import * as types from '../mutations_type';
import { WeatherVM } from '@/models/weather';


const state = {
  mode: sessionStorage.getItem('weatherMode'),
  weather: sessionStorage.getItem('weather')
    ? JSON.parse(sessionStorage.getItem('weather'))
    : new WeatherVM(),

};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getWeather: (state) => state.weather,
  // exportNest: (state) => {
  //   let apiData = CopyObject(state.nest);

  //   if (apiData.created_time.toISOString) {
  //     apiData.created_time = apiData.created_time.toISOString();
  //   }

  //   if (apiData.update_time != null && apiData.update_time.toISOString) {
  //     apiData.update_time = apiData.update_time.toISOString();
  //   }
  //   return apiData;
  // },
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_WEATHER);
    }
    commit(types.SET_WEATHER_MODE, mode);
  },
  setWeather({ commit }, weather) {
    commit(types.SET_WEATHER, weather);
  },
  importWeather({ commit }, weather) {
    // if (mission.period === 0 || mission.period === -1) {
    //   mission.noRepeat = true;
    // } else {
    //   mission.noRepeat = false;
    //   let unit = FindUnit(mission.period, state.periodUnit);

    //   mission.periodUnit = state.periodUnit[unit].magnification;
    //   mission.period = mission.period / mission.periodUnit;
    // }
    commit(types.SET_WEATHER, weather);
  },
};

const mutations = {
  [types.SET_WEATHER](state, weather) {
    state.weather = weather;
    sessionStorage.setItem('weather', JSON.stringify(weather));
  },
  [types.SET_WEATHER_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('weatherMode', mode);
  },
  [types.CLEAR_WEATHER](state) {
    state.mode = null;
    state.weather = new WeatherVM();
    sessionStorage.removeItem('weather');
    sessionStorage.removeItem('weatherMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
