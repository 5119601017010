import { Polygon } from 'vue2-google-maps';

const props = {
  draggable: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => {
      return {
        strokeWeight: 2,
        strokeColor: '#4873DD',
        strokeOpacity: 0.8,
        fillColor: '#6D93F2',
        fillOpacity: 0.15,
        zIndex: 10,
      };
    },
  },
  index: {
    type: Number,
    noBind: true,
  },
};

export default {
  extends: Polygon,
  props,
  name: 'AltitudeLimitZone',
};