import * as types from '../mutations_type';
import { AccountCreateVM } from '@/models/account';


const state = {
  mode: sessionStorage.getItem('accountMode'),
  account: sessionStorage.getItem('account')
    ? JSON.parse(sessionStorage.getItem('account'))
    : new AccountCreateVM(),

};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getAccount: (state) => state.account,  
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_ACCOUNT);
    }
    commit(types.SET_ACCOUNT_MODE, mode);
  },
  setAccount({ commit }, account) {
    commit(types.SET_ACCOUNT, account);
  },
};

const mutations = {
  [types.SET_ACCOUNT](state, account) {
    state.account = account;
    sessionStorage.setItem('account', JSON.stringify(account));
  },
  [types.SET_ACCOUNT_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('accountMode', mode);
  },
  [types.CLEAR_ACCOUNT](state) {
    state.mode = null;
    state.account = new AccountCreateVM();
    sessionStorage.removeItem('account');
    sessionStorage.removeItem('accountMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
