import * as types from '../mutations_type';
import { CameraVM } from '@/models/camera';


const state = {
  mode: sessionStorage.getItem('cameraMode'),
  camera: sessionStorage.getItem('camera')
    ? JSON.parse(sessionStorage.getItem('camera'))
    : new CameraVM(),

};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getCamera: (state) => state.camera,  
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_CAMERA);
    }
    commit(types.SET_CAMERA_MODE, mode);
  },
  setCamera({ commit }, camera) {
    commit(types.SET_CAMERA, camera);
  },
};

const mutations = {
  [types.SET_CAMERA](state, camera) {
    state.camera = camera;
    sessionStorage.setItem('camera', JSON.stringify(camera));
  },
  [types.SET_CAMERA_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('cameraMode', mode);
  },
  [types.CLEAR_CAMERA](state) {
    state.mode = null;
    state.camera = new CameraVM();
    sessionStorage.removeItem('camera');
    sessionStorage.removeItem('cameraMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
