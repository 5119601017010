<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'EmptyLayout',
  };
</script>

<style>
</style>
