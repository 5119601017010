<template>
  <vl-layer-vector :id="`${zone.id}-ObstacleZone`" :z-index="zIndex">
    <vl-source-vector :features="sourceGeoJSON"></vl-source-vector>
    <vl-style-box>
      <vl-style-stroke :color="strokeColor" :width="strokeWidth"></vl-style-stroke>
      <vl-style-fill :color="fillColor"></vl-style-fill>
    </vl-style-box>
  </vl-layer-vector>
</template>

<script>

export default {
  name: 'ObstacleZone',
  data() {
    return {
      strokeColor: [146, 88, 165, 0.8],
      strokeWidth: 2,
      fillColor: [146, 88, 165, 0.15],
    };
  },
  components: {
  },
  computed: {
    sourceGeoJSON() {
      let properties = { ...this.zone };
      properties.zoneType = properties.type;
      delete properties.coordinates;
      delete properties.type;
      return [{
        type: "Feature",
        id: `${this.zone.id}-ObstacleZone`,
        properties: {
          ...properties,
          type: 'polygon',
          source: 'no_fly_zones',
        },
        geometry: {
          type: "Polygon",
          coordinates: this.zone.coordinates,
        }
      }];
    },

  },
  props: {
    zone: {
      required: true,
      type: Object,
    },
    visible: {
      required: false,
      type: Boolean,
      default: true,
    },
    zIndex: {
      default: 3,
    }
  },
};
</script>
