import BaseVM from '@/models/';

export class MissionVM extends BaseVM {
  init() {
    return {
      name: '',
      type: 'line',
      tasks: [],
      area: [],
      areaSurveyConfig: {
        gsd: 20,
        tilt: -90,
        overlap: 60,
        sidelap: 60,
        angle: 0,
        type: 0,
        photo_duration: 0,
        time_lapse_mode: false,
      },
      start_time: new Date(),
      end_time: null,
      period: 0,
    };
  }
}
