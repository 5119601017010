export default {
  title: 'Users',
  allUsers : 'Users',
  addTitle : 'Add A New User',
  updateTitle : 'Update A User',
  dialog: {
    name: 'Name',
    account: 'Account',
    password: 'Password',
    permission: 'Permission',
    domain: 'Domain',
    groups: 'Locations',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    enable:'Enable the feature permissions for this user',
    write : 'Add / Edit / Delete',
    read : 'Read',
  },
};
