<template>
  <el-row
    class="app-header"
    type="flex"
    align="middle"
  >
    <el-row
      type="flex"
      class="fixed"
    >
      <!-- 漢堡條 -->
      <hamburger
        class="header-btn"
        hidden="true"
        @click="toggleSidebar"
      />
      <img
        :src="`/asserts/icon/logo/${iconLogo}`"
        alt="logo"
        class="app-header__icon fix_logo"
      >
    </el-row>

    <el-row
      id="group-selection"
      type="flex"
      justify="center"
    >
      <!-- Group 選擇 -->
      <el-row
        class="header-btn"
        trigger="click"
        @command="handleCommand"
      >
        <el-select
          v-model="groupId"
          :disabled="shouldDisabled"
          class="header-location-selector"
          placeholder="Select Group"
        >
          <el-option
            v-for="group in groups"
            :key="group.id"
            :label="group.name"
            :value="group.id"
          />
        </el-select>
      </el-row>
    </el-row>
    <el-row
      id="navi-row-end"
      class="fixed"
      type="flex"
      justify="end"
    >
      <!-- 全螢幕切換 -->
      <!-- <el-tooltip :content="$t('header.fullscreen')"
                  placement="bottom"
      >
        <full-screen class="header-btn hidden-xs-only" />
      </el-tooltip> -->

      <!-- 語系選擇 -->
      <!-- <lang-select class="header-btn" /> -->

      <!-- 訊息 -->
      <!-- <el-tooltip :content="$t('header.message')"
                  placement="bottom"
      >
        <div class="header-btn hidden-xs-only">
          <fa-icon icon="envelope"/>
          <el-badge is-dot/>
        </div>
      </el-tooltip> -->

      <!-- 通知 -->
      <notification />
      <changePassword ref="changePassword" />
      <!-- 帳戶 -->
      <el-dropdown
        class="header-btn"
        trigger="click"
        @command="handleCommand"
      >
        <div>
          <span
            v-if="authUser"
            class="small-text"
          ><span class="welcome-message">{{ $t('login.welcome') }}</span> {{ authUser.name }}</span>
          <fa-icon :icon="['far','user']" />
          <fa-icon :icon="['fas','caret-down']" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            command="changeMeasureImperial"
            :disabled="measurement === 'imperial'"
          >
            {{ $t('header.Imperial') }}
          </el-dropdown-item>
          <el-dropdown-item
            command="changeMeasureMetric"
            :disabled="measurement === 'metric'"
          >
            {{ $t('header.Metric') }}
          </el-dropdown-item>
          <el-dropdown-item
            command="profileSetting"
            class="hidden-sm-and-up"
            divided
          >
            {{ $t('header.profile') }}
          </el-dropdown-item>
          <el-dropdown-item command="openChangePassword">
            {{ $t('header.setPass') }}
          </el-dropdown-item>
          <el-dropdown-item command="signout">
            <span>
              <fa-icon icon="sign-out-alt" />
              {{ $t('header.signOut') }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Hamburger from '@/components/Hamburger.vue';
import Notification from '@/components/Notification.vue';
import ChangePassword from '@/components/ChangePassword.vue';
import customerSetting from '@/config/customerSetting';

const disabledPaths = [
  /\/mission\/edit/,
  /\/mission\/create/,
  /\/assign\/drone/,
  /\/history\/detail/,
  /\/history\/pickAlbum/,
  /\/setting\/location-edit-map/,
  /\/device\/device[\w]*Profile/,
];

export default {
  name: 'TheHeader',
  components: {
    Hamburger,
    Notification,
    ChangePassword,
  },
  data() {
    return {};
  },
  computed: {
    shouldDisabled() {
      return disabledPaths.some((regExpPath) => regExpPath.test(this.$route.path));
    },
    ...mapGetters({
      sidebarCollapse: 'sidebarCollapse',
      authUser: 'user/user',
      group: 'user/group',
      groups: 'user/groups',
      measurement: 'measurement',
      customer: 'customer',
    }),
    groupId: {
      ...mapGetters({
        get: 'user/groupId',
      }),
      ...mapActions({
        set: 'user/setGroupId',
      }),
    },
    iconLogo() {
      if (this.customer && customerSetting[this.customer]) {
        return customerSetting[this.customer].logo;
      }
      return `${this.$config.customerSetting.logo}`;
    },
  },
  methods: {
    ...mapActions({
      toggleSidebar: 'toggleSidebar',
      signout: 'auth/signout',
      cleanUser: 'user/clean',
      clearMonitor: 'monitorState/clean',
      setGroupId: 'group/setGroupId',
      changeMeasurement: 'changeMeasurement',
    }),
    handleCommand(command) {
      if (command === 'signout') {
        this.$router.push({ name: 'adminLogin', query: { isSignOut: 'true' } });
        this.signout()
          .then(() => {})
          .catch((error) => {
            console.group('signout');
            console.error(error);
            console.groupEnd();
          })
          .finally(() => {
            this.cleanUser();
            this.clearMonitor();
          });
      }

      if (command === 'openChangePassword') {
        this.$router.push({ name: 'accountSetting', query: { tab: 'setPass' } });
      } else if (command === 'profileSetting') {
        // this.$refs.changePassword.show=true;
        this.$router.push({ name: 'accountSetting', query: { tab: 'profile' } });
      } else if (command === 'changeMeasureImperial') {
        this.changeMeasurement('imperial');
      } else if (command === 'changeMeasureMetric') {
        this.changeMeasurement('metric');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .app-header {
    height: inherit;
    background-color: $main_color;
    color: white;
    padding : 0 20px;

    .header-btn {
      padding: 0 10px;
      color: white;
      height: $header_height;
      min-width: 60px;
      font-size: 20px;
      line-height: $header_height;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: darken($main_color, 10%);
      }
      .small-text {
        font-size: 12px;
        margin-right: 8px;
      }
      .welcome-message {
        color: #92d6ff;
      }
    }

    .fix_logo {
      max-height: 40px;
    }

    .fixed {
      flex: 1;
    }
  }
</style>
