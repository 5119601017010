export default {
  noHistory: {
    title: 'No history yet',
    content: 'Please click the “Add New” button to add a new history.',
  },
  dialog: {
    addTitle: 'Add a New Mission',
    updateTitle: 'Edit Mission',
    name: 'Mission Name',
    type: 'Mission Type',
    types: {
      lineString: 'Line String Mission',
      aerialSingle: 'Aerial Survey-Single',
      aerialGrid: 'Aerial Survey-Grid',
    },
    startTime: 'Start Time',
    endTime: 'End Time',
    repeat: 'Repeat',
    noRepeat: 'No Repeat',
    period: 'Every',
    addWaypoint: 'Add waypoint',
    save: 'Save the photos and the videos to your device?',
    delete: 'Once you delete the photos and the videos, you can\'t undo it!',
  },
  deleteConfirm: {
    title: 'Are you sure?',
    body: 'Once you delete the Mission, you can\'t undo it!',
  },
  title: 'Flight History',
  map: 'Map',
  name: 'Name',
  tasks: 'Tasks',
  location: 'Location',
  endTime: 'End Time',
  period: 'Scheduled at',
  allDrones: 'All Drones',
  periodUnit: {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
  },
  periodOnce: 'Once',
  periodMessage: 'Every {number} {unit}',
  load: 'Load',
  survey: {
    createRoute: 'Create Route',
  },
  saveConfirm: 'Do you want to save this mission?',
  table: {
    drone_name: 'Drone',
    create_time: 'Date',
    take_off: 'Takeoff',
    landing: 'Landing',
    distance: 'Distance',
    time: 'Time',
    result: 'Result',
    upload_progress: 'Progress',
    report: 'Report',
    video: 'Video',
  },
  imageNone: 'None',
  recordStatus: {
    200: 'Success',
    404: 'Can not find target drone',
    405: 'Assign mission failed',
    406: 'Execute mission failed',
    407: 'Assign device not ready',
    408: 'Incomplete Mission',
    409: 'Mission is not valid',
    410: 'Wind speed is not valid',
    411: 'Precipitation is not valid',
  },
};
