export default {
  homePage: '首頁',
  dashboard: '儀錶板',
  adminLogin: '登入',
  monitor: '監控',
  monitorLive: 'Monitor Live View',
  monitorMap: 'Monitor Map View',
  assignMap: 'Assign Mission Confirm',
  mission: '任務',
  missionList: 'Mission List',
  missionCreateMap: 'Set Mission Waypoint',
  missionEditMap: 'Set Mission Waypoint',
  missionAerialSurveySetArea: 'Aerial Survey',
  device: '裝置',
  event: '事件',
  history: '歷史紀錄',
  setting: '設定',
  profile: 'Profile',
  editLocationMap: 'Edit Location Map',
  createLocationMap: 'Create Location Map',
  deviceMap: 'Edit Device Location Map',
  selectGroup: '選擇場域',
  deviceWeatherProfile: 'Weather Station',
  deviceCameraProfile: 'Camera',
  deviceProfile: 'Drone',
  deviceNestProfile: 'Nest',
  accountSetting: '帳號',
  deviceNVRProfile: 'NVR',
  message : '訊息',
  olSourcePreview: 'OpenLayers 圖資預覽',
};
