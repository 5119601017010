import { CopyObject } from '@/utils/common.js';
import { FindUnit } from '@/utils/mission.js';

import * as types from '../mutations_type';
import { MissionVM } from '@/models/mission';


const state = {
  mode: sessionStorage.getItem('missionMode'),
  mission: sessionStorage.getItem('mission')
    ? JSON.parse(sessionStorage.getItem('mission'))
    : new MissionVM(),
  periodUnit: [
    { name: 'page.mission.periodUnit.minutes', magnification: 60 },
    { name: 'page.mission.periodUnit.hours', magnification: 3600 },
    { name: 'page.mission.periodUnit.days', magnification: 86400 },
    { name: 'page.mission.periodUnit.weeks', magnification: 604800 },
    { name: 'page.mission.periodUnit.months', magnification: 18144000 },
  ],
};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getMission: (state) => state.mission,
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_MISSION);
    }
    commit(types.SET_MISSION_MODE, mode);
  },
  setMission({ commit }, mission) {
    commit(types.SET_MISSION, mission);
  },
  importMission({ commit }, mission) {
    if (mission.period === 0 || mission.period === -1) {
      mission.noRepeat = true;
    } else {
      mission.noRepeat = false;
      let unit = FindUnit(mission.period, state.periodUnit);

      mission.periodUnit = state.periodUnit[unit].magnification;
      mission.period = mission.period / mission.periodUnit;
    }
    commit(types.SET_MISSION, mission);
  },
  exportMission({ commit }, elzs) {
    let apiData = CopyObject(state.mission);
    if (apiData.noRepeat) {
      apiData.period = 0;
    } else {
      apiData.period = apiData.periodUnit * apiData.period;
    }
    delete apiData.noRepeat;
    delete apiData.periodUnit;

    if (apiData.start_time.toISOString) {
      apiData.start_time = apiData.start_time.toISOString();
    }

    if (apiData.end_time != null && apiData.end_time.toISOString) {
      apiData.end_time = apiData.end_time.toISOString();
    }

    apiData.tasks = apiData.tasks.map((task, index) => {
      task.sequence = index;
      return task;
    });
    let missionElz=[];
    elzs.forEach(element => {
      if(element.activate)
      missionElz.push(element);
    });
    apiData.elz=missionElz; 
    return apiData;
  },
};

const mutations = {
  [types.SET_MISSION](state, mission) {
    state.mission = mission;
    sessionStorage.setItem('mission', JSON.stringify(mission));
  },
  [types.SET_MISSION_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('missionMode', mode);
  },
  [types.CLEAR_MISSION](state) {
    state.mode = null;
    state.mission = new MissionVM();
    sessionStorage.removeItem('mission');
    sessionStorage.removeItem('missionMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
