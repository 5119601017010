<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      appLang: 'appLang',
      googleMapsKey: 'user/googleMapsKey',
    }),
  },
  methods: {
    connectMqtt() {
      this.mqttConnect({
        host: this.user.mqtt_host,
        port: this.user.mqtt_port,
        path: this.user.mqtt_path,
        connectOptions: {
          userName: this.$config.mqttUsername,
          password: this.$config.mqttPsd,
          useSSL: this.user.is_mqtt_ssl == true,
        },
      });
    },
    ...mapActions({
      mqttConnect: 'mqtt/connect',
      mqttDisconnect: 'mqtt/disconnect',
    }),
  },
  watch: {
    appLang: {
      immediate: true,
      handler(newLang) {
        this.$i18n.locale = newLang;
      },
    },
    user: {
      handler(value) {
        if (value == null) {
          this.$nextTick(() => {
            this.mqttDisconnect();
          });
        } else {
          this.connectMqtt();
        }
      },
    },
    googleMapsKey: {
      handler(value) {
        this.$gmapOptions.load.key = this.googleMapsKey;
      },
    },
  },
  mounted() {
    this.$gmapOptions.load.key = this.googleMapsKey;
    if (this.user != null) {
      this.connectMqtt();
    }
  },
};
</script>

<style lang="scss">

</style>
