import { Message } from 'element-ui';
import i18n from '@/locales/';

const successHandler = (responseBody, config) => {
  Message.success(i18n.t(`response.${responseBody ? responseBody : 'success'}`, config));
};

const infoHandler = (responseBody, config) => {
  Message.info(i18n.t(`${responseBody ? responseBody : ''}`, config));
};

const failHandler = (response, config) => {
  let responseBody = null;
  if (response != null) {
    if (response.data) {
      responseBody = response.data;
    } else {
      responseBody = response;
    }
  }

  if (responseBody) {
    if (responseBody.meta && responseBody.meta.error) {
      Message.error(i18n.t(responseBody.meta.error, config));
    } else {
      Message.error(i18n.t(responseBody, config));
    }
  } else {
    Message.error(i18n.t('response.fail', config));
  }
};

export { infoHandler,successHandler, failHandler };
