import BaseVM from '@/models/';

export class MonitorTitleStateVM extends BaseVM {
  init() {
    return {
      tab: null,
      map: {
        drones: [],
        nest: true,
        camera: true,
      },
    };
  }
}
