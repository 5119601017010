export default {
  title: 'Map Source',
  name: 'Name',
  warning: 'Map Source setting save on this browser only',
  dialog: {
    addTitle: 'Add New Map Source',
    updateTitle: 'Edit Map Source',
    type: 'Source Type',
    options: 'Options',
    typeSelect: 'Select Source Type',
    preview: 'Preview',
    olRefs: 'OpenLayers Reference Class: ',
    parseFail: 'Option {key} should be {type}, but parse failed: {error}',
    noField: 'Missing required option value: {key}',
  },
};