import BaseVM from '@/models/';

export class AuthData extends BaseVM {
  init() {
    return {
      username: null,
      password: null,
      rememberMe: false,
    };
  }
}

export class AccountCreateVM extends BaseVM {
  init() {
    return {
      name: null,
      account: null,
      password: null,
      permission: null,
      
    };
  }
}
