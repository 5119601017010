<template>
  <el-aside
    :width="asideWidth"
    :class="['app-sidebar', 'no-scroll', { 'app-sidebar--collapse': true }]"
  >
    <el-row
      class="app-logo"
      type="flex"
      justify="center"
      align="middle"
    >
      <router-link :to="{ name: 'dashboard' }">
        <span v-if="true===true">
          <img src="/asserts/icon/inforbar-home-icon-normal.svg">
        </span>
        <span v-else>
          Coretronic <strong>FMS</strong></span>
      </router-link>
    </el-row>
    <el-menu
      :default-active="activeLink"
      :collapse="true"
      text-color="#fff"
      class="menu-wrapper"
      unique-opened
      router
    >
      <template v-for="(item,index) in list">
        <el-submenu
          v-if="hasChildren(item)"
          :key="item.name"
          :index="item.name"
          :class="{ 'is-parent': isParentMenu(item) }"
          popper-class="app-sidebar--popup"
        >
          <template slot="title">
            <fa-icon
              v-if="item.icon"
              :icon="item.icon"
            />
            <span>{{ $t(`route.${item.name}`) }}</span>
          </template>
          <template
            v-for="menu in item.subMenuItems"
            :index="menu.name"
          >
            <el-menu-item
              :key="menu.name"
              :index="menu.name"
              :route="{ name: menu.name }"
            >
              <fa-icon
                v-if="menu.icon"
                :icon="menu.icon"
              />
              <span>{{ $t(`route.${menu.name}`) }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item
          v-else-if="item.href"
          :key="item.name"
          :index="item.name"
          :route="{ name: item.name }"
          :class="item.name"
        >
          <!-- <fa-icon v-if="item.icon"
                   :icon="item.icon"
          /> -->
          <img :src="item.img">
          <span>{{ $t(`route.${item.name}`) }}</span>
        </el-menu-item>
        <div
          v-else
          class="dash-boarder"
        >
          <!-- <fa-icon v-if="item.icon"
                   :icon="item.icon"
          /> -->
          <img :src="item.img">
        </div>
      </template>
    </el-menu>
    <el-row
      :class="`app-setting ${(activeLink === 'setting') ? 'is-active' : ''} }`"
      type="flex"
      justify="center"
      align="middle"
    >
      <router-link :to="{ name: 'setting' }">
        <span v-if="true===true">
          <img src="/asserts/icon/menu-settings-icon.svg">
          <div>
            <strong>{{ $t('header.settings') }}</strong>
          </div>
        </span>
        <span v-else>
          Coretronic <strong>FMS</strong></span>
      </router-link>
    </el-row>

    <!-- more options dropdown -->
    <el-dropdown
      trigger="click"
      placement="top"
    >
      <span class="el-dropdown-link el-dropdown__icon" style="margin: 0px">
        <i class="el-icon-more"/>
      </span>
      <el-dropdown-menu
        slot="dropdown"
        style="padding: 0px; width: 106px"
        class="el-dropdown__menu"
      >

        <router-link :to="{ name: 'message' }">
          <el-dropdown-item
            class="message"
            style="font-size: 12px"
          >
            {{ $t('route.message') }}
          </el-dropdown-item>
        </router-link>

        <router-link :to="{ name: 'history' }">
          <el-dropdown-item
            class="history"
            style="font-size: 12px"
          >
            {{ $t('route.history') }}
          </el-dropdown-item>
        </router-link>

        <router-link :to="{ name: 'setting' }">
          <el-dropdown-item
            class="settings"
            style="font-size: 12px"
          >
            {{ $t('header.settings') }}
          </el-dropdown-item>
        </router-link>
      </el-dropdown-menu>
    </el-dropdown>
  </el-aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheSidebar',
  data() {
    return {
      asideWidth: '64px',
      activeLink: '',
    };
  },
  computed: {
    ...mapGetters(['sidebarCollapse']),
    list() {
      return this.$store.state.menu.list;
    },
  },
  watch: {
    sidebarCollapse(isCollapse) {
      isCollapse = true;
      this.asideWidth = isCollapse ? '64px' : '200px';
    },
    $route(newVal, oldVal) {
      const a = newVal.fullPath.substr(newVal.fullPath.indexOf('/') + 1);
      this.activeLink = a.substring(0, a.indexOf('/'));
    },
  },
  created() {
    this.$store.dispatch('menu/getMenu');
  },
  mounted() {
    this.activeLink = this.$route.path;
  },
  methods: {
    hasChildren(item) {
      return item.subMenuItems.length > 0;
    },
    isParentMenu(menu) {
      return this.$route.matched.some((item) => item.name === menu.name);
    },
  },

};
</script>

<style lang="scss">
@import '~@/styles/variables.scss';

.app-sidebar.no-scroll {
  // hotfix for safari
  overflow: hidden !important;
}

.app-sidebar {
  height: 100vh;
  background-color: $menu_color;
  transition: 0.3s;

  &.app-sidebar--collapse .app-logo {
    width: 64px;
    font-size: 12px;
  }

  .app-logo {
    height: $header_height;
    width: 200px;
    background-color: $dark-blue-300;
    font-size: 14px;
    transition: 0.3s;

    strong {
      font-size: 18px;
    }

    a {
      color: $normal-white;
      text-decoration: none;
    }

    img {
      width: 100%;
      height: 100%;
    }

    img:hover {
      content: url('/asserts/icon/inforbar-home-icon-focus.svg');
    }

    &:hover {
      color: $secondary_color;
    }
  }

  &.app-sidebar--collapse .app-setting {
    width: 64px;
    text-align: center;
    font-size: 12px;
    @include mobile-extra-height-640px {
      display: none;
    }
  }

  &.app-sidebar--collapse .app-setting.is-active {
    background-color: $dark-blue-600;
    color: $secondary_color;

    strong {
      color: $secondary_color;
    }
  }

  &.app-sidebar--collapse .app-setting-padding {
    width: 66px;
    font-size: 12px;
    height: 30px;
  }

  .app-setting {
    height: $header_height;
    width: 200px;
    background-color: $menu_color;
    font-size: 14px;
    transition: 0.3s;
    margin: -2px;

    strong {
      font-size: 12px;
      font-weight: normal;
      display: inherit;
    }

    a {
      color: $normal-white;
      text-decoration: none;
    }

    img {
      // margin-left: 6px;
      // width: 50%;
      // height: 50%;
    }

    &:hover {
      background-color: darken($menu_color, 10%);
      color: $secondary_color;
    }

  }

  > .el-menu {
    height: calc(100vh - #{$header_height} - #{$header_height} - 30px);
    overflow: hidden;
    //overflow-x: hidden;
    //overflow-y: auto;
    border: none;
  }

  .el-menu {
    background-color: $menu_color;
    margin: -2px;

    .el-menu-item,
    .el-submenu__title {
      &:hover,
      &:focus,
      &.is-active {
        background-color: darken($menu_color, 10%);
        color: $secondary_color;
      }
    }

    .el-submenu {
      &.is-parent {
        .el-submenu__title {
          background-color: darken($menu_color, 10%);
          color: $secondary_color !important;
        }
      }
    }

    .svg-inline--fa {
      width: 24px;
    }
  }
}

.app-sidebar--popup {
  .el-menu {
    background-color: $menu_color;
  }

  .el-menu-item,
  .el-submenu__title {
    &:hover,
    &:focus,
    &.is-active {
      background-color: darken($menu_color, 10%);
      color: $secondary_color;
    }
  }

  .svg-inline--fa {
    width: 24px;
  }

  .el-submenu {
    &:hover {
      background-color: darken($menu_color, 10%);
    }
  }
}

.el-menu--collapse > .el-menu-item span, .el-menu--collapse > .el-submenu > .el-submenu__title span {
  overflow: unset;
  visibility: visible;
  display: -webkit-inline-box;
  position: absolute;
  top: 48px;
  right: -1px;
  width: 37px;
  // z-index: 2222;
  font-size: 12px;
  // font-family: HelveticaNeue;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  width: 63px !important;
  overflow: visible !important;
  visibility: visible !important;
  display: block;
}

.el-menu--collapse > .dash-boarder {
  border-width: thin;
  border-color: $normal-white;
  margin-bottom: 15px;
  width: 50px;
  margin-left: 9px;
  border-bottom-style: dashed;
}

.el-menu--collapse > .dash-boarder:nth-of-type(2) {
  @include mobile-large-height-628px {
    display: none;
  }
}

.el-menu--collapse > .dash-boarder:nth-of-type(1) {
  @include mobile-medium-height-527px {
    display: none;
  }
}

.el-menu-item, .el-submenu__title {
  height: 72px !important;
  line-height: 56px;
  position: relative;
  -webkit-box-sizing: border-box;
  white-space: nowrap;
  list-style: none;
}

li.el-menu-item.history {
  @include mobile-large-height-600px {
    display: none;
  }
}

li.el-menu-item.message {
  @include mobile-medium-height-527px {
    display: none;
  }
}

.el-dropdown__icon {
  display: none;
  @include mobile-extra-height-640px {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    color: $normal-white;
    width: 64px;
    top: 22px;
    height: 52px;
  }
}

.el-dropdown__icon:hover {
  color: $light-gray-500;
}

.el-icon-more:before {
  font-size: 23px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.el-dropdown__menu {
  position: absolute !important;
  left: 46px !important;
  top: 84% !important;
  visibility: hidden;
  @include mobile-extra-height-640px {
    visibility: visible;
  }
  @include mobile-large-height-600px {
    top: 78% !important;
  }
  @include mobile-medium-height-527px {
    top: 70% !important;
  }
}

.el-popper[x-placement^=top] .popper__arrow {
  display: none;
}

li.el-dropdown-menu__item.history {
  display: none;
  @include mobile-large-height-600px {
    display: flex;
  }
}

li.el-dropdown-menu__item.message {
  display: none;
  @include mobile-medium-height-527px {
    display: flex;
  }
}

</style>
