<template>
  <component :is="component.implement" v-bind="component.attributes">
  </component>
</template>

<script>
import GoogleMaps from '@/components/GoogleMaps/Thumbnail.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DataTableThumbnail',
  components: {
    GoogleMaps,
  },
  computed: {
    component() {
      switch (this.mapEngine) {
        case 'GoogleMaps':
          return {
            implement: GoogleMaps,
            attributes: {
              src: this.value.thumbnail,
            },
          };
          break;
        case 'OpenLayers':
          if (this.value.local_thumbnail == null) {
            console.warn(`no local_thumbnail for ${this.value.name} (${this.value.id}), open and save it will create thumbnail resolve this problem`);
          }
          return {
            implement: 'img',
            attributes: {
              src: this.value.local_thumbnail,
            },
          }
          break;
      }
    },
    ...mapGetters({
      mapEngine: 'user/mapEngine',
    }),
  },
  props: {
    value: {
      required: true,
      type: Object,
    }
  },
}
</script>