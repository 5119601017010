export default {
  dialog: {
    addTitle: 'Add a New Drone',
    addNestTitle: 'Add a New Nest',
    addCameraTitle: 'Add a New Camera',
    addWeatherTitle: 'Add a New Weather Station',
    addNVRTitle: 'Add a New NVR',
    updateTitle: 'Edit Drone',
    advTitle: 'Advanced Settings',
    updateNestTitle: 'Edit Nest',
    updateCameraTitle: 'Edit Camera',
    updateWeatherTitle: 'Edit Weather Station',
    updateNVRTitle: 'Edit NVR',
    name: 'Name',
    nestName: 'Nest Name',
    cameraName: 'Camera Name',
    weatherName: 'Station Name',
    type: 'Drone Type',
    startTime: 'Start Time',
    endTime: 'End Time',
    repeat: 'Repeat',
    noRepeat: 'No Repeat',
    period: 'Every',
    addWaypoint: 'Add waypoint',
    save: 'Save',
    location: 'Location',
    serial: 'Serial',
    mac: 'MAC Address',
    stationID: 'Station Code',
    latitude: 'Lat',
    longitude: 'Lon',
    url: 'URL',
    admin: 'Admin',
    inActive: 'Active',
    gpsLocation: 'GPS Location',
    account: 'Account',
    password: 'Password',
    status: 'Status',
    powerOff: 'Power',
    ip: 'IP',
    port: 'Port',
    tempLimit: 'Lower & Upper Temp Limit',
    envTemp: 'Env.temp',
    HEXAROTOR: 'HEXAROTOR',
    c_rgb_push: 'Custom RGB Push Url',
    c_rgb_pop: 'Custom RGB Pop Url',
    c_thermal_push: 'Custom Thermal Push Url',
    c_thermal_pop: 'Custom Thermal Pop Url',
    rgb: 'RGB URL',
    thermal: 'Thermal URL',
    model: 'Model',
    gimbal: 'Gimbal',
    moveTo: 'Move to',
  },
  deleteConfirm: {
    title: 'Are you sure?',
    body: 'Once you delete the Drone, you can\'t undo it!',
    nestBody: 'Once you delete the Nest, you can\'t undo it!',
    cameraBody: 'Once you delete the Camera, you can\'t undo it!',
    weatherBody: 'Once you delete the Station, you can\'t undo it!',
    nvrBody: 'Once you delete the NVR, you can\'t undo it!',
  },
  title: 'Devices',
  map: '1',
  name: '2',
  tasks: '3',
  location: '4',
  endTime: '5',
  period: '6',
  periodUnit: {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
  },
  periodMessage: 'Every {number} {unit}',
  load: 'Load',
  droneId: 'Drone ID',
  droneName: 'Drone Name',
  domain: 'Domain',
  group: 'Group',
  MACAddress: 'MAC Address',
  allDrones: 'Drones',
  allNests: 'Nests',
  allCameras: 'IP Cam',
  allWeathers: 'Weather Stations',
  nvrs: 'NVR',
  noDrone: {
    title: 'No drone yet',
    content: 'Please click the "Add New" button to add a new drone.',
  },
  noNest: {
    title: 'No nest yet',
    content: 'Please click the "Add New" button to add a new nest.',
  },
  noCamera: {
    title: 'No camera yet',
    content: 'Please click the "Add New" button to add a new camera.',
  },
  noWeather: {
    title: 'No Weather Station yet',
    content: 'Please click the "Add New" button to add a new weather station.',
  },
  noNVR: {
    title: 'No NVR yet',
    content: 'Please click the "Add New" button to add a new NVR',
  },
};
