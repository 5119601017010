import * as types from '../mutations_type';
import { NoFlyZoneVM } from '@/models/noFlyZone';
import { GeographicApiToOl, GeographicShorter } from '@/utils/map.js';
import { GeographicOlToApi, GeographicLonger } from '@/utils/map.js';
import { BoundsOlToApi, BoundsApiToOl, BoundsApiToGoogle, FormatBounds } from '@/utils/map.js';
import { app } from '@/app.js';
import config from 'config';
import { transform as ProjectTransform, transformExtent as ProjectTransformExtent } from 'ol/proj.js';

const state = {
  type: 0,
  zoom: null,
  center: null,   // only accept change from map object, use panTo instead change center value
  bounds: null,   // only accept change from map object, use panTo instead change center value
};

const getters = {
  type: (state) => state.type,
  center: (state, getters, rootState, rootGetter) => {
    if (state.center) {
      return state.center;
    }

    let groupCenter = rootGetter['user/group'];
    if (groupCenter && groupCenter.latitude != null && groupCenter.longitude != null) {
      return {
        latitude: groupCenter.latitude,
        longitude: groupCenter.longitude,
      };
    }

    // user.js module includ final failback from config
    let domainCenter = rootGetter['user/location'];
    return {
      latitude: domainCenter.latitude,
      longitude: domainCenter.longitude,
    };
  },
  centerOl: (state, getters) => GeographicApiToOl(getters.center),
  centerGoogleMaps: (state, getters) => GeographicShorter(getters.center),
  zoom: (state, getters) => {
    if (state.zoom != null) {
      return state.zoom;
    }

    let latudedeAfterPoint = getters.center.latitude.toString().split('.')[1];
    if (latudedeAfterPoint != null) {
      return 17;
    }
    return 2;
  },
  bounds: (state) => state.bounds,
  boundsInteger: (state) => {
    if (state.bounds == null) {
      return null;
    }
    return FormatBounds(state.bounds);
  },
};

const actions = {
  reset({ commit }) {
    commit(types.SET_MAP_TYPE, 0);
    commit(types.SET_MAP_CENTER, null);
    commit(types.SET_MAP_ZOOM, null);
    commit(types.SET_MAP_BOUNDS, null);
  },
  typeNext({ getters, commit }) {
    commit(types.SET_MAP_TYPE, getters.type + 1);
  },
  centerUpdate({ commit }, center) {
    console.warn('use pan action instead this action');
    commit(types.SET_MAP_CENTER, center);
  },
  centerUpdateOl({ commit }, center) {
    commit(types.SET_MAP_CENTER, GeographicOlToApi(center));
  },
  centerUpdateGoogleMaps({ commit }, center) {
    commit(types.SET_MAP_CENTER, GeographicLonger(center));
  },
  goGroupCenter({ commit, rootGetters }) {
    let group = rootGetters['user/group'];
    let center = {
      latitude: group.latitude,
      longitude: group.longitude,
    };
    actions.panTo({
      commit,
      rootGetters,
    }, center);
  },
  zoomUpdate({ commit }, zoom) {
    commit(types.SET_MAP_ZOOM, zoom);
  },
  zoomIn({ getters, commit }) {
    commit(types.SET_MAP_ZOOM, getters.zoom + 1);
  },
  zoomOut({ getters, commit }) {
    commit(types.SET_MAP_ZOOM, getters.zoom - 1);
  },
  boundsUpdate({ commit }, bounds) {
    commit(types.SET_MAP_BOUNDS, bounds);
  },
  boundsUpdateOl({ commit }, bounds) {
    commit(types.SET_MAP_BOUNDS, BoundsOlToApi(bounds));
  },
  panTo({ rootGetters, commit }, center) {
    let mapEngine = rootGetters['user/mapEngine'];
    switch (mapEngine) {
      case 'OpenLayers':
        if (app.$ol == null) {
          commit(types.SET_MAP_CENTER, {
            latitude: center.latitude,
            longitude: center.longitude,
          });
          break;
        }
        app.$ol.view.setCenter(ProjectTransform(
          GeographicApiToOl(center),
          config.openlayers.vlProjection,
          config.openlayers.olProjection
        ));
        break;
      case 'GoogleMaps':
        if (app.$gmap == null) {
          commit(types.SET_MAP_CENTER, center);
          break;
        }
        app.$gmap.map.panTo(GeographicShorter(center));
        break;
    }
  },
  fitBounds({ rootGetters }, bounds) {
    let mapEngine = rootGetters['user/mapEngine'];
    switch (mapEngine) {
      case 'OpenLayers':
        app.$ol.view.fit(ProjectTransformExtent(
          BoundsApiToOl(bounds),
          config.openlayers.vlProjection,
          config.openlayers.olProjection
        ));
        break;
      case 'GoogleMaps':
        bounds = BoundsApiToGoogle(bounds);
        app.$gmapApiPromiseLazy().then(() => {
          app.$gmap.map.fitBounds(
            new google.maps.LatLngBounds(bounds.sw, bounds.ne)
          );
        });
        break;
    }
  },
};

const mutations = {
  [types.SET_MAP_TYPE](state, type) {
    state.type = type;
  },
  [types.SET_MAP_CENTER](state, center) {
    state.center = center;
  },
  [types.SET_MAP_ZOOM](state, zoom) {
    state.zoom = zoom;
  },
  [types.SET_MAP_BOUNDS](state, bounds) {
    state.bounds = bounds;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
