export const CopyObject = (someObject) => {
  if (someObject == null) {
    return null;
  }
  return JSON.parse(JSON.stringify(someObject));
};

/**
 * Convert hex style color code #00FF00 to rgba(0, 255, 0 , 1)
 * @param {String} hex Hex style color code like #00FF00
 * @param {Number} alpha Number between 0 and 1 like 0.25
 */
export const HexToRgbA = (hex, alpha) => {
  if (typeof (alpha) !== 'number') {
    alpha = 1;
  }
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    hex = hex.substring(1); // #00FF00 to 00FF00
    if (hex.length === 3) { // convert F0A to FF00AA
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    hex = parseInt(hex, 16);
    // every color 8 bit depth
    return `rgba(${(hex >> 16) & 255}, ${(hex >> 8) & 255}, ${(hex) & 255}, ${alpha})`;
  }
  throw new Error('Bad Hex');
};

function trans(value, scale = 1, preOffset = 0, postOffset = 0) {
  return (value + preOffset) * scale + postOffset;
}

function meterToFeetTrans(value) {
  return trans(value, 3.2808, 0, 0);
}

function feetToMeterTrans(value) {
  return trans(value, 0.3048, 0, 0);
}

function celsiusToFahrenheitTrans(value) {
  return trans(value, 1.8, 0, 32.0);
}

function defaultTrans(value) {

  return value;
}

const measurements = {
  imperial: {
    toDisplayTrans: meterToFeetTrans,
    toRealTrans: feetToMeterTrans,
    tmpTrans: celsiusToFahrenheitTrans,
    disUnit: 'ft',
    speedUnit: 'mph',
    tmpUnit: '°F',
  },
  metric: {
    toDisplayTrans: defaultTrans,
    toRealTrans: defaultTrans,
    tmpTrans: defaultTrans,
    disUnit: 'm',
    speedUnit: 'm/s',
    tmpUnit: '°C',
  },
};

export const ToDisplay = (measurement, meter) => (measurements[measurement] ? measurements[measurement].toDisplayTrans(meter) : meter);

export const ToReal = (measurement, meter) => (measurements[measurement] ? measurements[measurement].toRealTrans(meter) : meter);

export const ToTemperature = (measurement, celsius) => (measurements[measurement] ? measurements[measurement].tmpTrans(celsius) : celsius);

export const DisUnit = (measurement) => (measurements[measurement] ? measurements[measurement].disUnit() : measurements.metric.disUnit());

export const SpeedUnit = (measurement) => (measurements[measurement] ? measurements[measurement].disUnit() : measurements.metric.disUnit());

export const TmpUnit = (measurement) => (measurements[measurement] ? measurements[measurement].disUnit() : measurements.metric.disUnit());
