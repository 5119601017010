import AdminLayout from '@/views/layout/AdminLayout';

//#region Error Page
const Page403 = (resolve) => {
  require.ensure(['@/views/error/403.vue'], () => {
    resolve(require('@/views/error/403.vue'));
  });
};
const Page404 = (resolve) => {
  require.ensure(['@/views/error/404.vue'], () => {
    resolve(require('@/views/error/404.vue'));
  });
};
//#endregion

const AdminLogin = (resolve) => {
  require.ensure(['@/views/admin/Login.vue'], () => {
    resolve(require('@/views/admin/Login.vue'));
  });
};

const TriggerLogin = (resolve) => {
  require.ensure(['@/views/admin/TriggerLogin.vue'], () => {
    resolve(require('@/views/admin/TriggerLogin.vue'));
  });
};

import adminRoutes from './admin';

const routes = [
  {
    path: '/login',
    component: AdminLogin,
    name: 'adminLogin',
    meta: {
      title: 'adminLogin',
    },
  },
  {
    path: '/triggerLogin',
    component: TriggerLogin,
    name: 'triggerLogin',
    meta: {
      title: 'triggerLogin',
      requiresAuth: false,
    },
  },
  {
    path: '/403',
    component: Page403,
    name: 'page403',
    meta: {
      title: 'page403',
    },
  },
  {
    path: '/404',
    component: Page404,
    name: 'page404',
    meta: {
      title: 'page404',
    },
  },
  {
    path: '',
    component: AdminLayout,
    children: adminRoutes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/*',
    redirect: '/404',
  },
];

export default routes;
