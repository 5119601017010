import BaseVM from '@/models/';

export class MonitorStateVM extends BaseVM {
  init() {
    return {
      drone: {
        takeoff: {},
        history: {},
      },
    };
  }
}
