export default {
  control: {
    battery: '電池電量',
    linkQuality: '訊號強度',
    flightTime: '飛行時間',
    EstTime: '預估時間',
    altitude: '高度',
    speed: '水平速度',
    target: '目標',
    fromHome: '起點',
    timeFormat: 'MM.DD HH:mm',
    voltage: '電池電壓',
    GPS: 'GPS',
    HDOP: 'HDOP',
    life: '開機時間',
    vSpeed: '垂直速度',
    to_hover: '開始懸停',
    resume: '回復執行任務成功',
    land: '開始降落',
    take_off: '起飛',
    Altitude: '高度',
    Latitude: '緯度',
    longitude: '經度',
    rtl: '開始返航',
    stop: '確定要暫時停止目前執行的任務嗎？',
    guided: '開始飛行至目標點',
    tilt: '相機傾角',
    heading: '相機頭像',
    takeoff: '開始起飛',
    wpt: '新的航點',
    stream_reset: '重設視訊',
  },
  status: {
    manual: '手動',
    onmission: '任務中',
    standby: '待命',
    offline: '離線',
    charging: '充電中',
    error: '錯誤',
    unknown: 'Unknown',
    closed: '門關',
    opened: '門開',
    replay: 'Replay',
    closing: '關門中',
    opening: '開門中',
  },
  playback: {
    dragHere: '拖拉攝影機到此',
  },
  titleBar: {
    live: '視訊介面',
    map: '地圖介面',
    photos: '相片',
    videos: '影片',
    album: '相簿',
  },
  cameraList: {
    title: '攝影機',
    drones: '無人機',
    rgb: 'RGB',
    thermal: 'Thermal',
  },
  load_mission: '載入任務',
  select_mission: '選擇一個任務',
  replaceMission: '您確定要執行此操作並結束原先未完成的任務嗎？\n結束任務需花一點時間處理，請稍等。',
  select_drone: '選擇一台無人機',
  Next: '下一步',
  clear: '清除畫面',
  speed: '速度',
};
