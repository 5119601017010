import config from 'config';
import * as types from '../mutations_type';

const state = {
  list: [],
};

const getters = {
  getList: (state) => state.list,
};

const menus = {
  monitor: {
    name: 'monitor',
    href: '/monitor',
    icon: 'desktop',
    iconType: null,
    sequence: 0,
    subMenuItems: [],
    menuItemId: null,
    img: '/asserts/icon/menu-monitor-icon.svg',
  },
  mission: {
    name: 'mission',
    href: '/mission',
    icon: 'map-marked',
    iconType: null,
    sequence: 1,
    subMenuItems: [],
    menuItemId: null,
    img: '/asserts/icon/menu-missions-icon.svg',
  },
  device: {
    name: 'device',
    href: '/device',
    icon: 'plane',
    iconType: null,
    sequence: 1,
    subMenuItems: [],
    menuItemId: null,
    img: '/asserts/icon/menu-devices-icon.svg',
  },
  event: {
    name: 'event',
    href: '/event',
    icon: 'anchor',
    iconType: null,
    sequence: 1,
    subMenuItems: [],
    menuItemId: null,
    img: '/asserts/icon/menu-event-icon.svg',
  },
  message: {
    name: 'message',
    href: '/message',
    icon: 'message',
    iconType: null,
    sequence: 1,
    subMenuItems: [],
    menuItemId: null,
    img: '/asserts/icon/menu-message-icon.svg',
  },
  history: {
    name: 'history',
    href: '/history',
    icon: 'history',
    iconType: null,
    sequence: 1,
    subMenuItems: [],
    menuItemId: null,
    img: '/asserts/icon/menu-history-icon.svg',
  },
  dash: {
    name: 'dash1',
    iconType: null,
    sequence: 1,
    subMenuItems: [],
    menuItemId: null,
  },
};

const actions = {
  getMenu({ commit }) {
    const data = config.customerSetting.menus.filter((m) => menus[m] != null).map((m) => menus[m]);
    commit(types.SET_MENU, data);
  },
};

const mutations = {
  [types.SET_MENU](state, data) {
    state.list = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
