export default {
  title: '人員',
  allUsers : '人員',
  addTitle : '新增一位人員',
  updateTitle : '編輯人員',
  dialog: {
    name: 'Name',
    account: '帳號',
    password: '密碼',
    permission: '權限',
    domain: '權限',
    groups: '場域',
    oldPassword: '舊密碼',
    newPassword: '新密碼',
    confirmPassword: '確認密碼',
    enable:'為此用戶啟用功能權限',
    write : '新增 / 編輯 / 刪除',
    read : '讀取',
  },
};
