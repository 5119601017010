import { CopyObject } from '@/utils/common.js';

import * as types from '../mutations_type';
import { ForwardVM } from '@/models/forward';


const state = {
  mode: sessionStorage.getItem('forwardMode'),
  forward: sessionStorage.getItem('forward')
    ? JSON.parse(sessionStorage.getItem('forward'))
    : new ForwardVM(),
};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getForward: (state) => state.forward,
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_FORWARD);
    }
    commit(types.SET_FORWARD_MODE, mode);
  },
  setForward({ commit }, forward) {
    commit(types.SET_FORWARD, forward);
  },
  exportForward: () => {
    let apiData = CopyObject(state.forward);
    // mission only for preview
    if (apiData.name == null || apiData.name === '') {
      throw new Error('require name');
    }
    switch (apiData.mode) {
      case 'restful':
        if (apiData.url == null || apiData.url === '') {
          throw new Error('require URL');
        }
        break;
      case 'socket':
        if (apiData.account == null || apiData.account === '') {
          throw new Error('require account');
        }
        if (apiData.password == null || apiData.password === '') {
          throw new Error('require password');
        }
        delete apiData.url;
        break;              
      default:
        break;
    }

    return apiData;
  },
};

const mutations = {
  [types.SET_FORWARD](state, forward) {
    state.forward = forward;
    sessionStorage.setItem('forward', JSON.stringify(forward));
  },
  [types.SET_FORWARD_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('forwardMode', mode);
  },
  [types.CLEAR_FORWARD](state) {
    state.mode = null;
    state.forward = new ForwardVM();
    sessionStorage.removeItem('forward');
    sessionStorage.removeItem('forwardMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
