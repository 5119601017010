export default {
  dialog: {
    addTitle: '新增一台無人機',
    addNestTitle: '新增一台基站',
    addCameraTitle: '新增一個網路攝影機',
    addWeatherTitle: '新增一個氣象站',
    addNVRTitle: '新增一台網路影像錄影機',
    updateTitle: '編輯無人機',
    advTitle: '進階設定',
    updateNestTitle: '編輯基站',
    updateCameraTitle: '編輯網路攝影機',
    updateWeatherTitle: '編輯氣象站',
    updateNVRTitle: '編輯網路影像錄影機',
    name: '名稱',
    nestName: '基站名稱',
    cameraName: '網路攝影機 名稱',
    weatherName: '氣象站 名稱',
    type: '種類',
    startTime: 'Start Time',
    endTime: 'End Time',
    repeat: 'Repeat',
    noRepeat: 'No Repeat',
    period: 'Every',
    addWaypoint: 'Add waypoint',
    save: 'Save',
    location: 'Location',
    serial: 'Serial',
    mac: 'Mac位置',
    stationID: '氣象站代碼',
    latitude: '緯度',
    longitude: '經度',
    url: '鏈結',
    admin: 'Admin',
    inActive: '啟用',
    gpsLocation: 'GPS 位置',
    account: '帳號',
    password: '密碼',
    status: 'Status',
    powerOff: '電⼒開關',
    ip: 'IP位置',
    port: '通訊埠',
    tempLimit: '最高和最低溫度限制',
    envTemp: '環境溫度',
    HEXAROTOR: '多旋翼無人機',
    c_rgb_push: '客製化RGB Push鏈結',
    c_rgb_pop: '客製化RGB Pop鏈結',
    c_thermal_push: '客製化Thermal Push鏈結',
    c_thermal_pop: '客製化Thermal Pop鏈結',
    rgb: 'RGB鏈結',
    thermal: 'Thermal鏈結',
    model: '型號',
    gimbal: '雲台',
    moveTo: 'Move to',
  },
  deleteConfirm: {
    title: '確定嗎？',
    body: '無人機資料刪掉就沒囉',
    nestBody: '基站資料刪掉就沒囉',
    cameraBody: '攝影機資料刪掉就沒囉',
    weatherBody: '天氣站資料刪掉就沒囉',
    nvrBody: '錄影機資料刪掉就沒囉',
  },
  title: '裝置',
  map: '1',
  name: '2',
  tasks: '3',
  location: '4',
  endTime: '5',
  period: '6',
  periodUnit: {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
  },
  periodMessage: 'Every {number} {unit}',
  load: 'Load',
  droneId: 'Drone ID',
  droneName: 'Drone Name',
  domain: 'Domain',
  group: 'Group',
  MACAddress: 'MAC Address',
  allDrones: '無人機',
  allNests: '基站',
  allCameras: '網路攝影機',
  allWeathers: '氣象站',
  nvrs: '網路影像錄影機',
  noDrone: {
    title: '尚無無人機',
    content: '新增一台無人機',
  },
  noNest: {
    title: '尚無基站',
    content: '新增一台基站',
  },
  noCamera: {
    title: '尚無網路攝影機',
    content: '新增一個網路攝影機',
  },
  noWeather: {
    title: '尚無氣象站',
    content: '新增一個氣象站',
  },
  noNVR: {
    title: '尚無網路影像錄影機',
    content: '新增一台網路影像錄影機',
  },
};
