export const appLang = (state) => state.appLang;

export const sidebarCollapse = (state) => state.sidebarCollapse;

export const loading = (state) => state.loading;

export const processingData = (state) => state.processingData;

export const measurement = (state) => state.measurement;

export const customer = (state) => state.customer;
