import * as types from '../mutations_type';
import { MonitorStateVM } from '@/models/monitorState';
import { MonitorTitleStateVM } from '@/models/monitorTitleState';


const state = {
  titleOptions: sessionStorage.getItem('monitorTitle')
    ? JSON.parse(sessionStorage.getItem('monitorTitle'))
    : new MonitorTitleStateVM(),
  monitor: sessionStorage.getItem('monitor')
    ? JSON.parse(sessionStorage.getItem('monitor'))
    : new MonitorStateVM(),
};

const getters = {
  titleOptions: (state) => (state.titleOptions),
  monitor: (state) => (state.monitor),
};

const actions = {
  setTakeoff({ commit }, data) {
    if (data == null) {
      return;
    }
    commit(types.SET_TAKEOFF_LOCATION, data);
  },
  addFlightHistory({ commit }, data) {
    commit(types.ADD_FLY_HISTORY, data);
  },
  cleanFlightHistory({ commit }, data) {
    commit(types.CLEAN_FLY_HISTORY, data);
  },
  clean({ commit }) {
    commit(types.CLEAN_MONITOR_STATE);
  },
};

const mutations = {
  [types.SET_TAKEOFF_LOCATION](state, data) {
    // state.monitor = mission;
    state.monitor.drone.takeoff[data.drone.id] = {
      lat: data.location.lat,
      lng: data.location.lng,
    };
    sessionStorage.setItem('monitor', JSON.stringify(state.monitor));
  },
  [types.ADD_FLY_HISTORY](state, data) {
    // state.monitor = mission;
    if (state.monitor.drone.history[data.drone.id] == null) {
      state.monitor.drone.history[data.drone.id] = [];
    }

    state.monitor.drone.history[data.drone.id].push({
      lat: data.location.lat,
      lng: data.location.lng,
    });
    sessionStorage.setItem('monitor', JSON.stringify(state.monitor));
  },
  [types.CLEAN_FLY_HISTORY](state, data) {
    state.monitor.drone.history[data.drone.id] = [];

    sessionStorage.setItem('monitor', JSON.stringify(state.monitor));
  },
  [types.MONITOR_SET_TAB](state, data) {
    state.titleOptions.tab = data;
    sessionStorage.setItem('monitorTitle', JSON.stringify(state.titleOptions));
  },
  [types.MONITOR_SET_MAP](state, data) {
    state.titleOptions.map = data;
    sessionStorage.setItem('monitorTitle', JSON.stringify(state.titleOptions));
  },
  [types.CLEAN_MONITOR_STATE](state) {
    sessionStorage.removeItem('monitorTitle');
    state.titleOptions = new MonitorTitleStateVM();

    sessionStorage.removeItem('monitor');
    state.monitor = new MonitorStateVM();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
