import { Polygon } from 'vue2-google-maps';

const props = {
  draggable: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => {
      return {
        strokeWeight: 3,
        strokeColor: '#84CC78',
        strokeOpacity: 0.8,
        fillColor: '#B3EDAA',
        fillOpacity: 0.15,
        zIndex: 1,
      };
    },
  },
};

export default {
  extends: Polygon,
  props,
  name: 'FlyArea',
};