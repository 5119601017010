import enums from './enum/zh_TW';
import page from './page/zh_TW';
import response from './response/zh_TW';
import route from './route/zh_TW';
import map from './map/zh_TW';

export default {
  enums,
  page,
  response,
  route,
  map,
  button: {
    confirm: '確認',
    add: '新增',
    cancel: '取消',
    delete: '刪除',
    create: '新增',
    save: '儲存',
    next: '下一步',
    done: '完成',
    copy: '複製',
    apply: '套用',
    ok: '確認',
    rtlGo: '執行',
    yes: '是',
    select: 'Select Files',
    search: 'Search',
    no: '否',
    import: '匯入',
    export: '匯出',
    url: '鏈結',
    on: '開',
    off: '關',
    edit: '編輯',
    welcome: '歡迎',
    back: '返回',
  },
  validation: {
    required: '必填',
    mustSelect: '必選',
    length: '長度必須為 {length} 字元',
    number: '只能包含數字',
    exist: '此{name}已經存在',
    notExist: '{name}不存在',
    minLength: '長度必須大於 {minLength} 字元',
    droneName: '確定要立刻斷電{droneName}嗎？',
  },
  placeholder: {
    select: '請選擇',
  },
  errorMsg: {
    waypointInvalid: '航點不合法',
    nestNotBind: '請選擇一個基站',
    browserUnsupport: '瀏覽器不支援這個功能',
    requireSecureConnection: '必須透過安全的方式連線才能使用這個功能',
    requireGeolocationPermission: '必須提供權限存取您的地理位置',
    getLocationFailed: '無法取得地理位置',
  },
  header: {
    fullscreen: '全螢幕',
    message: '訊息',
    notification: '通知',
    profile: '個人資料',
    settings: '設定',
    signOut: '登出',
    selectLocatoin: '請選擇一個場域',
    setPass: '變更密碼',
    oldPassword: '舊密碼',
    Account: '帳號',
    Location: '場域',
    Imperial: '英制',
    Metric: '公制',
  },
  login: {
    email: '電子郵件',
    password: '密碼',
    signIn: '登入',
    signOut: '登出',
    register: '註冊',
    or: '或',
    rememberMe: '記住我',
    welcome: '歡迎',
    right_reserved: ' 版權所有',
  },
  requiredHint: '為必填欄位',
  processing: '處理中',
  messageBox: {
    success: '成功',
    info: '提示',
    warning: '警告',
    error: '錯誤',
  },
  formatter: {
    dateTime: 'YYYY.MM.DD HH:mm',
  },
  dashboard: {
    weatherStation: '氣象站',
    wind: '風速',
    from: '從',
    N: '北',
    NNE: '北北東',
    NE: '東北',
    NEE: '東北東',
    E: '東',
    SEE: '東南東',
    SE: '東南',
    SSE: '南南東',
    S: '南',
    SSW: '南南西',
    SW: '西南',
    SWW: '西南西',
    W: '西',
    NWW: '西北西',
    NW: '西北',
    NNW: '北北西',
    empty: '',
    humidity: '濕度',
    Precipitation: '雨量',
    Pressure: '氣壓',
    flight_summary: '飛行摘要',
    temperature: '溫度',
    Jan: '1月',
    Feb: '2月',
    Mar: '3月',
    Apr: '4月',
    May: '5月',
    Jun: '6月',
    Jul: '7月',
    Aug: '8月',
    Sep: '9月',
    Oct: '10月',
    Nov: '11月',
    Dec: '12月',
    total_flight: '總飛行次數',
    total_flight_time: '總飛行時數',
    Mins: 'Mins',
    drone_nest_status: '無人機/基站狀態',
    Capacity: '電量',
    Sensors: '感測器',
    Status: '狀態',
    GPS: 'GPS',
    Compass: '羅盤',
    Gyro: '陀螺儀',
    Accelerometer: '加速度計',
    Barometer: '氣壓計',
    Gimbal: '雲台',
    IR_camera: '紅外線攝影機',
    Rangefinder: '對地測距模組',
    Battery: '電池',
    env_temp: '環境溫度',
    nest_temp: '基站溫度',
    no_connection: '沒有連線',
    no_devices: '沒有裝置',
    no_data: '沒有資料',
  },
  notify: {
    title: '通知',
    new: '則新訊息',
    clear: '清除全部',
    target: '目標',
    detected: '{drone} 偵測到異常人士入侵',
    alertWindow: {
      Alert: '警示',
      Event: '事件',
    },
    gettingGeolocation: '正在取得地理位置，請允許存取。',
  },
};
