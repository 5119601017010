import BaseVM from '@/models/';

export class OlSourceVM extends BaseVM {
  init() {
    return {
      name: '',
      component: null,
    };
  }
}
