import EmptyLayout from '@/views/layout/EmptyLayout';

const Dashboard = (resolve) => {
  require.ensure(['@/views/admin/Dashboard.vue'], () => {
    resolve(require('@/views/admin/Dashboard.vue'));
  });
};

const Calendar = (resolve) => {
  require.ensure(['@/views/admin/Calendar.vue'], () => {
    resolve(require('@/views/admin/Calendar.vue'));
  });
};

const SelectGroup = (resolve) => {
  require.ensure(['@/views/admin/SelectGroup.vue'], () => {
    resolve(require('@/views/admin/SelectGroup.vue'));
  });
};

const AssignMap = (resolve) => {
  require.ensure(
    ['@/views/admin/assign/Map.vue'],
    () => { resolve(require('@/views/admin/assign/Map.vue')); },
    'mission',
  );
};

const PlaybackMap = (resolve) => {
  require.ensure(
    ['@/views/admin/assign/Map.vue'],
    () => { resolve(require('@/views/admin/history/Detail.vue')); },
  );
};

const MissionList = (resolve) => {
  require.ensure(
    ['@/views/admin/mission/Index.vue'],
    () => { resolve(require('@/views/admin/mission/Index.vue')); },
    'mission',
  );
};

const MissionMap = (resolve) => {
  require.ensure(
    ['@/views/admin/mission/Map.vue'],
    () => { resolve(require('@/views/admin/mission/Map.vue')); },
    'mission/create',
  );
};

const AerialSurveySetArea = (resolve) => {
  require.ensure(
    ['@/views/admin/mission/AerialSurveySetArea.vue'],
    () => { resolve(require('@/views/admin/mission/AerialSurveySetArea.vue')); },
    'mission/create',
  );
};

// #endregion

// #region Monitor
const Monitor = (resolve) => {
  require.ensure(
    ['@/views/admin/monitor/Index.vue'],
    () => { resolve(require('@/views/admin/monitor/Index.vue')); },
    'mission',
  );
};

// #endregion

// #region Setting

const Device = (resolve) => {
  require.ensure(['@/views/admin/device/Device.vue'], () => {
    resolve(require('@/views/admin/device/Device.vue'));
  });
};

const DeviceMap = (resolve) => {
  require.ensure(
    ['@/views/admin/device/DeviceMap.vue'],
    () => { resolve(require('@/views/admin/device/DeviceMap.vue')); },
  );
};

const DeviceProfile = (resolve) => {
  require.ensure(
    ['@/views/admin/device/DeviceProfile.vue'],
    () => { resolve(require('@/views/admin/device/DeviceProfile.vue')); },
  );
};

const DeviceNestProfile = (resolve) => {
  require.ensure(
    ['@/views/admin/device/DeviceNestProfile.vue'],
    () => { resolve(require('@/views/admin/device/DeviceNestProfile.vue')); },
  );
};

const CameraProfile = (resolve) => {
  require.ensure(
    ['@/views/admin/device/DeviceCameraProfile.vue'],
    () => { resolve(require('@/views/admin/device/DeviceCameraProfile.vue')); },
  );
};

const WeatherProfile = (resolve) => {
  require.ensure(
    ['@/views/admin/device/DeviceWeatherProfile.vue'],
    () => { resolve(require('@/views/admin/device/DeviceWeatherProfile.vue')); },
  );
};

const NVRProfile = (resolve) => {
  require.ensure(
    ['@/views/admin/device/DeviceNVRProfile.vue'],
    () => { resolve(require('@/views/admin/device/DeviceNVRProfile.vue')); },
  );
};

// #endregion

// #region Events

const Events = (resolve) => {
  require.ensure(
    ['@/views/admin/event/Index.vue'],
    () => { resolve(require('@/views/admin/event/Index.vue')); },
  );
};

// #endregion

const History = (resolve) => {
  require.ensure(['@/views/admin/history/Index.vue'], () => {
    resolve(require('@/views/admin/history/Index.vue'));
  });
};

// #region Setting

const Setting = (resolve) => {
  require.ensure(['@/views/admin/setting/Index.vue'], () => {
    resolve(require('@/views/admin/setting/Index.vue'));
  });
};

const SettingLocationMap = (resolve) => {
  require.ensure(['@/views/admin/setting/LocationMap.vue'], () => {
    resolve(require('@/views/admin/setting/LocationMap.vue'));
  });
};

const PreviewOlSourceMap = (resolve) => {
  require.ensure(['@/views/admin/setting/OlSourcePreview.vue'], () => {
    resolve(require('@/views/admin/setting/OlSourcePreview.vue'));
  });
};

const AccountSetting = (resolve) => {
  require.ensure(['@/views/admin/account/AccountSetting.vue'], () => {
    resolve(require('@/views/admin/account/AccountSetting.vue'));
  });
};

const Message = (resolve) => {
  require.ensure(['@/views/admin/message/Index.vue'], () => {
    resolve(require('@/views/admin/message/Index.vue'));
  });
};

// #endregion

// #endregion

// #region History Video Pick Album
const PickAlbum = (resolve) => {
  require.ensure(['@/views/admin/history/PickAlbum.vue'], () => {
    resolve(require('@/views/admin/history/PickAlbum.vue'));
  });
};
// #endregion

export default [
  /** 場域底下的無人機、鳥巢即時數據 */
  {
    path: '',
    redirect: 'dashboard',
    hidden: true,
  },
  {
    path: 'dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'dashboard',
      icon: 'tachometer-alt',
    },
  },

  /** 未使用 */
  {
    path: 'calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      title: 'calendar',
      icon: 'tachometer-alt',
    },
  },

  /** 登入後選擇場域 */
  {
    path: 'selectGroup',
    name: 'selectGroup',
    component: SelectGroup,
    meta: {
      title: 'selectGroup',
    },
    hidden: true,
  },

  /** 個人帳戶設定 */
  {
    path: 'accountSetting',
    name: 'accountSetting',
    component: AccountSetting,
    meta: {
      title: 'accountSetting',
    },
    hidden: true,
  },

  /** 指派任務到某飛機 */
  {
    path: 'assign/drone/:drone/mission/:mission',
    component: AssignMap,
    name: 'assignMap',
    meta: {
      title: 'assignMap',
      icon: 'mission-plus',
    },
    hidden: true,
  },

  /** 未使用，估計跟 trigger 有關 */
  {
    path: 'assign/trigger/mission/:mission',
    component: AssignMap,
    name: 'assignTriggerMap',
    meta: {
      title: 'assignMap',
      icon: 'mission-plus',
    },
    hidden: true,
  },

  /** 規劃無人機執行的任務的頁面 */
  {
    path: 'mission',
    name: 'mission',
    redirect: { name: 'missionList' },
    component: EmptyLayout,
    meta: {
      title: 'mission',
      icon: 'cogs',
    },
    children: [
      {
        path: 'list',
        component: MissionList,
        name: 'missionList',
        meta: {
          title: 'missionList',
          icon: 'list',
        },
        hidden: true,
      },
      {
        path: 'create',
        component: MissionMap,
        name: 'missionCreateMap',
        meta: {
          title: 'missionCreateMap',
          icon: 'mission-plus',
        },
        hidden: true,
      },
      {
        path: 'create_with_counting',
        component: MissionMap,
        name: 'missionCreateMapWithCounting',
        meta: {
          title: 'missionCreateMap',
          icon: 'mission-plus',
          counting: true,
        },
        hidden: true,
      },
      {
        path: 'edit',
        component: MissionMap,
        name: 'missionEditMap',
        meta: {
          title: 'missionEditMap',
          icon: 'info-circle',
        },
        hidden: true,
      },
      {
        path: 'edit_with_counting',
        component: MissionMap,
        name: 'missionEditMapWithCounting',
        meta: {
          title: 'missionEditMap',
          icon: 'info-circle',
          counting: true,
        },
        hidden: true,
      },
      {
        path: 'aerial/zone',
        component: AerialSurveySetArea,
        name: 'missionAerialSurveySetArea',
        meta: {
          title: 'missionAerialSurveySetArea',
          icon: 'info-circle',
        },
        hidden: true,
      },
    ],

  },

  /** 即時監控無人機串流影像、位置，以及操作無人機 */
  {
    path: 'monitor',
    name: 'monitor',
    redirect: { name: 'monitorHome' },
    component: EmptyLayout,
    meta: {
      title: 'monitor',
      icon: 'cogs',
    },
    children: [
      {
        path: 'monitorPanel',
        component: Monitor,
        name: 'monitorHome',
        meta: {
          title: 'monitor',
        },
        hidden: true,
      },
    ],
  },

  /** 用戶設定、場域設定、禁飛區（含最低高度(Building)、最高高度(High Restriction) 限制）設定、用戶 log、關於 FMS 的頁面 */
  {
    path: 'setting',
    name: 'setting',
    redirect: { name: 'settingHome' },
    component: EmptyLayout,
    meta: {
      title: 'setting',
    },
    children: [
      {
        path: 'panel',
        component: Setting,
        name: 'settingHome',
        meta: {
          title: 'setting',
        },
        hidden: true,
      },
      {
        path: 'location-create-map',
        component: SettingLocationMap,
        name: 'createLocationMap',
        meta: {
          title: 'createLocationMap',
        },
        hidden: true,
      },
      {
        path: 'location-edit-map',
        component: SettingLocationMap,
        name: 'editLocationMap',
        meta: {
          title: 'editLocationMap',
        },
        hidden: true,
      },
      {
        path: 'ol-source-preview',
        component: PreviewOlSourceMap,
        name: 'olSourcePreview',
        meta: {
          title: 'olSourcePreview',
        },
        hidden: true,
      },
    ],
  },

  /** 場域底下無人機(Drone)、鳥巢(Nest)、監控(IP Cam)、氣象監測裝置(Weather Station)、影像儲存裝置(NVR)的資訊  */
  {
    path: 'device',
    name: 'device',
    redirect: { name: 'list' },
    component: EmptyLayout,
    meta: {
      title: 'device',
    },
    children: [
      {
        path: 'list',
        component: Device,
        name: 'list',
        meta: {
          title: 'device',
        },
        hidden: true,
      },
      {
        path: ':type/map',
        component: DeviceMap,
        name: 'deviceMap',
        meta: {
          title: 'deviceMap',
        },
        hidden: true,
      },
      {
        path: 'deviceProfile',
        component: DeviceProfile,
        name: 'deviceProfile',
        meta: {
          title: 'deviceProfile',
        },
        hidden: true,
      },
      {
        path: 'deviceNestProfile',
        name: 'deviceNestProfile',
        component: DeviceNestProfile,
        meta: {
          title: 'deviceNestProfile',
        },
        hidden: true,
      },
      {
        path: 'deviceCameraProfile',
        name: 'deviceCameraProfile',
        component: CameraProfile,
        meta: {
          title: 'deviceCameraProfile',
        },
        hidden: true,
      },
      {
        path: 'deviceWeatherProfile',
        name: 'deviceWeatherProfile',
        component: WeatherProfile,
        meta: {
          title: 'deviceWeatherProfile',
        },
        hidden: true,
      },
      {
        path: 'deviceNVRProfile',
        name: 'deviceNVRProfile',
        component: NVRProfile,
        meta: {
          title: 'deviceNVRProfile',
        },
        hidden: true,
      },
    ],
  },

  /** 已廢棄的功能，trigger 是當什麼條件被觸發，forward 是讓無人機做什麼事
   * @example 攝影機拍到汽車（trigger），無人機前去追蹤汽車動向（forward）
   * */
  {
    path: 'event',
    name: 'event',
    redirect: { name: 'eventList' },
    component: EmptyLayout,
    meta: {
      title: 'event',
    },
    children: [
      {
        path: 'list',
        component: Events,
        name: 'eventList',
        meta: {
          title: 'event',
        },
        hidden: true,
      },
    ],
  },

  /** 相當於 Accounting（用戶 log） + 無人機 log */
  {
    path: 'message',
    name: 'message',
    redirect: { name: 'messageList' },
    component: EmptyLayout,
    meta: {
      title: 'message',
    },
    children: [
      {
        path: 'list',
        component: Message,
        name: 'messageList',
        meta: {
          title: 'message',
        },
        hidden: true,
      },
    ],
  },

  /** 歷史記錄頁面，可以播放歷史軌跡、下載照片影片 */
  {
    path: 'history',
    name: 'history',
    redirect: { name: 'historyList' },
    component: EmptyLayout,
    meta: {
      title: 'history',
      icon: 'tachometer-alt',
    },
    children: [
      {
        path: 'list',
        component: History,
        name: 'historyList',
        meta: {
          title: 'history',
        },
        hidden: true,
      },
      {
        path: 'detail',
        component: PlaybackMap,
        name: 'playbackMap',
        meta: {
          title: 'history',
        },
        hidden: true,
      },
      {
        path: 'pickAlbum',
        component: PickAlbum,
        name: 'pickAlbum',
        meta: {
          title: 'history',
        },
        hidden: true,
      },
    ],
  },
];
