import Vue from 'vue';
import state from '@/store/state';
import VueI18n from 'vue-i18n';

// 自訂語系
import en_US from './i18n/en_US.js';
import zh_TW from './i18n/zh_TW.js';

// Element語系
import enLocale from 'element-ui/lib/locale/lang/en';
import twLocale from 'element-ui/lib/locale/lang/zh-TW';

Vue.use(VueI18n);
Vue.config.lang = state.appLang;

const i18n = new VueI18n({
  locale: state.appLang,
  fallbackLocale: 'en-US',
  messages: {
    'en-US': {
      ...en_US,
      ...enLocale,
    },
    'zh-TW': {
      ...zh_TW,
      ...twLocale,
    },
  },
});

export default i18n;
