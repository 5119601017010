import { appAxios as axios } from '@/services/api/';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import config from 'config';


export default {
  version() {
    return axios.get('/v1/system/version')
      .then((response) => {
        return response.data;
      });
  },
};
