export default {
  tab: {
    eventList: '事件列表',
    triggerForward: '事件觸發/事件轉發',
  },
  title: {
    eventList: '事件列表',
    triggers: '事件觸發',
    forwards: '事件轉發',
  },
  triggerEditDialog: {
    addTitle: '新增一筆事件觸發',
    updateTitle: '編輯事件觸發',
    name: '名稱',
    type: '類型',
    enabled: '啟用',
    auto: '自動執行',
    message: '訊息',
    ip: 'IP位置',
    mission: '任務',
    types: {
      alert: '通知',
      curise: '巡航',
      mission: '任務',
    },
    load: '載入一個任務',
  },
  forwardEditDialog: {
    addTitle: '新增一筆事件轉發',
    updateTitle: '編輯事件轉發',
    name: '名稱',
    type: 'Type',
    enabled: '啟用',
    auto: 'Auto',
    message: 'Message',
    ip: 'IP Address',
    mission: 'Mission',
    types: {
      alert: '通知',
      curise: '巡航',
      mission: '任務',
      http: 'HTTP Post',
      websocket : 'WebSocket',
      event : '無人機事件',
      status : '無人機狀態',
    },
    mode : '模式',
    forwardType: '轉發類型',
    url : '鏈結',
    account : '帳號',
    password : '密碼',
  },
  triggerDeleteDialog: {
    title: '確定刪除嗎？',
    body: '資料刪掉就沒囉！',
    confirm: '刪除觸發',
  },
  forwardDeleteDialog: {
    title: '確定刪除嗎？',
    body: '資料刪掉就沒囉！',
    confirm: '刪除轉發',
  },
  name: 'Name',
  type: '通知',
  typeType: {
    alert: '通知',
    cruise: '巡航',
    mission: '任務',
  },
  time: '事件發生時間',
  description: '描述',
  droneStatus: '無人機狀態',
  droneEvent: '無人機事件',
};
