import * as types from './mutations_type';

// 切換語言
export const changeLang = ({ commit, state }, lang) => {
  commit(types.SET_LANG, lang);
};

// 開闔 Sidebar
export const toggleSidebar = ({ commit }) => {
  commit(types.COLLAPSE_SIDEBAR);
};

// 設定 Loading
export const setLoading = ({ commit }, loading) => {
  commit(types.SET_LOADING);
};

export const setProcessingData = ({ commit }, data) => {
  commit(types.SET_PROCESSING_DATA, data);
};

export const changeMeasurement = ({ commit, state }, measurement) => {
  commit(types.SET_MEASUREMENT, measurement);
};

export const changeCustomer = ({ commit, state }, customer) => {
  commit(types.SET_CUSTOMER, customer);
};
