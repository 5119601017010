import { Stroke, Fill, Style } from 'ol/style.js';
import FlyArea from '@/components/GoogleMaps/FlyArea.js';
import NoFlyZone from '@/components/GoogleMaps/NoFlyZone.js';
import ObstacleZone from '@/components/GoogleMaps/ObstacleZone.js';
import AltitudeLimitZone from '@/components/GoogleMaps/AltitudeLimitZone.js';
import SurveyZone from '@/components/GoogleMaps/SurveyZone.js';
import OlFlyArea from '@/components/OpenLayers/FlyArea.vue';
import OlNoFlyZone from '@/components/OpenLayers/NoFlyZone.vue';
import OlObstacleZone from '@/components/OpenLayers/ObstacleZone.vue';
import OlAltitudeLimitZone from '@/components/OpenLayers/AltitudeLimitZone.vue';
import OlSurveyZone from '@/components/OpenLayers/SurveyZone.vue';

import { CopyObject, HexToRgbA } from '@/utils/common.js';

export const formatNoFlyZoneTypeToComponent = (type) => {
  switch (type) {
    case 'fence':
      return FlyArea;
    case 'no_fly':
      return NoFlyZone;
    case 'altitude_limit':
      return AltitudeLimitZone;
    case 'obstacle':
      return ObstacleZone;
    case 'survey':
      return SurveyZone;
    default:
      return FlyArea;
  }
};

export const calculBounds = (latlngs) => {
  const bound = new google.maps.LatLngBounds();
  latlngs.forEach((latlng) => {
    bound.extend(latlng);
  });
  return bound;
};

export const formatNoFlyZoneTypeToOlComponent = (type) => {
  switch (type) {
    case 'fence':
      return OlFlyArea;
    case 'no_fly':
      return OlNoFlyZone;
    case 'altitude_limit':
      return OlAltitudeLimitZone;
    case 'obstacle':
      return OlObstacleZone;
    case 'survey':
      return OlSurveyZone;
    default:
      return OlFlyArea;
  }
};

// format from latitude to lat
export const GeographicShorter = (value) => {
  if (value == null) {
    return null;
  }
  const data = { ...value };
  delete data.latitude;
  delete data.longitude;
  return {
    lat: value.latitude,
    lng: value.longitude,
    ...data,
  };
};

// fotmat from lat to latitude
export const GeographicLonger = (value) => {
  if (value == null) {
    return null;
  }
  const data = { ...value };
  delete data.lat;
  delete data.lng;
  return {
    latitude: (typeof (value.lat) === 'function') ? value.lat() : value.lat,
    longitude: (typeof (value.lng) === 'function') ? value.lng() : value.lng,
    ...data,
  };
};

export const GeographicXyToOl = (value) => [
  value.y,
  value.x,
];

export const GeographicXyToGoogle = (value) => ({
  lat: value.x,
  lng: value.y,
});

// format API style coordinate to OpenLayers Format
export const GeographicApiToOl = (value) => [
  value.longitude,
  value.latitude,
];

// format API style coordinate to OpenLayers Format
export const GeographicOlToApi = (value) => ({
  latitude: value[1],
  longitude: value[0],
});

// format from [ [ {latitude: 0, longitude: 1} ] ] to [ [ [ 1, 0 ] ] ]
export const PolygonsApiToOl = (polygons) => polygons.map((fence) => {
  const formatFence = fence.map(GeographicApiToOl);

  // open layers 的 coordinate 必須頭尾連起來
  if (formatFence.length > 0) {
    formatFence.push(formatFence[0]);
  }
  return formatFence;
});

export const PolygonsOlToApi = (polygons) => polygons.map((fence) => {
  const formatFence = fence.map(GeographicOlToApi);

  formatFence.pop();
  return formatFence;
});

export const BoundsGoogleToApi = (value) => {
  const northEast = value.getNorthEast();
  const southWest = value.getSouthWest();
  return [
    southWest.lat(),
    southWest.lng(),
    northEast.lat(),
    northEast.lng(),
  ];
};

export const BoundsApiToGoogle = (value) => {
  const southWest = value.slice(0, 2);
  const northEast = value.slice(2, 4);
  return {
    sw: {
      lat: southWest[0],
      lng: southWest[1],
    },
    ne: {
      lat: northEast[0],
      lng: northEast[1],
    },
  };
};

export const BoundsOlToApi = (value) =>
  // definition: https://openlayers.org/en/latest/apidoc/module-ol_extent.html#~Extent
  [
    value[1], // miny
    value[0], // minx
    value[3], // maxy
    value[2], // maxx
  ];
export const BoundsApiToOl = (value) =>
  // definition: https://openlayers.org/en/latest/apidoc/module-ol_extent.html#~Extent
  [
    value[1], // minx
    value[0], // miny
    value[3], // maxx
    value[2], // maxy
  ];
export const FormatPolygonsToLatLng = (polygons) => polygons.map((polygon) => polygon.map(GeographicShorter));

export const FormatPolygonsToLatitudeLongitude = (polygons) => polygons.map((polygon) => polygon.map(GeographicLonger));

export const GoogleMapsMarkerToOl = (value) => ({
  src: value.url,
  anchor: (value.scale)
    ? [value.anchor.x / value.scale, value.anchor.y / value.scale]
    : [value.anchor.x, value.anchor.y],
  anchorXUnits: 'pixels',
  anchorYUnits: 'pixels',
  scale: (value.scale) ? value.scale : 1,
  rotation: value.rotation,
});

export const FormatBounds = (bounds, accurate = 0) => {
  if (bounds == null) {
    return;
  }
  // 設定要保留幾個小數點後幾位到 API ，目前 API 只支援整數
  // 如果遇到效能問題，也許可以把底數 10 改成 2
  const magnification = Math.pow(10, accurate);

  const boundsExpend = bounds.map((value) => value * magnification);

  const formatedBounds = [
    Math.floor(boundsExpend[0]) / magnification,
    Math.floor(boundsExpend[1]) / magnification,
    Math.ceil(boundsExpend[2]) / magnification,
    Math.ceil(boundsExpend[3]) / magnification,
  ];

  if (formatedBounds[0] === formatedBounds[2]) {
    formatedBounds[2] += 1 / magnification * 1;
  }

  if (formatedBounds[1] === formatedBounds[3]) {
    formatedBounds[3] += 1 / magnification * 1;
  }

  return formatedBounds;
};

export const FormatGoogleDashToOl = (style) => {
  const icon = style.icons[0];
  if (style.icons.length !== 1 || icon.icon.path !== 'M 0,-1 0,1') {
    console.warn('not implement');
  }
  const repeat = parseInt(icon.repeat.replace('px', ''));
  const draw = repeat / icon.icon.scale;
  return [new Style({
    stroke: new Stroke({
      color: HexToRgbA(icon.icon.strokeColor, icon.icon.strokeOpacity),
      width: icon.icon.scale,
      lineDash: [draw, repeat],
    }),
  })];
};

export const FormatGoogleLineToOl = (style) => [
  new Style({
    stroke: new Stroke({
      color: HexToRgbA(style.strokeColor, style.strokeOpacity),
      width: style.strokeWeight,
    }),
  }),
];

export const ExpendSurveyRange = (survey) => survey.waypoints.map((waypoint, index) => {
  const footprintRange = CopyObject(survey.footprints[waypoint.footprint_index]);
  if (footprintRange == null) {
    return [];
  }
  return footprintRange.map((offset) => ({
    x: offset.latitude + waypoint.x,
    y: offset.longitude + waypoint.y,
  }));
});

/**
 * @param {Object} options getCurrentPosition option, not required
 * 備註：
 * 1. Chrome 50 開始要求使用安全連線、Firefox 55 開始要求使用安全連線
 *    詳細請參考 MDN 文件： https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API#Browser_compatibility
 * 2. 在 Windows 環境下， Chrome 與 Firefox 都必須透過網際網路上的服務定位
 *    只有 Windows 的 Edge 在系統有 GPS 裝置的前提下可以透過 GPS 而不需要網際網路服務定位
 */
export const GPSGetCurrentPosition = (options) => {
  const positionPromise = new Promise((resolve, reject) => {
    if (navigator == null || navigator.geolocation == null) {
      reject();
      return;
    }
    if (typeof (navigator.geolocation.getCurrentPosition) !== 'function') {
      reject();
      return;
    }
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
  return positionPromise;
};
