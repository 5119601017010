export default {
    title: 'Message Center',
    user: 'User',
    allUser: 'All User',
    type: 'Type',
    time: 'Time',
    command: 'Command',
    desc: 'Description',
    level: 'Level',
    typeType: {
      all: 'All Type',
      login: 'Login',
      users: 'User',
      groups: 'Location',
      missions: 'Mission',
      noflyzones: 'No Fly Zone',
      histories: 'History',
      router_plan: 'Router Find',
      trigger_events: 'Trigger Event',
      drones: 'Drone',
      drone_events: 'Drone Event',
      nests: 'Nest',
      cameras: 'Camera',
      weather_stations: 'Weather Station',
      triggers: 'Trigger',
      forwards: 'Forward',
    },
    levelType: {
      all: 'All Level',
      error: 'Error',
      warn: 'Warning',
      info: 'Info',
    },
    action: 'Action',
    actionType: {
      GET: 'Fetch',
      POST: 'Add/Command',
      PUT: 'Update',
      DELETE: 'Remove',
    },
    result: 'Result',
    resultType: {
      success: 'Success',
      fail: 'Fail',
    },
  };
  