import moment from 'moment';
import config from 'config';

moment.locale('zh-TW');

export function isPast(dateTime) {
  return moment()
    .isAfter(dateTime);
}

export function isAfter(dateTime) {
  return moment()
    .isPast(dateTime);
}

export function formatDate(dateTime) {
  if (moment.invalid(dateTime)) {
    return moment(dateTime)
      .format(config.momentDateFormat);
  }
  return dateTime;
}

export function formatDateTime(dateTime, formatString) {
  if (formatString == null) {
    formatString = config.momentDateTimeFormat;
  }
  if (moment.invalid(dateTime)) {
    return moment(dateTime)
      .format(formatString);
  }
  return dateTime;
}

export function formatDateTimeNoSecond(dateTime) {
  if (moment.invalid(dateTime)) {
    return moment(dateTime)
      .format(config.momentDateTimeFormatNoSecond);
  }
  return dateTime;
}

export function today() {
  return moment()
    .format(config.momentDateFormat);
}
