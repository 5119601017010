import monitor from './monitor/zh_TW';
import mission from './mission/zh_TW';
import setting from './setting/zh_TW';
import device from './device/zh_TW';
import event from './event/zh_TW';
import message from './message/zh_TW';
import history from './history/zh_TW';
export default {
  monitor,
  mission,
  setting,
  device,
  event,
  message,
  history,
};