<template>
  <default-dialog
    :show-dialog="show"
    :has-cancel="alertWindow.withCancel"
    next-button-text="OK"
    :center="true"
    :width="alertWindow.width"
    :show-close="true"
    title="Change Password"
    @close="close"
    @next="updatePassword"
  >
    <template slot="main">
      <el-form
        ref="form"
        :model="account"
        label-width="120px"
      >
        <el-form-item :label="$t('page.setting.userTab.dialog.oldPassword')">
          <el-input
            v-model="oldPassword"
            type="password"
          />
        </el-form-item>
        <el-form-item :label="$t('page.setting.userTab.dialog.newPassword')">
          <el-input
            v-model="newPassword"
            type="password"
          />
        </el-form-item>
        <el-form-item :label="$t('page.setting.userTab.dialog.confirmPassword')">
          <el-input
            v-model="confirmPassword"
            type="password"
          />
        </el-form-item>
      </el-form>
    </template>
  </default-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupApi from '@/services/api/domain/group';
import TriggerEventApi from '@/services/api/domain/group/triggerEvent';
import DefaultDialog from '@/components/DefaultDialog.vue';
import { CopyObject } from '@/utils/common.js';
import userApi from '@/services/api/domain/user';

export default {
  name: 'Notification',
  components: {
    DefaultDialog,
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      username: 'user/username',
    }),
  },
  data() {
    return {
      notificationHistory: [],
      currentNotification: null,
      alertWindow: {
        width: '651px',
        nextButton: 'OK',
        withCancel: true,
        title: null,
        method: 'dialog',
      },
      show: false,
      account: CopyObject(this.$store.state.account.account),
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      // username:'',
    };
  },
  watch: {

  },
  async mounted() {

  },
  destroyed() {

  },
  methods: {
    close() {
      this.show = false;
    },

    async updatePassword() {
      this.errors = [];

      if (!this.oldPassword) {
        this.errors.push('Old password required.');
      }
      if (!this.newPassword) {
        this.errors.push('New password required.');
      }
      if (!this.confirmPassword) {
        this.errors.push('Confirmed password required.');
      }
      if (!(this.newPassword === this.confirmPassword)) {
        this.errors.push('New password inconsistent.');
      }

      if (this.errors.length) {
        this.$showFail(this.errors[0]);
        return true;
      }

      const data = {
        account: this.username,
        password: this.confirmPassword,
        old_password: this.oldPassword,
      };
      try {
        const status = await userApi.changePass(data);
        console.log(status);
        this.$showSuccess(status.msg);
        this.show = false;
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
      } catch (error) {
        this.$showFail(error);
      }
    },
  },
};
</script>

<style lang="scss">
.el-dropdown-menu.notification-history-list {
  padding-top: 0;
  padding-bottom: 5px;
  width: 200px;

  .el-dropdown-menu__item.title, .el-dropdown-menu__item.title:not(.is-disabled):hover {
    line-height: 35px;
    border-radius: 4px 4px 0px 0px;
    background-color: #203547;
    cursor: default;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
  }

  .el-dropdown-menu__item {
    width: 100%;
    line-height: initial;
    font-size: 12px;
    display: table;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 1px solid rgba(191, 191, 191, 0.6);

    span {
      display: table-cell;
      vertical-align: middle;
    }

    span.time {
      padding-bottom: 5px;
      width: 40px;
      text-align: right;
      vertical-align: bottom;
      color: #c0c0c0;
    }
  }

  .el-dropdown-menu__item:not(.title) {
    height: 50px;
  }

  .popper__arrow::after {
    border-bottom-color: #203547 !important;
  }

  .right {
    float: right
  }

  .footer {
    margin-top: 6.5px;
    text-align: center;
    .el-button.el-button--mini {
      height: 16px;
    }
  }

}

.image-button.bell {
  outline:0;
  width: 24px;
  height: 29px;
  vertical-align: middle;
  background-image: url('/asserts/icon/inforBar-alert-icon-normal.svg')
}

.notification-body {
  text-align: center;
  h2 {
    margin: 0;
    font-size: 22px;
    font-weight: normal;
    line-height: 22px;
  }
}

.notification-bubble{
  position: fixed;
  top: 65px;
  right: 10px;
  width: 378px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  font-size: 13px;
  button.close {
    background-image: url('/asserts/icon/alert-close-icon.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 15px;
    height: 15px;
  }

  p {
    line-height: 13px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0;
    text-align: left;
  }

  p.notification-bubble__time {
    position: absolute;
    right: 22px;
    bottom: 13px;
  }
}

.notification-section {
  position: relative;
  .notification-section__count {
    position: absolute;
    top: 10px;
    right: 0;
    color: white;
    font-size: 12px;
    line-height: 15px;

    background-color: #c90000;
    width: 15px;
    height: 15px;
    -webkit-clip-path: circle();
    clip-path: circle();
  }
}
</style>
