import BaseVM from '@/models/';

export class NoFlyZoneVM extends BaseVM {
  init() {
    return {
      name: null,
      type: null,
      polygons: [
        [],
      ],
      max_altitude: null,
      min_altitude: null,
      level: 0,
    };
  }
}
