import config from 'config';

const { lang, measurement, domain } = config.customerSetting;

export default {
  appLang: sessionStorage.getItem('appLang') ? JSON.parse(sessionStorage.getItem('appLang')) : lang,
  sidebarCollapse: false,
  loading: false,
  error: null,
  processingData: null,
  measurement: sessionStorage.getItem('measurement') ? JSON.parse(sessionStorage.getItem('measurement')) : measurement,
  customer: sessionStorage.getItem('customer') ? JSON.parse(sessionStorage.getItem('customer')) : domain,
};
