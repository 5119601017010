<template>
  <el-container class="admin-layout">
    <admin-sidebar />
    <el-container>
      <el-header height="56px">
        <admin-header />
      </el-header>
      <div v-loading.body="loading"
           class="main-container"
      >
        <el-main>
          <router-view :key="`group-${$store.state.user.groupId}`"/>
        </el-main>
        <!-- <el-footer height="40px">
          <admin-footer />
        </el-footer> -->
      </div>
    </el-container>
  </el-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AdminHeader from '@/views/layout/components/AdminHeader.vue';
  import AdminSidebar from '@/views/layout/components/AdminSidebar.vue';
  import AdminFooter from '@/views/layout/components/AdminFooter.vue';
  import AdminMainLayout from '@/views/layout/AdminMainLayout.vue';

  export default {
    name: 'AdminLayout',
    components: {
      AdminHeader,
      AdminSidebar,
      AdminFooter,
      AdminMainLayout,
    },
    computed: {
      ...mapGetters({
        loading: 'loading',
      }),
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';
  .admin-layout {
    height: 100vh;
    overflow: hidden;

    .el-header {
      padding: 0 !important;
    }

    .main-container {
      overflow-y: auto;

      .el-main {
        padding: 0px;
        height: calc(100vh - #{$header_height});
      }

      .el-footer {
        background-color: #eeeeee;
      }
    }
  }
</style>
