export default {
  altitude_limit: '限高區',
  no_fly: '禁飛區',
  obstacle: '建築物',
  latitude: '緯度',
  longitude: '經度',
  altitude: '高度',
  rtl_altitude: '返航高度',
  distance: '距離',
  time: '時間',
  takeoff: '起飛',
  title: {
    drone: {
      total: '總數: ',
      selectall: '全選',
      auto_heading: '自動頭向',
    },
  },
  nest: {
    name: '基站',
    open: '開啟',
    close: '關閉',
  },
  ipCam: {
    name: '網路攝影機',
  },
  aerialSurvey: {
    title: 'Survey',
    capturePoint: '航點拍照',
    intervalShooting: '間隔拍照',
    footprint: 'Footprint',
    longitudinal: 'Longitudinal',
    lateral: 'Lateral',
    area: 'Area',
    pictures: 'Pictures',
    numberOfStrips: 'Number of strips',
    update: '更新',
    altitude: 'Altitude',
  },
  unit: {
    m: 'm',
    m2: 'm<sup>2</sup>',
    pcs: 'pcs',
    mmpx: 'mm/px',
  },
  waypointMenu: {
    photo_duration: '間隔拍照',
    selected: '被選擇',
    altitude: '高度',
    delay: '停留',
    speed: '到下一點的速度',
    tilt: '相機傾角',
    heading: '無人機頭像',
    Take_Off: '起飛',
    Waypoint: '航點',
    Counting: '計數行點',
    Landing: '降落',
    Nest: '基站',
    Return_Home: '返航',
    Camera: '拍照',
    Intervalcam: '間隔拍照',
    selectNest: '換基站',
    notify_ai: 'ai辨識',
  },
};
