import locationTab from './locationTab/zh_TW';
import userTab from './userTab/zh_TW';
import accountingTab from './accountingTab/zh_TW';
import olSourceTab from './olSourceTab/zh_TW';
export default {
  tab: {
    domain: 'Domain',
    user: '人員',
    location: '場域',
    noFlyZone: '禁飛區',
    accounting: '歷程紀錄',
    about: '關於',
    olSource: '圖資設定',
  },
  locationTab,
  userTab,
  accountingTab,
  olSourceTab,
  // move to userTab ?
  deleteConfirm: {
    title: '確定刪除嗎？',
    body: 'Once you delete the Drone, you can\'t undo it!',
    userBody: '刪除人員資料救不回來',
  },
  delete: {
    user: '刪除人員',
  },
  // should move to drone related file
  droneId : 'Drone ID',
  droneName : 'Drone Name',
  domain : 'Domain',
  group : 'Group',
  MACAddress : 'MAC Address',
  loading: '讀取中…',
};
