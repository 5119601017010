import { RadianToAngle } from '@/utils/math.js';
import { estimatePathTime } from '@/utils/mission.js';
import { app } from '@/app.js';
import * as types from '../mutations_type';

const droneErrorTopics = ['system_status', 'pilot_status'];
const groundStatus = ['charging', 'standby'];

const state = {
  mqtt: null,
  group: null,
  drones: [],
  droneLatest: {},
  monitoredDrone: '',
  deviceLatestThrottle: {
    drones: {},
    nests: {},
  },
  droneHistory: {},
  nests: [],
  nestLatest: {},
  weathers: [],
  weatherLatest: {},
  timeout: 10000,
  timeoutCheckInterval: 5000,
  throttleTimeout: {
    low: 5000,
    normal: 3500,
    realtime: 750,
  },
};

const getters = {
  mqttInstance(state) {
    return state.mqtt;
  },
  timeoutCheckInterval(state) {
    return state.timeoutCheckInterval;
  },
  droneLatestMessage(state) {
    return state.droneLatest;
  },
  droneStatus(state) {
    const droneIds = Object.keys(state.droneLatest);
    const droneStatus = droneIds.map((droneId) => {
      const droneMessage = state.droneLatest[droneId];
      if (droneMessage.timeout == null || droneMessage.timeout.alive !== true) {
        return {
          detail: 'offline',
          simple: 'offline',
        };
      }

      // errors check
      for (const topic of droneErrorTopics) {
        // for in get key
        for (const key in droneMessage[topic]) {
          const topicKeyContent = droneMessage[topic][key];
          if (key.endsWith('error') && topicKeyContent === true) {
            return {
              detail: 'error',
              simple: 'error',
              error: {
                topic,
                key,
              },
            };
          }
        }
      }

      const droneStatus = droneMessage.heartbeat && droneMessage.heartbeat.drone_status;

      if (droneStatus === 'error') {
        return {
          detail: 'error',
          simple: 'error',
        };
      }

      if (droneStatus === 'charging') {
        return {
          detail: 'charging',
          simple: 'charging',
        };
      }

      const standbyModes = [
        'standby',
        'onmission',
        'takingoff',
        'hovering',
        'clickngo',
        'landing',
        'rtl',
      ];
      if (standbyModes.includes(droneStatus)) {
        return {
          detail: droneStatus,
          simple: 'standby',
        };
      }

      return {
        detail: 'standby',
        simple: 'standby',
      };
    });
    return droneStatus.reduce((accumulator, currentValue, currentIndex) => {
      accumulator[droneIds[currentIndex]] = currentValue;
      return accumulator;
    }, {});
  },
  droneHistoryMessage(state) {
    return state.droneHistory;
  },
  nestLatestMessage(state) {
    return state.nestLatest;
  },
  weatherLatesetMessage(state) {
    return state.weatherLatest;
  },
};

const actions = {
  connect({ commit }, config) {
    commit(types.MQTT_CONNECT, config);
  },
  disconnect({ commit }) {
    commit(types.MQTT_DISCONNECT);
  },
  subscribeGroupAlertFacade({ commit }, {
    group,
    job,
  }) {
    commit(types.MQTT_SET_GROUP, {
      group,
      job,
    });
  },
  unsubscribeGroupAlertFacade({ commit }) {
    commit(types.MQTT_UNSET_GROUP);
  },
  subscribeDrone({
    state,
    commit,
  }, {
    drone,
    job,
  }) {
    commit(types.MQTT_ADD_DRONE, {
      group: state.group,
      drone,
      job,
    });
  },
  unsubscribeDrone({
    state,
    commit,
  }, { drone }) {
    commit(types.MQTT_REMOVE_DRONE, {
      group: state.group,
      drone,
    });
  },
  clearDroneStatus({
    state,
    commit,
  }, { drone }) {
    commit(types.MQTT_CLEAR_DRONE_STATUS, {
      group: state.group,
      drone,
    });
  },
  clearDroneHistory({ commit }, { drone }) {
    commit(types.MQTT_CLEAR_DRONE_HISTORY, drone);
  },
  subscribeNest({
    state,
    commit,
  }, {
    nest,
    job,
  }) {
    commit(types.MQTT_ADD_NEST, {
      group: state.group,
      nest,
      job,
    });
  },
  unsubscribeNest({
    state,
    commit,
  }, { nest }) {
    commit(types.MQTT_REMOVE_NEST, {
      group: state.group,
      nest,
    });
  },
  subscribeWeather({
    state,
    commit,
  }, {
    weather,
    job,
  }) {
    commit(types.MQTT_ADD_WEATHER, {
      group: state.group,
      weather,
      job,
    });
  },
  unsubscribeWeather({ commit }, { weather }) {
    commit(types.MQTT_REMOVE_WEATHER, {
      group: state.group,
      weather,
    });
  },
  setWeatherStatus({ commit }, { weather, payload }) {
    commit(types.MQTT_ARRIVED_WEATHER, {
      topic: `${weather.topic}/heartbeat`,
      messagePayload: payload,
      weather,
    });
  },
  gimbal1_tilt({ commit }, {
    droneId,
    value,
  }) {
    commit(types.MQTT_GIMBAL1_TILT, {
      droneId,
      value,
    });
  },
  gimbal2_tilt({ commit }, {
    droneId,
    value,
  }) {
    commit(types.MQTT_GIMBAL2_TILT, {
      droneId,
      value,
    });
  },
};

const mutations = {
  // init at app.js
  [types.MQTT_INIT](state, mqtt) {
    state.mqtt = mqtt;
  },
  [types.MQTT_CONNECT](state, config) {
    state.mqtt.mqttConnect(config);
  },
  [types.MQTT_DISCONNECT](state) {
    state.mqtt.mqttDisconnect();
  },
  [types.MQTT_SET_GROUP](state, {
    group,
    job,
  }) {
    if (typeof (job) !== 'function') {
      job = () => {
      };
    }
    state.group = group;
    state.mqtt.subscribeGroupAlertFacade(group, job);
  },
  [types.MQTT_UNSET_GROUP](state) {
    state.mqtt.unsubscribeGroupAlertFacade();
  },
  [types.MQTT_ADD_DRONE](state, {
    group,
    drone,
    job,
  }) {
    if (typeof (job) !== 'function') {
      job = () => {
      };
    }
    if (state.drones.find((d) => d.topic === drone.topic) != null) {
      console.log(`drone ${drone.id} already subscribed`);
    } else {
      mutations[types.MQTT_CLEAR_DRONE_HISTORY](state, { id: drone.id });
      app.$set(state.droneLatest, drone.id, {});
      this.commit(`mqtt/${types.MQTT_ADD_DEVICE_THROTTLE}`, { deviceType: 'drones', id: drone.id });
      state.mqtt.subscribeDroneFacade(group, drone, job);
      state.mqtt.subscribeDroneDetailFacade(group, drone, job);
    }
    state.drones.push(drone);
  },
  [types.MQTT_REMOVE_DRONE](state, {
    group,
    drone,
  }) {
    const droneIndex = state.drones.findIndex(((d) => d.topic === drone.topic));
    state.drones.splice(droneIndex, 1);
    if (state.drones.find((d) => d.topic === drone.topic) != null) {
      console.log(`other still using drone ${drone.id}`);
    } else {
      state.mqtt.unsubscribeDrone(group, drone);
      app.$delete(state.droneLatest, drone.id);
      this.commit(`mqtt/${types.MQTT_REMOVE_DEVICE_THROTTLE}`, { deviceType: 'drones', id: drone.id });
    }
  },
  [types.MQTT_ADD_NEST](state, {
    group,
    nest,
    job,
  }) {
    if (typeof (job) !== 'function') {
      job = () => {
      };
    }
    if (state.nests.find((d) => d.topic === nest.topic) != null) {
      console.log(`nest ${nest.id} already subscribed`);
    } else {
      app.$set(state.nestLatest, nest.id, {});
      state.mqtt.subscribeNestFacade(group, nest, job);
    }
    state.nests.push(nest);
  },
  [types.MQTT_REMOVE_NEST](state, {
    group,
    nest,
  }) {
    const droneIndex = state.nests.findIndex(((d) => d.topic === nest.topic));
    state.nests.splice(droneIndex, 1);
    if (state.nests.find((d) => d.topic == nest.topic) != null) {
      console.log(`other still using drone ${nest.id}`);
    } else {
      state.mqtt.unsubscribeNest(group, nest);
      app.$delete(state.nestLatest, nest.id);
    }
  },
  [types.MQTT_ADD_WEATHER](state, {
    group,
    weather,
    job,
  }) {
    if (typeof (job) !== 'function') {
      job = () => {
      };
    }
    if (state.weathers.find((d) => d.topic === weather.topic) != null) {
      console.log(`weather ${weather.id} already subscribed`);
    } else {
      app.$set(state.weatherLatest, weather.id, {});
      state.mqtt.subscribeWeatherFacade(group, weather, job);
    }
    state.weathers.push(weather);
  },
  [types.MQTT_REMOVE_WEATHER](state, {
    group,
    weather,
  }) {
    const droneIndex = state.weathers.findIndex(((d) => d.topic === weather.topic));
    state.weathers.splice(droneIndex, 1);
    if (state.weathers.find((d) => d.topic === weather.topic) != null) {
      console.log(`other still using drone ${weather.id}`);
    } else {
      state.mqtt.unsubscribeWeather(group, weather);
      app.$delete(state.weatherLatest, weather.id);
    }
  },
  [types.MQTT_ADD_DEVICE_THROTTLE](state, { deviceType, id }) {
    state.deviceLatestThrottle[deviceType][id] = {};
  },
  [types.MQTT_REMOVE_DEVICE_THROTTLE](state, { deviceType, id }) {
    this._vm.$delete(state.deviceLatestThrottle[deviceType], id);
  },
  [types.MQTT_ARRIVED](state, {
    message,
    msg,
  }) {
    const topic = message.destinationName;
    const messagePayload = msg;

    const drone = state.drones.find((d) => topic.startsWith(d.topic));
    if (drone) {
      const payload = { device: drone, topic, messagePayload };
      this.commit('mqtt/MQTT_DRONE_THROTTLE', payload);
      return;
    }

    const nest = state.nests.find((d) => topic.startsWith(d.topic));
    if (nest) {
      const payload = { device: nest, topic, messagePayload };
      this.commit('mqtt/MQTT_NEST_THROTTLE', payload);
      return;
    }

    const weather = state.weathers.find((d) => topic.startsWith(d.topic));
    if (weather) {
      mutations[types.MQTT_ARRIVED_WEATHER](state, {
        topic,
        messagePayload,
        weather,
      });
    }
  },

  /** @todo 控制前端接收 mqtt message 頻率，間隔750毫秒的會擋掉。而3.5秒內的資料會先暫存起來，之後一次性更新 */
  [types.MQTT_DRONE_THROTTLE](state, { device, topic, messagePayload }) {
    const current = +new Date();
    const droneStage = state.deviceLatestThrottle.drones[device.id];
    const shouldReject = droneStage && droneStage[topic]
      && current - droneStage[topic].receivedTime < state.throttleTimeout.realtime;
    if (shouldReject) {
      return;
    }
    if (messagePayload.broadcast === 'alert' && messagePayload.data.type === 'detect') {
      return;
    }
    // 不降頻優先處理
    const isMonitored = device.id === state.monitoredDrone;
    const isGPS = messagePayload.broadcast === 'gps' || (messagePayload.broadcast === 'alert' && messagePayload.data.type === 'counting');
    if (isMonitored || isGPS) {
      this.commit(`mqtt/${types.MQTT_ARRIVED_DRONE}`, {
        topic,
        messagePayload,
        drone: device,
      });
      return;
    }

    droneStage[topic] = {
      messagePayload,
      drone: device,
      receivedTime: current,
    };

    const duration = current - droneStage.lastUpdateTime;
    if (duration > state.throttleTimeout.normal) {
      delete droneStage.lastUpdateTime;
      Object.keys(droneStage).forEach((msgTopic) => {
        this.commit(`mqtt/${types.MQTT_ARRIVED_DRONE}`, {
          topic: msgTopic,
          messagePayload: droneStage[msgTopic].messagePayload,
          drone: droneStage[msgTopic].drone,
        });
      });
      droneStage.lastUpdateTime = current;
      return;
    }

    const isFirstReceivedData = !state.droneLatest[device.id][messagePayload.broadcast];
    if (!isFirstReceivedData) {
      return;
    }

    // 初始化一次性更新的時間
    droneStage.lastUpdateTime = current;

    // 每個 drone 底下的 topic 第一次收到資料，會直接 commit()
    this.commit(`mqtt/${types.MQTT_ARRIVED_DRONE}`, {
      topic,
      messagePayload,
      drone: device,
    });
  },
  [types.MQTT_NEST_THROTTLE](state, { device, topic, messagePayload }) {
    const current = +new Date();
    const firstReceived = state.nestLatest[device.id].timeout === undefined;
    const isStatusUpdated = !firstReceived
      && state.nestLatest[device.id].heartbeat.cover_status !== messagePayload.data.cover_status;
    const lastUpdate = state.nestLatest[device.id].timeout
      && state.nestLatest[device.id].timeout.lastUpdate;
    if (firstReceived || isStatusUpdated || current - lastUpdate > state.throttleTimeout.low) {
      this.commit(`mqtt/${types.MQTT_ARRIVED_NEST}`, {
        topic,
        messagePayload,
        nest: device,
      });
    }
  },
  [types.MQTT_ARRIVED_DRONE](state, {
    topic,
    messagePayload,
    drone,
  }) {
    let messageType = topic.substring(drone.topic.length + 1);
    if (messageType === 'heartbeat') {
      const lastHeartbeat = state.droneLatest[drone.id][messageType];
      const lastOnGround = lastHeartbeat && groundStatus.includes(lastHeartbeat.drone_status);
      if (lastOnGround && messagePayload.data.drone_status === 'onmission') {
        mutations.MQTT_CLEAR_DRONE_HISTORY(state, { id: drone.id });
      }
    }

    if (messageType === 'alert') {
      if (messagePayload.data.hasOwnProperty('mission')) {
        if (messagePayload.data.mission.duration == null || messagePayload.data.mission.duration === 0) {
          console.warn('alert mission no duration');
          messagePayload.data.mission.duration = estimatePathTime(messagePayload.data.mission.tasks);
        }
        mutations.MQTT_CLEAR_DRONE_HISTORY(state, { id: drone.id });
      }
      if (messagePayload.data.type === 'counting') {
        messageType = 'counting';
      }
    }

    if (messageType === 'gps') {
      messagePayload.data.latitude = messagePayload.data.latitude / Math.pow(10, 7);
      messagePayload.data.longitude = messagePayload.data.longitude / Math.pow(10, 7);

      const droneLatestHeartbeat = state.droneLatest[drone.id].heartbeat;
      const onGround = droneLatestHeartbeat && groundStatus.includes(droneLatestHeartbeat);
      const takeOrHistoryData = {
        id: drone.id,
        messagePayload,
      };
      if (onGround) {
        mutations[types.MQTT_ARRIVED_DRONE_TAKEOFF](state, takeOrHistoryData);
      } else {
        mutations[types.MQTT_ARRIVED_DRONE_MISSION_HISTORY](state, takeOrHistoryData);
      }
    }

    if (messageType === 'attitude') {
      messagePayload.data.pitchAngle = RadianToAngle(messagePayload.data.pitch);
      messagePayload.data.rollAngle = RadianToAngle(messagePayload.data.roll);
      messagePayload.data.yawAngle = RadianToAngle(messagePayload.data.yaw);
    }

    app.$set(state.droneLatest[drone.id], messageType, messagePayload.data);
    if (state.droneLatest[drone.id].timeout == null) {
      app.$set(state.droneLatest[drone.id], 'timeout', {
        alive: false,
        lastUpdate: null,
      });
    }
    state.droneLatest[drone.id].timeout.alive = true;
    state.droneLatest[drone.id].timeout.lastUpdate = new Date();
  },
  [types.MQTT_ARRIVED_DRONE_TAKEOFF](state, {
    id,
    messagePayload,
  }) {
    app.$set(state.droneLatest[id], 'take_off', messagePayload.data);
  },
  [types.MQTT_ARRIVED_DRONE_MISSION_HISTORY](state, {
    id,
    messagePayload,
  }) {
    state.droneHistory[id].gps.push(messagePayload.data);
  },
  [types.MQTT_CLEAR_DRONE_STATUS](state, drone) {
    app.$set(state.droneLatest, drone.id, {});
    mutations[types.MQTT_CLEAR_DRONE_HISTORY](state, drone);
  },
  [types.MQTT_CLEAR_DRONE_HISTORY](state, drone) {
    if (state.droneHistory[drone.id] == null) {
      app.$set(state.droneHistory, drone.id, {});
    }
    app.$set(state.droneHistory[drone.id], 'gps', []);
  },
  [types.MQTT_CLEAR_NEST_STATUS](state, nest) {
    app.$set(state.nestLatest, nest.id, {});
  },
  [types.MQTT_CLEAR_WEATHER_STATUS](state, weather) {
    app.$set(state.weatherLatest, weather.id, {});
  },
  [types.MQTT_ARRIVED_NEST](state, {
    topic,
    messagePayload,
    nest,
  }) {
    const messageType = topic.substring(nest.topic.length + 1);

    app.$set(state.nestLatest[nest.id], messageType, messagePayload.data);
    if (state.nestLatest[nest.id].timeout == null) {
      app.$set(state.nestLatest[nest.id], 'timeout', {
        alive: false,
        lastUpdate: null,
      });
    }
    state.nestLatest[nest.id].timeout.alive = true;
    state.nestLatest[nest.id].timeout.lastUpdate = new Date();
  },
  [types.MQTT_ARRIVED_WEATHER](state, {
    topic,
    messagePayload,
    weather,
  }) {
    if (state.weatherLatest[weather.id] == null) {
      return;
    }
    const messageType = topic.substring(weather.topic.length + 1);
    app.$set(state.weatherLatest[weather.id], messageType, messagePayload.data);
    if (state.weatherLatest[weather.id].timeout == null) {
      app.$set(state.weatherLatest[weather.id], 'timeout', {});
    }
    state.weatherLatest[weather.id].timeout.alive = true;
    state.weatherLatest[weather.id].timeout.lastUpdate = new Date();
  },
  // interval init at app.js
  [types.MQTT_TIMEOUT](state) {
    const now = new Date();

    for (const droneId in state.droneLatest) {
      if (state.droneLatest[droneId].timeout == null || state.droneLatest[droneId].timeout.alive === false) {
        continue;
      }
      let lastMessageTime = state.droneLatest[droneId].timeout && state.droneLatest[droneId].timeout.lastUpdate;
      if (lastMessageTime) {
        lastMessageTime = new Date(lastMessageTime);
        if (now - lastMessageTime > state.timeout) {
          console.log('drone timeout');
          state.droneLatest[droneId].timeout.alive = false;
          mutations[types.MQTT_CLEAR_DRONE_STATUS](state, { id: droneId });
        }
      }
    }

    for (const nestId in state.nestLatest) {
      if (state.nestLatest[nestId].timeout == null || state.nestLatest[nestId].timeout.alive === false) {
        continue;
      }

      let lastMessageTime = state.nestLatest[nestId].timeout && state.nestLatest[nestId].timeout.lastUpdate;
      if (lastMessageTime) {
        lastMessageTime = new Date(lastMessageTime);
        if (now - lastMessageTime > state.timeout) {
          state.nestLatest[nestId].timeout.alive = false;
          mutations[types.MQTT_CLEAR_NEST_STATUS](state, { id: nestId });
        }
      }
    }

    for (const weatherId in state.weatherLatest) {
      if (state.weatherLatest[weatherId].timeout == null || state.weatherLatest[weatherId].timeout.alive === false) {
        continue;
      }
      let lastMessageTime = state.weatherLatest[weatherId].timeout && state.weatherLatest[weatherId].timeout.lastUpdate;
      if (lastMessageTime) {
        lastMessageTime = new Date(lastMessageTime);
        if (now - lastMessageTime > state.timeout * 12) {
          state.weatherLatest[weatherId].timeout.alive = false;
          mutations[types.MQTT_CLEAR_WEATHER_STATUS](state, { id: weatherId });
        }
      }
    }
  },
  [types.MQTT_GIMBAL1_TILT](state, {
    droneId,
    value,
  }) {
    app.$set(state.droneLatest[droneId], 'gimbal1_tilt', { tilt: value });
  },
  [types.MQTT_GIMBAL2_TILT](state, {
    droneId,
    value,
  }) {
    app.$set(state.droneLatest[droneId], 'gimbal2_tilt', { tilt: value });
  },
  [types.MQTT_ADD_MONITOR_DRONE](state, droneId) {
    state.monitoredDrone = droneId;
  },
  [types.MQTT_REMOVE_MONITOR_DRONE](state) {
    state.monitoredDrone = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
