import BaseVM from '@/models/';

export class GroupVM extends BaseVM {
  init() {
    return {
      name: '',
      latitude: 0,
      longitude: 0,
      comment: '',
      admin: [],
      fence: [],
      elz: [],
      altitude: [15, 120],
      account: null,
      password: null,
      rtl_altitude: 15,
      stream_config: {
        ip: null,
        webrtc_port: 8443,
        port: 1935,
        real_ip: null,
        api_port: 8087,
        account: null,
        password: null,
      },
      alerts: {
        detect: {
          user: '',
          car: '',
        },
      },
    };
  }
}
