import * as types from '@/store/mutations_type';
import _ from 'lodash';
import config from 'config';

import accountApi from '@/services/api/account';

const state = {
  token: sessionStorage.getItem('token') || null,
  expire: sessionStorage.getItem('expire') || null,
};

if (config.environment === 'development') {
  console.log('auth/state', state);
}

const getters = {
  token: (state) => state.token,
  expire: (state) => state.expire,
  isAuth: (state) => state.token != null,
};

const actions = {
  // accessToken 過期計時器
  setSignOutTimer({ dispatch }, expirationTime) {
    setTimeout(() => {
      if (state.refreshToken) {
        dispatch('refreshToken', state.refreshToken);
      } else {
        dispatch('clearAuth');
      }
    }, expirationTime);
  },
  async signin({ commit, dispatch }, authData) {
    try {
      // 呼叫登入 Api
      const { expire, token } = await accountApi.signin(authData);
      const data = {
        expire,
        token,
      };
      commit(types.SIGNIN_SUCCESS, data);
      // accessToken 過期計時
      // dispatch('setSignOutTimer', data.expiresIn);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  async signout({ commit }) {
    try {
      // 呼叫登出 Api
      await accountApi.signout(state.refreshToken);
      commit(types.SIGNOUT_SUCCESS);
      return Promise.resolve();
    } catch (error) {
      commit(types.SIGNOUT_SUCCESS);
      return Promise.reject(error);
    }
  },
  async refreshToken({ commit, dispatch }, refreshToken) {
    try {
      const { data } = await accountApi.refreshToken(refreshToken);
      commit(types.SIGNIN_SUCCESS, data);
      // accessToken 過期計時
      dispatch('setSignOutTimer', data.expiresIn);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  clearAuth({ commit }) {
    try {
      commit(types.SIGNOUT_SUCCESS);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  [types.SIGNIN_SUCCESS](state, data) {
    state.token = data.token;
    state.expire = data.expire;
    const jwt = data.token.split('.');
    const jwtPayload = JSON.parse(atob(jwt[1]));
    // state.user = JSON.parse(jwtPayload.id);
    // const id = JSON.parse(jwtPayload.id);
    // state.id = id.ID;
    // state.exp = id.exp;
    // state.origIat = id.orig_ita;

    sessionStorage.setItem('token', data.token);
    sessionStorage.setItem('expire',  data.expire);
    // sessionStorage.setItem('id', jwtPayload.id);
    sessionStorage.setItem('user', jwtPayload.id);
  },
  [types.SIGNOUT_SUCCESS](state) {
    state.token = null;
    state.expire = null;
    state.id = null;
    state.exp = null;
    state.origIat = null;

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('expire');
    sessionStorage.removeItem('id');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
