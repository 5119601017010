import { appAxios as axios } from '@/services/api/';
import state from '@/store/state';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import config from 'config';


export default {
  signin(authData) {
    let requestTime = timestamp();
    let authMsg = {
      'method': 'POST',
      'action': '/api/fms/login',
      'auth': 'web',
      'dateTime': requestTime,
    };
    let hashCode = hmacSHA256(JSON.stringify(authMsg), config.secretKey);

    return axios.post(`/fms/login?request_time=${encodeURIComponent(requestTime)}&auth=${hashCode}`, authData)
      .then((response) => {
        return response.data;
      });
  },
  signout(refreshToken) {
    // return axios.post(`/signout`, null, {
    //   params: {
    //     refreshToken,
    //   },
    // });
  },
  refreshToken(refreshToken) {
    // return axios
    //   .get(`/refreshToken`, {
    //     params: {
    //       refreshToken,
    //     },
    //   })
    //   .then((response) => {
    //     return response.data;
    //   });
  },

};

// private function
const timestamp = (date) => {
  var pad = function(amount, width) {
    var padding = '';
    while (padding.length < width - 1 && amount < Math.pow(10, width - padding.length - 1))
    {
      padding += '0';
    }
    return padding + amount.toString();
  };
  date = date ? date : new Date();
  var offset = date.getTimezoneOffset();
  return pad(date.getFullYear(), 4) +
      '-' + pad(date.getMonth() + 1, 2) +
      '-' + pad(date.getDate(), 2) +
      'T' + pad(date.getHours(), 2) +
      ':' + pad(date.getMinutes(), 2) +
      ':' + pad(date.getSeconds(), 2) +
      '' + (offset > 0 ? '-' : '+') +
      '' + pad(Math.floor(Math.abs(offset) / 60), 2) +
      ':' + pad(Math.abs(offset) % 60, 2);
};