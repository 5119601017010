import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1`;

export default {
  get(id) {
    return axios.get(`${apiUrl}/users/${id}`).then((response) => {
      return response.data;
    });
  },
  getList() {
    return axios
      .get(`${apiUrl}/users`)
      .then((response) => {
        return response.data;
      });
  },
  create(user) {
    return axios
      .post(`${apiUrl}/users`, user)
      .then((response) => {
        return response.data;
      });
  },
  update(userId, user) {
    return axios
      .put(`${apiUrl}/users/${userId}`, user)
      .then((response) => {
        return response.data;
      });
  },
  delete(userId) {
    return axios
      .delete(`${apiUrl}/users/${userId}`)
      .then((response) => {
        return response.data;
      });
  },
  
  changePass(data) {
    return axios
      .put(`${apiUrl}/user/password`, data)
      .then((response) => {
        return response.data;
      });
  },
};
