import Vue from 'vue';
import VueRouter from 'vue-router';
import config from 'config';
import NProgress from 'nprogress';
import store from '@/store';
import i18n from '@/locales/';
import 'nprogress/nprogress.css';

import routes from './routes';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// 解決 navigation with a new navigation error
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

NProgress.configure({ showSpinner: false });

// 檢查權限
function hasPermission(roles, allowRoles) {
  if (roles.indexOf('Admin') >= 0) {
    return true;
  }
  if (!allowRoles) {
    return true;
  }
  return roles.some((role) => allowRoles.indexOf(role) >= 0);
}

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // 合法路徑
  if (to.matched.length <= 0) {
    next(false);
    return;
  }

  // 開啟載入進度條
  NProgress.start();
  // 根據路由更改 document title
  document.title = `${i18n.t(`route.${to.meta.title}`)} - ${config.siteName}`;

  const requireLogin = to.matched.some((record) => record.meta.requiresAuth);
  if (!requireLogin) {
    next();
    return;
  }

  const isLogin = store.getters['auth/isAuth'];
  if (isLogin) {
    next();
    return;
  }

  next({
    name: 'adminLogin',
    query: {
      returnUrl: to.fullPath,
    },
  });
});

router.afterEach(() => {
  // 結束載入進度條
  NProgress.done();
});

export default router;
