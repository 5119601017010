<template>
  <el-dropdown
    ref="notificationDropdown"
    class="header-btn"
    trigger="click"
    @command="loadHistory"
  >
    <div class="notification-section">
      <button class="image-button bell" />
      <div
        v-if="notificationHistory.length > 0"
        class="notification-section__count"
      >
        {{ notificationHistory.length > 100 ? "N" : notificationHistory.length }}
      </div>
    </div>

    <el-dropdown-menu
      slot="dropdown"
      class="notification-history-list"
    >
      <el-dropdown-item class="title">
        {{ $t("notify.title") }}<span class="right">{{ notificationHistory.length }} {{ $t("notify.new") }}</span>
      </el-dropdown-item>

      <section class="content-container">
        <el-dropdown-item
          v-for="(notification, index) in notificationHistory"
          :key="`notification-${index}`"
          :command="index"
        >
          <span class="message">{{ notification.message }}</span>
          <span class="time">{{ formatAgo(notification.ago) }}</span>
        </el-dropdown-item>
      </section>
      <section class="footer">
        <el-button
          size="mini"
          type="info"
          @click="clearAll"
        >
          {{ $t("notify.clear") }}
        </el-button>
      </section>
    </el-dropdown-menu>

    <default-dialog
      :show-dialog="currentNotification != null && alertWindow.method == 'dialog'"
      :has-cancel="alertWindow.withCancel"
      :next-button-text="$t('button.ok')"
      :center="true"
      :width="alertWindow.width"
      :footer-align="alertWindow.withCancel ? 'false' : 'true'"
      @close="event => closeAlert(event == 'click-cancel', false)"
      @next="closeAlert(true, true)"
    >
      <template
        v-if="currentNotification"
        slot="main"
      >
        <div class="notification-body">
          <img src="/asserts/icon/popup-alert-icon.svg">
          <h2>{{ $t(`notify.alertWindow.${alertWindow.title}`) }}</h2>
          {{ currentNotification.message
          }}<template v-if="currentNotification.target">
            , do you want to send <span class="drone">{{ currentNotification.target }}</span> to execute this mission?
          </template>
          <template v-if="currentNotification.mission">
            <data-table-thumbnail
              :value="currentNotification.mission"
              height="83px"
            />
          </template>
          <div style="display: -webkit-box;">
            <div
              v-if="currentNotification.type == 'detect'"
              class="player-1"
            >
              <flash-rtmp-player :url="currentNotification.rgb_url" />
            </div>
            <div
              v-if="currentNotification.type == 'detect'"
              v-show="currentNotification.objects.length > 0"
              class="object-list"
            >
              <div class="title">
                <div class="static">
                  {{ $t("notify.target") }} ({{ currentNotification.objects.length }})
                </div>
              </div>
              <div class="item-list">
                <div
                  v-for="(item, index) in currentNotification.objects"
                  :key="index"
                  :class="`waypoint-item ${item.activate ? ' activate' : ''}`"
                >
                  <div class="waypoint-index">
                    <span>{{ item.label }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentNotification.type == 'detect'">
            <span class="timestamp-label">{{ $t(`notify.alertWindow.Event`) }}</span>
            <span class="timestamp-label alignRight">{{
              currentNotification.receiveAt | formatDateTime("HH:mm:ss")
            }}</span>
          </div>
        </div>
      </template>
    </default-dialog>

    <div
      v-if="currentNotification != null && alertWindow.method == 'bubble'"
      class="notification-bubble"
      @click="closeAlert(true, false)"
    >
      <button class="image-button close" />
      <p class="notification-bubble__message">
        {{ currentNotification.message }}
      </p>
      <p class="notification-bubble__type">
        {{ $t(`notify.alertWindow.Alert`) }}
      </p>
      <p class="notification-bubble__time" />
    </div>
  </el-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GroupApi from '@/services/api/domain/group';
import TriggerEventApi from '@/services/api/domain/group/triggerEvent';
import DroneApi from '@/services/api/domain/group/drone';
import DefaultDialog from '@/components/DefaultDialog.vue';
import FlashRtmpPlayer from '@/components/Monitor/FlashRtmpPlayer.vue';
import DataTableThumbnail from '@/components/DataTable/Thumbnail.vue';

export default {
  name: 'Notification',
  components: {
    DefaultDialog,
    FlashRtmpPlayer,
    DataTableThumbnail,
  },
  data() {
    return {
      notificationHistory: [],
      currentNotification: null,
      alertWindow: {
        width: '651px',
        nextButton: 'OK',
        withCancel: true,
        title: null,
        method: 'dialog',
      },
      autoClose: null,
      timer: null,
      drones: {},
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
    }),
    alerts() {
      return {
        detect: this.group.alerts.detect.people !== 'off' && this.group.alerts.detect.people !== '',
      };
    },
  },
  watch: {
    async group(current, previous) {
      if (current == null) {
        return;
      }
      if (previous != null) {
        this.unsubscribeGroupAlertFacade();
      }
      const group = await GroupApi.get(current.id);
      await this.initDrones();
      this.subscribeGroupAlertFacade({
        group: group.data,
        job: this.messageArrive,
      });
    },
  },
  async mounted() {
    this.timer = setInterval(this.updateHistoryAgo, 5000);
    if (this.group != null) {
      const group = await GroupApi.get(this.group.id);
      await this.initDrones();
      this.subscribeGroupAlertFacade({
        group: group.data,
        job: this.messageArrive,
      });
    }
  },
  destroyed() {
    clearInterval(this.timer);
    this.unsubscribeGroupAlertFacade();
  },
  methods: {
    clearAll() {
      this.notificationHistory = [];
      this.$refs.notificationDropdown.hide();
    },
    formatAgo(value) {
      if (value === 0) {
        return 'Now';
      }
      return `${value}min`;
    },
    updateHistoryAgo() {
      const now = new Date();
      this.notificationHistory.forEach((hisotry) => {
        const time = hisotry.receiveAt;
        const minute = Math.floor((now - time) / 1000 / 60);
        hisotry.ago = minute;
      });
    },
    messageArrive(_, payload, destination) {
      if (destination[parseInt(destination.length) - 1] !== 'alert') {
        return;
      }
      const message = payload.data;
      switch (message.type) {
        case 'counting':
          return;
        case 'detect': {
          if (this.alerts.detect === false) {
            return;
          }
          const event = JSON.parse(message.message);
          if (event.data.objects.findIndex((object) => object.label === 'people') === -1) {
            return;
          }
          const drone = this.drones[event.sender];
          if (drone !== undefined) {
            message.message = `${this.$t('notify.detected', { drone: drone.name })}`;
            message.rgb_url = drone.rgb_url;
          } else {
            message.message = `${this.$t('notify.detected', { drone: event.sender })}`;
          }
          message.objects = event.data.objects;
          break;
        }
      }
      message.droneId = destination[parseInt(destination.length) - 2];
      message.receiveAt = new Date();
      message.time = new Date(message.time);
      message.ago = 0;
      this.showAlert(message);
    },
    loadHistory(index) {
      const notitication = this.notificationHistory[index];
      notitication.fromHistory = true;
      this.showAlert(notitication, '');
      this.notificationHistory.splice(index, 1);
    },
    async showAlert(message, method = '') {
      if (this.currentNotification != null) {
        this.closeAlert(false, false);
      }
      switch (message.type) {
        case 'confirm':
        case 'alert':
          this.autoClose = setTimeout(() => {
            this.closeAlert(false, false);
          }, 8000);
          this.alertWindow.method = 'bubble';
          break;
        case 'cruise':
          this.alertWindow.width = '788px';
          this.alertWindow.nextButton = 'Start Mission';
          this.alertWindow.withCancel = true;
          this.alertWindow.title = 'Event';
          this.alertWindow.method = 'dialog';
          break;
        case 'failsafe':
          this.alertWindow.width = '450px';
          this.alertWindow.nextButton = 'OK';
          this.alertWindow.withCancel = false;
          this.alertWindow.title = 'Alert';
          this.alertWindow.method = 'dialog';
          break;
        case 'detect': {
          this.alertWindow.width = '788px';
          this.alertWindow.withCancel = false;
          this.alertWindow.title = 'Event';
          this.alertWindow.method = method;
          break;
        }
        default: {
          const preSendTime = this.drones[message.droneId].content[message.content];
          const minTime = 15 * 1000;
          if (preSendTime && new Date() - preSendTime < minTime) {
            return;
          }
          this.drones[message.droneId].content[message.content] = new Date();

          this.autoClose = setTimeout(() => {
            this.closeAlert(false, false);
          }, 8000);
          const droneName = this.drones[message.droneId].name;
          message.message = `${droneName} : ${message.content}`;
          this.alertWindow.method = 'bubble';
          break;
        }
      }

      this.currentNotification = message;
    },
    async closeAlert(byHand, excute) {
      if (this.currentNotification == null) {
        return;
      }

      if (this.currentNotification.fromHistory == null) {
        this.notificationHistory.push(this.currentNotification);
      }

      if (!excute || this.currentNotification.type !== 'cruise') {
        this.currentNotification = null;
        return;
      }
      const loading = this.$loading({
        lock: true,
      });
      try {
        const eventId = this.currentNotification.event_id;
        this.currentNotification = null;
        const status = await TriggerEventApi.execute(this.group.id, eventId);
        this.$showSuccess(status.msg);
        loading.close();
      } catch (error) {
        this.$showFail(error);
      }
      loading.close();
    },
    async initDrones() {
      if (!this.group) {
        return true;
      }
      const drones = await DroneApi.getAll(this.group.id);
      const droneData = {};
      drones.data.drones.forEach((drone) => {
        droneData[drone.id] = {
          ...drone,
          content: {},
        };
      });
      this.drones = droneData;
      return true;
    },
    ...mapActions({
      subscribeGroupAlertFacade: 'mqtt/subscribeGroupAlertFacade',
      unsubscribeGroupAlertFacade: 'mqtt/unsubscribeGroupAlertFacade',
    }),
  },
};
</script>

<style lang="scss">
.el-dropdown-menu.notification-history-list {
  padding-top: 0;
  padding-bottom: 5px;
  width: 200px;

  .el-dropdown-menu__item.title,
  .el-dropdown-menu__item.title:not(.is-disabled):hover {
    line-height: 35px;
    border-radius: 4px 4px 0px 0px;
    background-color: #203547;
    cursor: default;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
  }

  .el-dropdown-menu__item {
    width: 100%;
    line-height: initial;
    font-size: 12px;
    display: table;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 1px solid rgba(191, 191, 191, 0.6);

    span {
      display: table-cell;
      vertical-align: middle;
    }

    span.time {
      padding-bottom: 5px;
      width: 40px;
      text-align: right;
      vertical-align: bottom;
      color: #c0c0c0;
    }
  }

  .el-dropdown-menu__item:not(.title) {
    height: 50px;
  }

  .popper__arrow::after {
    border-bottom-color: #203547 !important;
  }

  .right {
    float: right;
  }

  .content-container {
    max-height: 25vh;
    overflow: auto;
    overflow-x: hidden;
  }

  .footer {
    margin-top: 6.5px;
    text-align: center;
    .el-button.el-button--mini {
      height: 16px;
    }
  }
}

.image-button.bell {
  outline: 0;
  width: 24px;
  height: 29px;
  vertical-align: middle;
  background-image: url("/asserts/icon/inforBar-alert-icon-normal.svg");
}

.notification-body {
  text-align: center;
  h2 {
    margin: 0;
    font-size: 22px;
    font-weight: normal;
    line-height: 22px;
  }

  // top: 15px;
  .player-1 {
    // left: 15px;
    width: 368px;
    height: 207px;
    margin-left: 85px;
  }

  .object-list {
    margin-left: 30px;
    border-color: #d2d2d2;
    border-style: solid;
    border-width: 1px;
    height: 268px;
    width: 149px;

    .title {
      text-align: center;
      height: 36px;
      background: #d8d8d8;
      line-height: 36px;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
    }

    .item-list {
      height: 232px;
      overflow-y: scroll;
      overflow-x: hidden;
      overflow: auto;

      .waypoint-item {
        border-color: #d2d2d2;
        border-style: double;
        border-width: 0.2px;
        line-height: 36px;
        color: #858585;
        background-color: white;

        &:hover {
          background-color: rgba($color: #dedede, $alpha: 0.3);
        }
      }
    }
  }

  .timestamp-label {
    margin-left: -175px;
    padding-top: -113px;
    position: relative;
    top: -69px;
  }
  .alignRight {
    margin-left: 275px;
  }
}

.notification-bubble {
  position: fixed;
  top: 65px;
  right: 10px;
  width: 378px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  font-size: 13px;
  button.close {
    background-image: url("/asserts/icon/alert-close-icon.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 15px;
    height: 15px;
  }

  p {
    line-height: 13px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0;
    text-align: left;
  }

  p.notification-bubble__time {
    position: absolute;
    right: 22px;
    bottom: 13px;
  }
}

.notification-section {
  position: relative;
  .notification-section__count {
    position: absolute;
    top: 10px;
    right: 0;
    color: white;
    font-size: 12px;
    line-height: 15px;

    background-color: #c90000;
    width: 15px;
    height: 15px;
    -webkit-clip-path: circle();
    clip-path: circle();
  }
}
</style>
