import Vue from 'vue';

import * as momentUtil from '@/utils/moment';
import config from 'config';
import moment from 'moment';

Vue.filter('formatDate', (dateTime) => {
  return dateTime ? momentUtil.formatDate(dateTime) : null;
});

Vue.filter('formatDateTime', (dateTime, formatString) => {
  return dateTime ? momentUtil.formatDateTime(dateTime, formatString) : null;
});

Vue.filter('formatDateTimeNoSecond', (dateTime) => {
  return dateTime ? momentUtil.formatDateTimeNoSecond(dateTime) : null;
});

Vue.filter('formatMoney', (price) => {
  if (!price) {
    price = 0;
  }
  const temp = [];
  while (price >= 1000) {
    let part = price % 1000;
    temp.push(part.toString().padStart(3, '0'));
    price = Math.floor(price / 1000);
  }
  temp.push(price);
  return ` ${config.currency} ${temp.reverse().join(',')}`;
});

Vue.filter('roundNumber', (value, digit = 0, failback = undefined) => {
  if (typeof(value) !== 'number') {
    return failback;
  }
  const magnification = Math.pow(10, digit);
  let roundValue = (Math.round(value * magnification) / magnification).toString();

  if (digit > 0 && roundValue.indexOf('.') == -1) {
    roundValue += '.';
  }
  if (digit > 0) {
    while (roundValue.split('.')[1].length < digit) {
      roundValue += '0';
    }
  }
  if(isNaN(roundValue)) return value;
  return roundValue;
});

Vue.filter('leftPad', (value, length, fillCharter = ' ') => {
  value = value.toString();
  let fillLength = length - value.length;

  if (fillLength <= 0) {
    return value;
  } else {
    return (new Array(fillLength + 1)).join((fillCharter.toString())) + value;
  }
});


Vue.filter('duration', (value, formatString='d[d] hh:mm:ss', failback='') => {
  if (value == null || isNaN(value)) {
    return failback;
  }
  let duration = moment.duration(value, 'seconds');
  let formatted = duration.format(formatString,{ trim: false });
  return formatted;
});
