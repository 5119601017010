import BaseVM from '@/models/';

export class NVRVM extends BaseVM {
  init() {
    return {
      name: '',
      ip: '',
      port: '',
      type: 'acti',
      account : '',
      password : '',      
    };
  }
}
