import _ from 'lodash';
import config from 'config';
import * as types from '@/store/mutations_type';

import userApi from '@/services/api/domain/loginUser';
import groupApi from '@/services/api/domain/group';

const localStorageOlLayers = localStorage.getItem('olLayers');
if (localStorageOlLayers != null) {
  console.warn('browser localStorage olLayers not empty, this will overwrite .env settings');
  console.warn(localStorageOlLayers);
}

const state = {
  user: sessionStorage.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user'))
    : null,
  groupId: sessionStorage.getItem('groupId'),
  mapEngine: sessionStorage.getItem('mapEngine')
    ? sessionStorage.getItem('mapEngine')
    : process.env.VUE_APP_DEFAULT_MAP_ENGINE,
  olLayersEditable: sessionStorage.getItem('olLayersEditable')
    ? JSON.parse(sessionStorage.getItem('olLayersEditable'))
    : JSON.parse(process.env.LAYERS_EDITABLE && process.env.LAYERS_EDITABLE.toLowerCase() || false),
  olLayers: localStorageOlLayers
    ? JSON.parse(localStorageOlLayers)
    : JSON.parse(process.env.LAYERS_TILE || null),
  placeAPI: sessionStorage.getItem('placeAPI')
    ? sessionStorage.getItem('placeAPI')
    : process.env.VUE_APP_PLACE_API,
};

const getters = {
  user: (state) => state.user,
  groupId: (state) => state.groupId,
  group: (state) => {
    if (state.groupId && state.user && state.user.groups) {
      return state.user.groups.find((group) => group.id == state.groupId);
    }
    return null;
  },
  groups: (state) => ((state.user) ? state.user.groups : null),
  isAdmin: (state) => ((state.user) ? state.user.is_domain_admin : null),
  isFMSAdmin: (state) => ((state.user) ? state.user.is_fms_domain : null),
  isViewer: (state) => {
    const result = config.disableDroneControlList.filter((el) => el.domain == state.user.domain && el.account == state.user.account);

    return result.length > 0;
  },
  username: (state) => ((state.user) ? state.user.username : null),
  googleMapsKey: (state) => {
    if (config.environment == 'development'
      || state.user == null
      || state.user.google_api_key == null) {
      return config.googleMapKey;
    }
    return state.user.google_api_key;
  },
  // 除非遇到特殊狀況，否則這個 Location 應該只會在 Group 找不到適合座標時使用
  location: (state) => {
    if (state.user.domain_latitude != null && state.user.domain_longitude) {
      return {
        latitude: state.user.domain_latitude,
        longitude: state.user.domain_longitude,
      };
    }
    return {
      latitude: config.fallbackLatitude,
      longitude: config.fallbackLongitude,
    };
  },
  mapEngine: (state) => {
    // 地圖引擎清單，防止例外
    switch (state.mapEngine) {
      case 'OpenLayers':
        return 'OpenLayers';
      case 'GoogleMaps':
        return 'GoogleMaps';
      default:
        console.error('unknown map engine: ', state.mapEngine);
        return 'GoogleMaps';
    }
  },
  olSourceEdit: (state) => {
    if (typeof (state.olLayersEditable) !== 'boolean') {
      console.error('olLayersEditable not boolean');
      return false;
    }
    return state.olLayersEditable;
  },
  olSource: (state) => {
    if (!(state.olLayers instanceof Array)) {
      return [];
    }
    return state.olLayers;
  },
  placeAPI: (state) => {
    // 地圖引擎清單，防止例外
    switch (state.placeAPI) {
      case 'LatitudeLongitude':
        return 'LatitudeLongitude';
      case 'Nominatim':
        return 'Nominatim';
      case 'GoogleMaps':
        return 'GoogleMaps';
      default:
        console.error('unknown place API: ', state.placeAPI);
        return 'GoogleMaps';
    }
  },
};

const actions = {
  async getProfile({ commit }, username) {
    try {
      const { data } = await userApi.getProfile();
      data.username = username;
      commit(types.SET_USER, data);
      // if (data.map_engine) {
      //   commit(types.SET_MAP_ENGINE, data.map_engine);
      // }
      // if (data.is_ol_source_editable) {
      //   commit(types.SET_OL_SOURCE_EDITABLE, data.is_ol_source_editable);
      // }
      // if (data.ol_tile_sources) {
      //   commit(types.SET_OL_TILE_SOURCE, data.ol_tile_sources);
      // }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getGroups({ commit }) {
    try {
      const { data } = await groupApi.getAll();
      commit(types.SET_USER_GROUPS, data);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setGroupId({ commit, rootGetters }, groupId) {
    try {
      commit(types.SET_GROUP, groupId);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  clean({ commit }) {
    try {
      commit(types.CLEAN_USER);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setMapEngine({ commit }, engine) {
    try {
      commit(types.SET_MAP_ENGINE, engine);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setOlTileSource({ commit }, sources) {
    commit(types.SET_OL_TILE_SOURCE, sources);
  },
};

const mutations = {
  [types.SET_USER](state, user) {
    state.user = user;
    sessionStorage.setItem('user', JSON.stringify(user));
  },
  [types.SET_GROUP](state, groupId) {
    state.groupId = groupId;
    sessionStorage.setItem('groupId', groupId);
  },
  [types.CLEAN_USER](state) {
    state.user = null;
    state.groupId = null;

    sessionStorage.removeItem('user');
    sessionStorage.removeItem('groupId');
  },
  [types.SET_USER_GROUPS](state, groups) {
    this._vm.$set(state.user, 'groups', groups.groups);
    sessionStorage.setItem('user', JSON.stringify(state.user));
  },
  [types.SET_MAP_ENGINE](state, engine) {
    state.mapEngine = engine;
    sessionStorage.setItem('mapEngine', engine);
  },
  [types.SET_OL_SOURCE_EDITABLE](state, editable) {
    if (typeof (editable) === 'boolean') {
      state.olLayersEditable = editable;
    } else {
      console.warn('editable not boolean', editable);
    }
  },
  [types.SET_OL_TILE_SOURCE](state, sources) {
    if (sources instanceof Array) {
      state.olLayers = sources;
      localStorage.setItem('olLayers', JSON.stringify(sources));
    } else {
      console.warn('editable not boolean', sources);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
