export default {
  control: {
    battery: 'Battery',
    linkQuality: 'Link Quality',
    flightTime: 'Time',
    EstTime: 'Est',
    altitude: 'Altitude',
    speed: 'H.Speed',
    target: 'Target',
    fromHome: 'Home',
    timeFormat: 'MM.DD HH:mm',
    voltage: 'Voltage',
    GPS: 'GPS',
    HDOP: 'HDOP',
    life: 'Startup Time',
    vSpeed: 'V.Speed',
    to_hover: 'start to hover',
    resume: 'Resume the mission successfully',
    land: 'Start to land',
    take_off: 'Take off',
    rtlTitle: 'Return Home',
    Altitude: 'Altitude',
    Latitude: 'Latitude',
    longitude: 'Longitude',
    rtl: 'Start to Return Home',
    stop: 'Are you sure you want to temporarily stop the mission in progress?',
    guided: 'Start to achieve the waypoint',
    tilt: 'Tilt',
    heading: 'Heading',
    takeoff: 'Start to take off',
    wpt: 'New WPT',
    stream_reset: 'Restart streaming',
  },
  status: {
    manual: 'Manual',
    onmission: 'On Mission',
    standby: 'Standby',
    offline: 'Offline',
    charging: 'Charging',
    error: 'Error',
    takingoff: 'Taking Off',
    hovering: 'Hovering',
    clickngo: 'ClickAndGo',
    landing: 'Landing',
    rtl: 'Retuen',
    unknown: 'Unknown',
    closed: 'Closed',
    opened: 'Opened',
    replay: 'Replay',
    closing: 'Closing',
    opening: 'Opening',
  },
  playback: {
    dragHere: 'Drag Camera Here',
  },
  titleBar: {
    live: 'Live View',
    map: 'Map View',
    photos: 'Photos',
    videos: 'Videos',
    album: 'Album',
  },
  cameraList: {
    title: 'Cameras',
    drones: 'Drones',
    rgb: 'RGB',
    thermal: 'Thermal',
  },
  load_mission: 'Load Mission',
  select_mission: 'Select a mission',
  replaceMission: 'Are you sure you want to execute this and discard current plan?\n It may take a few seconds. please wait.',
  select_drone: 'Select a drone',
  Next: 'Next',
  clear: 'Clear',
  speed: 'H.S',
};
