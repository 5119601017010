<template>
  <vl-layer-vector id="Zones-FlyArea" :z-index="zIndex">
    <vl-source-vector :features="sourceGeoJSON"></vl-source-vector>
    <vl-style-box>
      <vl-style-stroke :color="strokeColor" :width="strokeWidth"></vl-style-stroke>
      <vl-style-fill :color="fillColor"></vl-style-fill>
    </vl-style-box>
  </vl-layer-vector>
</template>

<script>

export default {
  name: 'FlyArea',
  data() {
    return {
      strokeColor: [132, 204, 120, 0.8],
      strokeWidth: 3,
      fillColor: [179, 237, 170, 0.15],
    };
  },
  components: {
  },
  computed: {
    sourceGeoJSON() {
      return [{
        type: "Feature",
        id: `${this.id}-FlyArea`,
        properties: {
          type: 'polygon',
          source: 'fence',
        },
        geometry: {
          type: "Polygon",
          coordinates: this.zone.coordinates,
        }
      }];
    },
  },
  mounted() {
  },
  props: {
    zone: {
      required: true,
      type: Object,
    },
    zIndex: {
      default: 1,
    },
  },
};
</script>
