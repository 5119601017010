export default {
    date: '日期',
    user: '人員',
    allUser: '全部使用者',
    type: '類型',
    typeType: {
      all: '全部類型',
      login: '登入',
      users: '人員',
      groups: '場域',
      missions: '任務',
      noflyzones: '禁飛區',
      histories: '歷史紀錄',
      router_plan: '路徑搜尋器',
      trigger_events: '觸發事件',
      drones: '無人機',
      nests: '基站',
      cameras: '攝影機',
      weather_stations: '氣象站',
      triggers: '觸發',
      forwards: '轉發',
    },
    action: '動作',
    actionType: {
      GET: '查詢',
      POST: '新增/命令',
      PUT: '更新',
      DELETE: '刪除',
    },
    result: '結果',
    resultType: {
      success: '成功',
      fail: '失敗',
    },
  };