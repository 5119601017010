import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1/groups`;

export default {
  getAll(groupId, filter) {
    return axios
      .get(`${apiUrl}/${groupId}/trigger_events`, { params: filter })
      .then((response) => {
        return response.data;
      });
  },
  execute(groupId, eventId) {
    return axios.post(`${apiUrl}/${groupId}/trigger_events/${eventId}/execute`).then((response) => {
      return response.data;
    });
  },
  launchExecute(groupId, eventId) {
    return axios.post(`${apiUrl}/${groupId}/trigger_events/${eventId}/launch_execute`).then((response) => {
      return response.data;
    });
  },
};
