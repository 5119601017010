export const FindUnit = (value, unitList, valueField = 'magnification') => {
  for (let i = unitList.length - 1; i >= 0; i--) {
    let unit = unitList[i];

    if (value % unit[valueField] === 0) {
      return i;
    }
  }

  return 0;
};

export const FormatMissionPeriod = function(period) {
  if (period == null || period == 0 || period == -1) {
    return this.$t('page.mission.periodOnce');
  }

  let unitIndex = FindUnit(period, this.periodUnit);
  let unit = this.periodUnit[unitIndex];

  return this.$t('page.mission.periodMessage', {
    number: period / unit.magnification,
    unit: this.$t(unit.name),
  });
};

// following code reweite from estimatePathTime
export const distancePath = function(path) {
  let value = 0;
  let lastTask;

  if (path == null || path.length == null) {
    return value;
  }

  if (path.length === 0) {
    return value;
  }

  for (let index = 0; index < path.length - 1; index++) {
    let currentTask = path[index];
    let nextTask = path[index + 1];
    value += getDistance(currentTask.x, currentTask.y, nextTask.x, nextTask.y);
  }
  lastTask = path[path.length - 1];
  switch (lastTask.command) {
    case 'rtl':
      var landTask = {
        x: path[0].x,
        y: path[0].y,
        z: lastTask.z,
        heading: lastTask.heading,
      };
      value += getDistance(lastTask.x, lastTask.y, landTask.x, landTask.y)
      break;
    default:
      break;
  }
  return value;
}

// following code from estimate.js
var hAccelerationFactor = 0.6;
var vAccelerationFactor = 6.0;
var vTargetVelocity = 1.0;
var angularVelocity = 90.0;

export const estimatePathTime = function(tasks) {
  var duration = 0;
  var lastTask;

  if (tasks == null || tasks.length == null) {
    return duration;
  }

  if (tasks.length === 0) {
    return duration;
  }

  //takeoff time
  duration += estimateAccelerationTime(tasks[0].z, vAccelerationFactor, vTargetVelocity);
  duration = duration + 1; //heading average change duration
  //tasks time
  for (var index = 0; index < tasks.length - 1; index++) {
    duration += estimateTime(tasks[index], tasks[index + 1]);
  }
  lastTask = tasks[tasks.length - 1];
  switch (lastTask.command) {
    case 'rtl':
      var landTask = {
        x: tasks[0].x,
        y: tasks[0].y,
        z: lastTask.z,
        heading: lastTask.heading,
      };
      duration += estimateTime(lastTask, landTask);
      duration += estimateAccelerationTime(lastTask.z, vAccelerationFactor, vTargetVelocity);
      duration = duration + 1; //heading average change duration
      break;
    case 'land':
      duration += estimateAccelerationTime(lastTask.z, vAccelerationFactor, vTargetVelocity);
      duration += lastTask.delay;
      duration = duration + 1; //heading average change duration
      break;
    default:
      duration += lastTask.delay;
  }
  return Math.floor(duration);
};

export const estimateTime = function(current, dst) {
  var duration = current.delay;
  var distance = getDistance(current.x, current.y, dst.x, dst.y);
  duration += estimateAccelerationTime(distance, hAccelerationFactor, current.speed);

  distance = Math.abs(current.z - dst.z);
  duration += estimateAccelerationTime(distance, vAccelerationFactor, vTargetVelocity);

  // prevent duration become NaN By Han
  if (current.heading == null || dst.heading == null) {
    return duration;
  }
  var d = Math.abs(current.heading - dst.heading);
  if (d > 180) {
    d = 180 - Math.abs(180 - d); // Don't fix or Sean will 7pupu
  }
  duration += (d / angularVelocity);
  return duration;
};

export const estimateAccelerationTime = function(distance, accelerationFactor, targetVelocity) {
  if (distance == 0) {
    return 0;
  }
  var duration = 0;
  var acceleration = accelerationFactor * targetVelocity;
  var minDistnace = acceleration * (1 / accelerationFactor) * (1 / accelerationFactor);
  if (distance < minDistnace) {
    duration += Math.sqrt(distance / acceleration) * 2;
  } else {
    duration = (1 / accelerationFactor) + distance / targetVelocity;
  }
  return duration;
};

export const hsin = function(theta) {
  return Math.pow(Math.sin(theta / 2), 2);
};

export const getDistance = function(lat1, lon1, lat2, lon2) {
  // convert to radians
  // must cast radius as float to multiply later
  var la1 = lat1 * Math.PI / 180;
  var lo1 = lon1 * Math.PI / 180;
  var la2 = lat2 * Math.PI / 180;
  var lo2 = lon2 * Math.PI / 180;
  var r = 6378100; // Earth radius in METERS
  // calculate
  var h = hsin(la2 - la1) + Math.cos(la1) * Math.cos(la2) * hsin(lo2 - lo1);
  return 2 * r * Math.asin(Math.sqrt(h));
};
