import BaseVM from '@/models/';

export class CameraVM extends BaseVM {
  init() {
    return {
      id : null,
      name: null,
      admin: null,
      url: null,
      latitude: 0,
      longitude: 0,
      inactive : false,
      domain : null,
      group : null,
      account : null,
      password :null,
    };
  }
}
