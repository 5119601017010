import BaseVM from '@/models/';

export class WeatherVM extends BaseVM {
  init() {
    return {
      name: '',
      group: '',
      address: '',
      domain: '',
      created_time : new Date(),
      update_time : new Date(),
      latitude: 0,
      longitude: 0,
      relay : true,
      inactive:false,
    };
  }
}
