import Vue from 'vue';

import * as VueGoogleMaps from 'vue2-google-maps';
import VueLayers from 'vuelayers';
import ElementUI from 'element-ui';
import VueCircleSlider from 'vue-circle-slider';
import { sync } from 'vuex-router-sync';
import config from 'config';

// FontAwesome Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { appAxios } from './services/api/index';
import SystemAPI from '@/services/api/system';
import router from './router';
import store from './store';

import App from './App.vue';
import AdminMainLayout from '@/views/layout/AdminMainLayout.vue';
import AdminFillallLayout from '@/views/layout/AdminFillallLayout.vue';
import '@/utils/filter';
import '@/utils/loading';
import { mqtt } from './services/mqtt/index';

import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css';

// Main stylesheet
import './styles/site.scss';
import 'vuelayers/lib/style.css'; // needs css-loader

// I18n
import i18n from './locales';

// Response Handler
import { infoHandler, successHandler, failHandler } from '@/utils/response';

Vue.config.devtools = true;
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});

Vue.use(VueLayers, {
  // default is EPSG:3857
  dataProjection: config.openlayers.vlProjection,
});

Vue.use(VueGoogleMaps, {
  load: {
    // key: config.googleMapKey, // key assign at App.vue
    libraries: 'places,geometry', // This is required if you use the Autocomplete plugin

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  },
});

Vue.use(VueCircleSlider);

Vue.prototype.$appHttp = appAxios;
// Vue.prototype.$adminHttp = adminAxios;
// Global configuration
Vue.prototype.$config = config;
Vue.prototype.$showSuccess = successHandler;
Vue.prototype.$showInfo = infoHandler;
Vue.prototype.$showFail = failHandler;

Vue.component('FaIcon', FontAwesomeIcon);
library.add(fab);
library.add(far);
library.add(fas);

Vue.component('AdminMainLayout', AdminMainLayout);
Vue.component('AdminFillallLayout', AdminFillallLayout);

sync(store, router);

const app = new Vue({
  store,
  router,
  ...App,
  i18n,
});

app.$config.apiVersion = 'fetching...';

SystemAPI.version()
  .then((response) => {
    app.$config.apiVersion = response.data.version;
  })
  .catch(() => {
    app.$config.apiVersion = 'unable to fetch api version';
  });

try {
  const mqttInstance = new mqtt();
  app.$store.commit('mqtt/MQTT_INIT', mqttInstance);
  mqttInstance.mqttArriveMessage = (message) => {
    app.$store.commit('mqtt/MQTT_ARRIVED', message);
  };
  const intervalTask = setInterval(() => {
    app.$store.commit('mqtt/MQTT_TIMEOUT');
  }, app.$store.state.mqtt.timeoutCheckInterval);
} catch (e) {
  console.error('mqtt initialize failed');
}

export { app, router, store };
