export default {
  dialog: {
    name: 'Location Name',
    addTitle: 'Add a New Location',
    updateTitle: 'Edit Location',
    altitude: 'Min & Max Altitudes',
    rtl_altitude: 'RTL Altitude',
    alert_detect_user: 'Detect Alert',
    alert_on: 'ON',
    alert_off: 'OFF',
  },
  deleteConfirm: {
    title: 'Are you sure?',
    body: 'Once you delete the Location, you can\'t undo it!',
    delete: 'Delete Location',
  },
  map: 'Map',
  name: 'Name',
  description: 'Description',
  modified: 'Modified',
  altitude: 'Min & Max Alt',
  mapPage: {
    searchHere: 'Enter Search Here',
    latLngHere: 'Enter coordinates Here',
  },
  saveConfirm: 'Do you want to save this location?',
  noFence: 'Please add a Geo Fence on the location.',
  noELZ: 'Please add at least one ELZ on the location.',
  streamingServer: 'Stream Server',
  ip: 'Signaling Host',
  webrtc_port: 'WebRTC Port',
  real_ip: 'API Host',
  port: 'Streaming Port',
  api_port: 'API Port',
  account: 'Account',
  password: 'Password',
  title: 'Locations',
  elz: 'ELZ',
  nameDuplicated: 'Name Existed. Please Change the Name',
  nameEmpty: 'Please Input Name',
  rtlOutOfRange: 'RTL Should Between Min&Max Altitude',
};
