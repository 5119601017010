import * as types from '../mutations_type';
import { NVRVM } from '@/models/nvr';


const state = {
  mode: sessionStorage.getItem('nvrMode'),
  nvr: sessionStorage.getItem('nvr')
    ? JSON.parse(sessionStorage.getItem('nvr'))
    : new NVRVM(),

};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getNVR: (state) => state.nvr,
  // exportNest: (state) => {
  //   let apiData = CopyObject(state.nest);

  //   if (apiData.created_time.toISOString) {
  //     apiData.created_time = apiData.created_time.toISOString();
  //   }

  //   if (apiData.update_time != null && apiData.update_time.toISOString) {
  //     apiData.update_time = apiData.update_time.toISOString();
  //   }
  //   return apiData;
  // },
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_NVR);
    }
    commit(types.SET_NVR_MODE, mode);
  },
  setNVR({ commit }, nvr) {
    commit(types.SET_NVR, nvr);
  },
  importNVR({ commit }, nvr) {
    // if (mission.period === 0 || mission.period === -1) {
    //   mission.noRepeat = true;
    // } else {
    //   mission.noRepeat = false;
    //   let unit = FindUnit(mission.period, state.periodUnit);

    //   mission.periodUnit = state.periodUnit[unit].magnification;
    //   mission.period = mission.period / mission.periodUnit;
    // }
    commit(types.SET_NVR, nvr);
  },
};

const mutations = {
  [types.SET_NVR](state, nvr) {
    state.nvr = nvr;
    sessionStorage.setItem('nvr', JSON.stringify(nvr));
  },
  [types.SET_NVR_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('nvrMode', mode);
  },
  [types.CLEAR_NVR](state) {
    state.mode = null;
    state.nvr = new NVRVM();
    sessionStorage.removeItem('nvr');
    sessionStorage.removeItem('nvrMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
