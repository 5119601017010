import Vue from 'vue';
import store from '@/store';
import * as types from '@/store/mutations_type';

// 開啟 Loading
Vue.prototype.$startLoading = () => {
  store.commit(types.SET_LOADING, true);
};

// 結束 Loading
Vue.prototype.$endLoading = () => {
  store.commit(types.SET_LOADING, false);
};

export default Vue;
