<template>
  <el-dialog
    :title="title"
    :visible.sync="(showDialog)"
    :width="width"
    :before-close="event => close('click-outside')"
    :close-on-click-modal="false"
    :class="{
      'default-dialog': true,
      'title': title,
      'body-center': bodyCenter,
    }"
    :center="center"
    :show-close="showClose"
    @open="open"
  >
    <slot name="main" />

    <div
      slot="footer"
      :class="`dialog-footer ${footerAlign}`"
    >
      <slot name="footer" />
      <el-button
        v-if="hasCancel"
        class="main-actions"
        type="info"
        @click="event => close('click-cancel')"
      >
        {{ backButtonText }}
      </el-button>
      <el-button
        :disabled="!isAdmin && !disableEscaped"
        class="main-actions"
        type="primary"
        @click="next"
      >
        {{ nextButtonText }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DefaultDialog',

  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
  },

  props: {
    title: {
      required: false,
      type: String,
      default: null,
    },
    nextButtonText: {
      required: true,
      type: String,
    },
    backButtonText: {
      required: false,
      type: String,
      default() { return this.$t('button.cancel'); },
    },
    showDialog: {
      required: true,
      type: Boolean,
    },
    center: {
      required: false,
      type: Boolean,
      default: false,
    },
    showClose: {
      required: false,
      type: Boolean,
      default: true,
    },
    bodyCenter: {
      required: false,
      type: Boolean,
      default: false,
    },
    hasCancel: {
      required: false,
      type: Boolean,
      default: true,
    },
    width: {
      required: false,
      type: String,
      default: '651px',
    },
    footerAlign: {
      required: false,
      type: String,
      default: 'right',
    },
    disableEscaped: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close(source) {
      this.$emit('close', source);
    },
    next() {
      this.$emit('next');
    },
    open() {
      this.$emit('open');
    },
  },
};
</script>

<style lang="scss">
.el-dialog__wrapper.default-dialog{
  &.body-center {
    .el-dialog {
      .el-dialog__body {
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .el-dialog {
    top: 50%;
    margin-top: 0 !important;
    transform: translateY(-50%);

    .el-dialog__body {
      max-height: 75vh;
      overflow: auto;
      overflow-x: hidden;
      .el-form-item {
        margin-bottom: 10px;
        &.with_error_hint {
          margin-bottom: 24px;
        }
      }

      .el-form-item__label {
        font-size: 16px;
        text-align: left;
      }

      .icon{
        background-image: url('/asserts/icon/userprofile-icon.svg');
        height: 88px;
        background-repeat: no-repeat;
      }

      .iconDrone{
        background-image: url('/asserts/icon/devices-drone-icon.svg');
        height: 88px;
        background-repeat: no-repeat;
      }

      .iconOfflineDrone{
        background-image: url('/asserts/icon/devices-drone-icon.svg');
        height: 88px;
        background-repeat: no-repeat;
        opacity: 0.4;
      }

      .iconInactiveDrone{
        background-image: url(/asserts/icon/devices-drone-inactive-icon.svg);
        height: 88px;
        background-repeat: no-repeat;
      }

      .iconNest{
        background-image: url('/asserts/icon/devices-nest-icon.svg');
        height: 88px;
        background-repeat: no-repeat;
      }

      .iconCamera{
        background-image: url('/asserts/icon/devices-ipcam-icon.svg');
        height: 88px;
        background-repeat: no-repeat;
      }

      .iconWeather{
        background-image: url('/asserts/icon/weather-station-icon.svg');
        height: 88px;
        background-repeat: no-repeat;
      }

      .iconNVR{
        background-image: url('/asserts/icon/devices-nvr-icon.svg');
        height: 88px;
        background-repeat: no-repeat;
      }

      .whiteBody{
        background-color: white;
        width: 113%;
        margin-left: -38px;
        margin-bottom: 0px;
        .streaming{
          width: 260px;
          margin-left: 40px;
          position: absolute;
          left: 67px;
        }
      }
    }

    .el-dialog__footer {
      .dialog-footer.right {
        text-align: right;
      }

      .dialog-footer.center {
        text-align: center;
      }

      .dialog-footer.left {
        text-align: left;
      }
    }
  }
  .right-button {
    float: left;
  }
}
</style>
