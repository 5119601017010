import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';
import config from 'config';

import auth from './modules/auth';
import menu from './modules/menu';
import user from './modules/user';
import mission from './modules/mission';
import group from './modules/group';
import drone from './modules/drone';
import nest from './modules/nest';
import monitorState from './modules/monitorState';
import account from './modules/account';
import camera from './modules/camera';
import weather from './modules/weather';
import trigger from './modules/trigger';
import forward from './modules/forward';
import nvr from './modules/nvr';
import noFlyZone from './modules/noFlyZone';
import map from './modules/map';
import mqtt from './modules/mqtt';
import olSource from './modules/olSource';
Vue.use(Vuex);

export default new Vuex.Store({
  strict: config.environment !== 'production',
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    menu,
    user,
    mission,
    group,
    drone,
    nest,
    monitorState,
    account,
    camera,
    weather,
    trigger,
    forward,
    nvr,
    noFlyZone,
    map,
    mqtt,
    olSource,
  },
});
