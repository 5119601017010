export const SET_LANG = 'SET_LANG';
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const SET_LOADING = 'SET_LOADING';
export const SET_MEASUREMENT = 'SET_MEASUREMENT';
export const SET_CUSTOMER = 'SET_CUSTOMER';

export const SET_USER = 'SET_USER';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SET_MENU = 'SET_MENU';
export const CLEAN_USER = 'CLEAN_USER';
export const SET_MAP_ENGINE = 'SET_MAP_ENGINE';
export const SET_OL_SOURCE_EDITABLE = 'SET_OL_SOURCE_EDITABLE';
export const SET_OL_TILE_SOURCE = 'SET_OL_TILE_SOURCE';

export const SET_PROCESSING_DATA = 'SET_PROCESSING_DATA';
export const SET_GROUP = 'SET_GROUP';

export const SET_MISSION_MODE = 'SET_MISSION_MODE';
export const CLEAR_MISSION = 'CLEAR_MISSION';
export const SET_MISSION = 'SET_MISSION';

export const SET_GROUP_EDIT_MODE = 'SET_GROUP_EDIT_MODE';
export const CLEAR_GROUP_EDIT = 'CLEAR_GROUP_EDIT';
export const SET_GROUP_EDIT = 'SET_GROUP_EDIT';

export const SET_OL_SOURCE_EDIT_MODE = 'SET_GROUP_EDIT_MODE';
export const CLEAR_OL_SOURCE_EDIT = 'CLEAR_GROUP_EDIT';
export const SET_OL_SOURCE_EDIT = 'SET_GROUP_EDIT';

export const SET_DRONE_MODE = 'SET_DRONE_MODE';
export const CLEAR_DRONE = 'CLEAR_DRONE';
export const SET_DRONE = 'SET_DRONE';

export const SET_NEST_MODE = 'SET_NEST_MODE';
export const CLEAR_NEST = 'CLEAR_NEST';
export const SET_NEST = 'SET_NEST';

export const SET_TAKEOFF_LOCATION = 'SET_TAKEOFF_LOCATION';
export const ADD_FLY_HISTORY = 'ADD_FLY_HISTORY';
export const CLEAN_FLY_HISTORY = 'CLEAN_FLY_HISTORY';
export const CLEAN_MONITOR_STATE = 'CLEAN_MONITOR_STATE';

export const SET_ACCOUNT_MODE = 'SET_ACCOUNT_MODE';
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';
export const SET_ACCOUNT = 'SET_ACCOUNT';

export const SET_CAMERA_MODE = 'SET_CAMERA_MODE';
export const CLEAR_CAMERA = 'CLEAR_CAMERA';
export const SET_CAMERA = 'SET_CAMERA';

export const SET_WEATHER_MODE = 'SET_WEATHER_MODE';
export const CLEAR_WEATHER = 'CLEAR_WEATHER';
export const SET_WEATHER = 'SET_WEATHER';

export const SET_TRIGGER_MODE = 'SET_TRIGGER_MODE';
export const CLEAR_TRIGGER = 'CLEAR_TRIGGER';
export const SET_TRIGGER = 'SET_TRIGGER';

export const SET_FORWARD_MODE = 'SET_FORWARD_MODE';
export const CLEAR_FORWARD = 'CLEAR_FORWARD';
export const SET_FORWARD = 'SET_FORWARD';

export const SET_NVR_MODE = 'SET_NVR_MODE';
export const CLEAR_NVR = 'CLEAR_NVR';
export const SET_NVR = 'SET_NVR';

export const SET_NO_FLY_ZONE_DATA = 'SET_NO_FLY_ZONE_DATA';
export const SAVE_NO_FLY_ZONE_DATA = 'SAVE_NO_FLY_ZONE_DATA';

export const SET_MAP_TYPE = 'SET_MAP_TYPE';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_MAP_CENTER_READER = 'SET_MAP_CENTER_READER';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';

export const MONITOR_SET_TAB = 'MONITOR_SET_TAB';
export const MONITOR_SET_MAP = 'MONITOR_SET_MAP';

export const MQTT_INIT = 'MQTT_INIT';
export const MQTT_CONNECT = 'MQTT_CONNECT';
export const MQTT_DISCONNECT = 'MQTT_DISCONNECT';
export const MQTT_SET_GROUP = 'MQTT_SET_GROUP';
export const MQTT_UNSET_GROUP = 'MQTT_UNSET_GROUP';
export const MQTT_ADD_DRONE = 'MQTT_ADD_DRONE';
export const MQTT_REMOVE_DRONE = 'MQTT_REMOVE_DRONE';
export const MQTT_CLEAR_DRONE_STATUS = 'MQTT_CLEAR_DRONE_STATUS';
export const MQTT_CLEAR_DRONE_HISTORY = 'MQTT_CLEAR_DRONE_HISTORY';
export const MQTT_CLEAR_NEST_STATUS = 'MQTT_CLEAR_NEST_STATUS';
export const MQTT_CLEAR_WEATHER_STATUS = 'MQTT_CLEAR_WEATHER_STATUS';
export const MQTT_ADD_NEST = 'MQTT_ADD_NEST';
export const MQTT_REMOVE_NEST = 'MQTT_REMOVE_NEST';
export const MQTT_ADD_WEATHER = 'MQTT_ADD_WEATHER';
export const MQTT_REMOVE_WEATHER = 'MQTT_REMOVE_WEATHER';
export const MQTT_ADD_DEVICE_THROTTLE = 'MQTT_ADD_DEVICE_THROTTLE';
export const MQTT_REMOVE_DEVICE_THROTTLE = 'MQTT_REMOVE_DEVICE_THROTTLE';
export const MQTT_ADD_MONITOR_DRONE = 'MQTT_ADD_MONITOR_DRONE';
export const MQTT_REMOVE_MONITOR_DRONE = 'MQTT_REMOVE_MONITOR_DRONE';
export const MQTT_ARRIVED = 'MQTT_ARRIVED';
export const MQTT_DRONE_THROTTLE = 'MQTT_DRONE_THROTTLE';
export const MQTT_NEST_THROTTLE = 'MQTT_NEST_THROTTLE';
export const MQTT_ARRIVED_DRONE = 'MQTT_ARRIVED_DRONE';
export const MQTT_ARRIVED_DRONE_TAKEOFF = 'MQTT_ARRIVED_DRONE_TAKEOFF';
export const MQTT_ARRIVED_DRONE_MISSION_HISTORY = 'MQTT_ARRIVED_DRONE_MISSION_HISTORY';
export const MQTT_ARRIVED_NEST = 'MQTT_ARRIVED_NEST';
export const MQTT_ARRIVED_WEATHER = 'MQTT_ARRIVED_WEATHER';
export const MQTT_TIMEOUT = 'MQTT_TIMEOUT';
export const MQTT_GIMBAL1_TILT = 'MQTT_GIMBAL1_TILT';
export const MQTT_GIMBAL2_TILT = 'MQTT_GIMBAL2_TILT';
