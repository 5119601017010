import monitor from './monitor/en_US';
import mission from './mission/en_US';
import setting from './setting/en_US';
import device from './device/en_US';
import event from './event/en_US';
import message from './message/en_US';
import history from './history/en_US';
export default {
  mission,
  setting,
  monitor,
  device,
  event,
  message,
  history,
};