import customerSetting from '@/config/customerSetting';

const customer = customerSetting[process.env.VUE_APP_customer] ? customerSetting[process.env.VUE_APP_customer] : customerSetting.default;

export default {
  siteName: `${customer.header}`,
  dateFormat: 'yyyy-MM-dd',
  dateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
  momentDateFormat: 'YYYY-MM-DD',
  momentDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  momentDateTimeFormatNoSecond: 'YYYY-MM-DD HH:mm',
  currency: 'NT$',
  secretKey: process.env.VUE_APP_SECRET_KEY,
  googleMapKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
  apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
  mqttHost: process.env.VUE_APP_MQTT_HOST,
  mqttPort: parseInt(process.env.VUE_APP_MQTT_PORT),
  mqttUsername: process.env.VUE_APP_MQTT_USERNAME,
  mqttPsd: process.env.VUE_APP_MQTT_PSD,
  mqttReconnectTimeout: parseInt(process.env.VUE_APP_MQTT_RECONNECT_TIMEOUT),
  customerSetting: customer,
  version: {
    describe: __VERSION_DESCRIBE__,
    hash: __VERSION_HASH__,
  },
  fallbackLatitude: 24.769818546445983,
  fallbackLongitude: 121.02903047555532,
  carMax: 100,
  peopleMax: 50,
  kmlEndpoint: process.env.VUE_APP_KML_ENDPOINT,
  openlayers: {
    olProjection: 'EPSG:3857',
    vlProjection: 'EPSG:4326', // same as google maps using
  },
  disableDroneControlList: [
    {
      domain: 'secom.com',
      account: 'fmsviewer@secom.com',
    },
  ],
};
