import BaseVM from '@/models/';

export class ForwardVM extends BaseVM {
  init() {
    return {
      id : null,
      name :null,
      mode:'restful',
      monitor:1,
      enabled:true,
      url:null,
      account:null,
      password:null,            
    };
  }
}
