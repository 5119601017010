import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1/user`;

export default {
  getProfile(config) {
    return axios.get(`/v1/user`, config)
      .then((response) => {
        return response.data;
      });
  },
  getSetting() {
    return axios
      .get(`${apiUrl}/setting`)
      .then((response) => {
        return response.data;
      });
  },
  updateLiveSetting(data) {
    return axios.put(`${apiUrl}/setting/live_view`, data).then((response) => {
      return response.data;
    });
  },
  deleteLiveSetting(data) {
    return axios.delete(`${apiUrl}/setting/live_view`,  {
      data: data
     }).then((response) => {
      return response.data;
    });
    
  },
};
