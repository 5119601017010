import * as types from './mutations_type';

export default {
  [types.SET_LANG](state, lang) {
    state.appLang = lang;
    sessionStorage.setItem('appLang', JSON.stringify(lang));
  },

  [types.SET_MEASUREMENT](state, measurement) {
    state.measurement = measurement;
    sessionStorage.setItem('measurement', JSON.stringify(measurement));
  },

  [types.SET_CUSTOMER](state, customer) {
    state.customer = customer;
    sessionStorage.setItem('customer', JSON.stringify(customer));
  },

  [types.COLLAPSE_SIDEBAR](state) {
    state.sidebarCollapse = !state.sidebarCollapse;
  },

  [types.SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [types.SET_PROCESSING_DATA](state, id) {
    state.processingData = id;
  },
};
