<template>
  <div :class="{ 'app-fill': true, 'fullscreen': fullscreen}">
    <div class="app-content">
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AdminFillallLayout',
    props: {
      fullscreen: {
        type: Boolean,
        default: false,
      }
    },
  };
</script>

<style lang="scss">
@import '~@/styles/variables.scss';
.app-fill {
    height: calc(100vh - #{$header_height});
    position: relative;
}

.app-fill.fullscreen {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 10000;
}
.app-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
}
</style>
