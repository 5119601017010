import enums from './enum/en_US';
import page from './page/en_US';
import response from './response/en_US';
import route from './route/en_US';
import map from './map/en_US';

export default {
  enums,
  page,
  response,
  route,
  map,
  button: {
    confirm: 'Confirm',
    add: 'Add New',
    cancel: 'Cancel',
    delete: 'Delete',
    create: 'Create',
    save: 'Save',
    next: 'Next',
    done: 'Done',
    copy: 'Copy',
    apply: 'Apply',
    ok: 'OK',
    rtlGo: 'Go',
    yes: 'Yes',
    select: 'Select Files',
    search: 'Search',
    no: 'No',
    import: 'Import',
    export: 'Export',
    url: 'URL',
    on: 'On',
    off: 'Off',
    edit: 'Edit',
    welcome: 'Welcome',
    back: 'Back',
  },
  validation: {
    required: 'Required',
    mustSelect: 'Required',
    length: 'Length must be {length} characters',
    number: 'Only numbers are allowed',
    exist: '{name} must be unique.',
    notExist: '{name} is not exist.',
    droneName: 'Are you sure you want to power off {droneName} now?',
  },
  placeholder: {
    select: 'Please select',
  },
  errorMsg: {
    waypointInvalid: 'Waypoint Invalid',
    nestNotBind: 'Please select a Nest',
    browserUnsupport: 'Browser not support this function',
    requireSecureConnection: 'Require Secure Connection use this function',
    requireGeolocationPermission: 'Require provide access to get your geolocation',
    getLocationFailed: 'Failed to get your geolocation',
  },
  header: {
    fullscreen: 'Full Screen',
    message: 'Message',
    notification: 'Notification',
    profile: 'User Profile',
    settings: 'Settings',
    signOut: 'Sign Out',
    selectLocatoin: 'Please select a Location',
    setPass: 'Change Password',
    oldPassword: 'Old Password',
    Account: 'Account',
    Location: 'Locations',
    Imperial: 'Imperial',
    Metric: 'Metric',
  },
  login: {
    email: 'Email',
    password: 'Password',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    register: 'Register',
    or: 'or',
    rememberMe: 'Remember me',
    welcome: 'Welcome',
    right_reserved: 'All rights reserved.',
  },
  requiredHint: ' is required',
  processing: 'Processing',
  messageBox: {
    success: 'Success',
    info: 'Info',
    warning: 'Warning',
    error: 'Error',
  },
  formatter: {
    dateTime: 'YYYY.MM.DD HH:mm',
  },
  dashboard: {
    weatherStation: 'Weather Station',
    wind: 'Wind',
    from: 'from',
    N: 'N',
    NNE: 'NNE',
    NE: 'NE',
    NEE: 'NEE',
    E: 'E',
    SEE: 'SEE',
    SE: 'SE',
    SSE: 'SSE',
    S: 'S',
    SSW: 'SSW',
    SW: 'SW',
    SWW: 'SWW',
    W: 'W',
    NWW: 'NWW',
    NW: 'NW',
    NNW: 'NNW',
    empty: '',
    humidity: 'Humidity',
    Precipitation: 'Precipitation',
    Pressure: 'Atm. Pressure',
    flight_summary: 'Flight Summary',
    temperature: 'Temperature',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    total_flight: 'Total Flights',
    total_flight_time: 'Total Flight time',
    Mins: 'Mins',
    drone_nest_status: 'Drone / Nest Status',
    Capacity: 'Capacity',
    Sensors: 'Sensors',
    Status: 'Status',
    GPS: 'GPS',
    Compass: 'Compass',
    Gyro: 'Gyro',
    Accelerometer: 'Accelerometer',
    Barometer: 'Barometer',
    Gimbal: 'Gimbal',
    IR_camera: 'IR camera',
    Rangefinder: 'Rangefinder',
    Battery: 'Battery',
    env_temp: 'Env.temp',
    nest_temp: 'Nest.temp',
    no_connection: 'No Connection',
    no_devices: 'No devices found Here',
    no_data: 'No data',
  },
  notify: {
    title: 'Notifications',
    new: 'New',
    clear: 'Clear all',
    target: 'Target',
    detected: '{drone} detected an anomalous intrusion',
    alertWindow: {
      Alert: 'Alert',
      Event: 'Event',
    },
    gettingGeolocation: 'Getting location. Please allow access.',
  },
};
