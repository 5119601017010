class BaseViewModel {
  constructor(attr) {
    Object.assign(this, this.init());
    if (attr !== undefined) {
      Object.entries(attr)
        .forEach((e) => {
          if (this.hasOwnProperty(e[0])) {
            this[e[0]] = e[1];
          }
        });
    }
  }

  init(attributes) {
    return attributes;
  }
}

export default BaseViewModel;
