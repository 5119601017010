import * as types from '../mutations_type';
import { NestVM } from '@/models/nest';
import { CopyObject } from '@/utils/common.js';

const state = {
  mode: sessionStorage.getItem('nestMode'),
  nest: sessionStorage.getItem('nest')
    ? JSON.parse(sessionStorage.getItem('nest'))
    : new NestVM(),

};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getNest: (state) => state.nest,
  // exportNest: (state) => {
  //   let apiData = CopyObject(state.nest);

  //   if (apiData.created_time.toISOString) {
  //     apiData.created_time = apiData.created_time.toISOString();
  //   }

  //   if (apiData.update_time != null && apiData.update_time.toISOString) {
  //     apiData.update_time = apiData.update_time.toISOString();
  //   }
  //   return apiData;
  // },
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_NEST);
    }
    commit(types.SET_NEST_MODE, mode);
  },
  setNest({ commit }, nest) {
    commit(types.SET_NEST, nest);
  },
  importNest({ commit }, nest) {
   
    nest.temperature = [ nest.min_temperature, nest.max_temperature ];
    delete nest.min_altitude;
    delete nest.max_altitude;
    commit(types.SET_NEST, nest);
    commit(types.SET_NEST_MODE, 'update');
  },
  exportNest() {
    let apiData = CopyObject(state.nest);        
    apiData.min_temperature = apiData.temperature[0];
    apiData.max_temperature = apiData.temperature[1];
    delete apiData.temperature;

    return apiData;
  },
};




const mutations = {
  [types.SET_NEST](state, nest) {
    state.nest = nest;
    sessionStorage.setItem('nest', JSON.stringify(nest));
  },
  [types.SET_NEST_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('nestMode', mode);
  },
  [types.CLEAR_NEST](state) {
    state.mode = null;
    state.nest = new NestVM();
    sessionStorage.removeItem('nest');
    sessionStorage.removeItem('nestMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
