import { appAxios as axios } from '@/services/api/';

const apiUrl = '/v1/groups';

export default {
  getPayloadInfo(groupId, id, model_id) {
    return axios
      .get(`${apiUrl}/${groupId}/drones/${id}/payload_info/${model_id}`)
      .then((response) => response.data);
  },

  getPayloadInfowithPID(groupId, id, payload_info_id) {
    return axios
      .get(`${apiUrl}/${groupId}/drones/${id}/payload_info_locate/${payload_info_id}`)
      .then((response) => response.data);
  },

  getAll(groupId) {
    return axios
      .get(`${apiUrl}/${groupId}/drones`)
      .then((response) => response.data);
  },
  get(groupId, id) {
    return axios.get(`${apiUrl}/${groupId}/drones/${id}`).then((response) => response.data);
  },
  execute(groupId, droneId, command = 'auto', payload = {}) {
    const data = {
      command,
      ...payload,
    };
    return axios.post(`${apiUrl}/${groupId}/drones/${droneId}/execute`, data).then((response) => response.data);
  },
  assign(groupId, droneId, missionId) {
    const data = {
      mission_id: missionId,
    };
    return axios.post(`${apiUrl}/${groupId}/drones/${droneId}/assign_mission`, data).then((response) => response.data);
  },
  create(group, data) {
    return axios.post(`${apiUrl}/${group}/drones`, data).then((response) => response.data);
  },
  update(group, id, data) {
    return axios.put(`${apiUrl}/${group}/drones/${id}`, data).then((response) => response.data);
  },
  delete(group, id) {
    return axios.delete(`${apiUrl}/${group}/drones/${id}`).then((response) => response.data);
  },
  simulate(group, id, data) {
    return axios.post(`${apiUrl}/${group}/drones/${id}/simulate`, data).then((response) => response.data);
  },

  history(group, id, filter) {
    return axios
      .get(`${apiUrl}/${group}/drones/${id}/histories`, { params: filter })
      .then((response) => response.data);
  },

  historyInfo(group, id, filter, historyId) {
    return axios
      .get(`${apiUrl}/${group}/drones/${id}/histories/${historyId}`, { params: filter })
      .then((response) => response.data);
  },
  incomplete(group, id) {
    return axios.post(`${apiUrl}/${group}/drones/${id}/incomplete_mission`).then((response) => response.data);
  },
};
