import * as types from '../mutations_type';
import { DroneVM } from '@/models/drone';

const state = {
  mode: sessionStorage.getItem('droneMode'),
  drone: sessionStorage.getItem('drone')
    ? JSON.parse(sessionStorage.getItem('drone'))
    : new DroneVM(),

};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getDrone: (state) => state.drone,
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_DRONE);
    }
    commit(types.SET_DRONE_MODE, mode);
  },
  setDrone({ commit }, drone) {
    commit(types.SET_DRONE, drone);
  },
};

const mutations = {
  [types.SET_DRONE](state, drone) {
    state.drone = drone;
    sessionStorage.setItem('drone', JSON.stringify(drone));
  },
  [types.SET_DRONE_MODE](state, mode) {
    if (mode == null) {
      return;
    }
    state.mode = mode;
    sessionStorage.setItem('droneMode', mode);
  },
  [types.CLEAR_DRONE](state) {
    state.mode = null;
    state.drone = new DroneVM();
    sessionStorage.removeItem('drone');
    sessionStorage.removeItem('droneMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
