export default {
  dialog: {
    name: '場域名稱',
    addTitle: '新增一個場域',
    updateTitle: '編輯場域',
    altitude: '最大&最小高度',
    rtl_altitude: '自動返航高度',
    alert_detect_user: '檢測警報',
    alert_on: '開啟',
    alert_off: '關閉',
  },
  deleteConfirm: {
    title: '你確定嗎？',
    body: '一旦刪除場域，將無法回復',
    delete: '刪除場域',
  },
  map: '地圖',
  name: '名稱',
  description: '描述',
  modified: '修改',
  altitude: '最大&最小高度',
  mapPage: {
    searchHere: '輸入名稱搜尋',
    latLngHere: '請輸入座標',
  },
  saveConfirm: '確定要儲存這個場域嗎？',
  noFence: '請在此場域新增一個電子圍籬',
  noELZ: '請在此場域新增至少一個緊急降落點',
  streamingServer: '串流伺服器',
  ip: 'WebRTC 主機',
  webrtc_port: 'WebRTC Port',
  real_ip: 'API 主機',
  port: '串流通訊埠',
  api_port: 'API 通訊埠',
  account: '帳號',
  password: '密碼',
  title: '場域',
  elz: '緊急降落點',
  nameDuplicated: '已建過相同名稱，請更換名稱',
  nameEmpty: '請輸入名稱',
  rtlOutOfRange: '返航高度應落在最小&最大高度之間',
};
