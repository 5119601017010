export default {
  noMission: {
    title: '還沒有任何任務',
    content: '請點擊“新增”按鈕來新增一筆新的任務',
  },
  dialog: {
    addTitle: '新增一個新的任務',
    updateTitle: '編輯任務',
    name: '任務名稱',
    type: '任務類型',
    types: {
      lineString: 'Line String Mission',
      lineCounting: 'AI Counting Mission',
      aerialSingle: 'Aerial Survey-Single',
      aerialGrid: 'Aerial Survey-Grid',
    },
    startTime: '起始時間',
    endTime: '結束時間',
    repeat: '重複性',
    noRepeat: '不重複',
    period: '每',
    addWaypoint: '新增航點',
    editWaypoint: '修改航點',
  },
  deleteConfirm: {
    title: '你確定嗎？',
    body: '一旦刪除任務，將無法回復',
  },
  title: '任務',
  map: '地圖',
  name: '名稱',
  tasks: 'Tasks',
  location: '場域',
  endTime: '結束時間',
  period: '排程',
  periodUnit: {
    minutes: 'Minutes',
    hours: '小時',
    days: '天',
    weeks: '週',
    months: '月',
  },
  periodOnce: '一次',
  periodMessage: 'Every {number} {unit}',
  load: 'Load',
  survey: {
    createRoute: '建立路徑',
  },
  saveConfirm: '確定要儲存這個任務嗎？',
  correct: '目前規劃的路徑將會經過禁飛區，確定要自動修正路徑嗎？',
  correctAltitude: '規劃的路徑穿越限高區. 我們建議修正路徑高度.',
  correctBoth: '規劃的路徑需要多處修正. 我們建議最佳化路徑修正',
  selectelz: '選擇 ELZ點',
  delete: '刪除任務',
  export: '匯出任務',
  import: '匯入任務',
  filename: '檔案名稱',
  dragndrop: '拖拉檔案至此',
  filepath: '選擇檔案',
  fileexisted: '已經存在，確認要覆蓋嗎？',
  or: '或',
  noNest: '沒有基站',
  toAddNest: '請去設備頁面新增一個基站',
  manualHint: '頭向設定改為手動設定嗎?',
  alert: {
    nameExist: '任務名稱重複',
    nameEmpty: '任務名稱不可為空',
  },
};
