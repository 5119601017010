export default {
  date: 'Date',
  user: 'User',
  allUser: 'All User',
  type: 'Type',
  typeType: {
    all: 'All Type',
    login: 'Login',
    users: 'User',
    groups: 'Location',
    missions: 'Mission',
    noflyzones: 'No Fly Zone',
    histories: 'History',
    router_plan: 'Router Find',
    trigger_events: 'Trigger Event',
    drones: 'Drone',
    nests: 'Nest',
    cameras: 'Camera',
    weather_stations: 'Weather Station',
    triggers: 'Trigger',
    forwards: 'Forward',
  },
  action: 'Action',
  actionType: {
    GET: 'Fetch',
    POST: 'Add/Command',
    PUT: 'Update',
    DELETE: 'Remove',
  },
  result: 'Result',
  resultType: {
    success: 'Success',
    fail: 'Fail',
  },
};