<template>
  <div class="player-container">
    <iframe :src="`/players/jquery-example/dev-view-play.html?url=${wssUrl}&app=${app}&stream=${stream}`"
            class="iframe-player"
    />
    <div :style="(dragging) ? '' : 'display: none;'"
         class="drop-area"
    >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FlashRtmpPlayer',
    props: {
      url: {
        required: true,
        type: Object,
      },
      dragging: {
        required: false,
        default: false,
      },
    },
    computed: {
      wssUrl() {
        return this.url.web_RTC.wssUrl;
      },
      app() {
        return this.url.web_RTC.app;
      },
      stream() {
        return this.url.web_RTC.stream;
      },
    },
    methods: {
      allowDrop(event) {
        event.preventDefault();
      },
      drop(index) {
        this.$emit('drop', { index });
      },
    },
  };
</script>

<style lang="scss">
.player-container {
  width: 100%;
  height: 100%;
}
.drop-area {
  // background-color: rgba(0, 0, 0, 0.3);
  // color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.iframe-player {
  border: 0;
  width: 100%;
  height: 100%;
}
</style>