import locationTab from './locationTab/en_US';
import userTab from './userTab/en_US';
import accountingTab from './accountingTab/en_US';
import olSourceTab from './olSourceTab/en_US';
export default {
  tab: {
    domain: 'Domain',
    user: 'User',
    location: 'Locations',
    noFlyZone: 'No Fly Zones',
    accounting: 'Accounting',
    about: 'About',
    olSource: 'Map Source',
  },
  locationTab,
  userTab,
  accountingTab,
  olSourceTab,
  // move to userTab ?
  deleteConfirm: {
    title: 'Are you sure?',
    body: 'Once you delete the Drone, you can\'t undo it!',
    userBody: 'Once you delete the User, you can\'t undo it!',
  },
  delete: {
    user: 'Delete User',
  },
  // should move to drone related file
  droneId : 'Drone ID',
  droneName : 'Drone Name',
  domain : 'Domain',
  group : 'Group',
  MACAddress : 'MAC Address',
  loading: 'Loading...',
};
