<template>
  <el-row class="app-footer"
          type="flex"
          justify="start"
          align="middle"
  >
    <span>Copyright © 2018 TrunkStudio All rights reserved.</span>
  </el-row>
</template>

<script>
  export default {
    name: 'TheFooter',
  };
</script>

<style scoped>
  .app-footer {
    height: inherit;
  }
</style>
