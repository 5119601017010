import axios from 'axios';
import store from '@/store';
import _config from 'config';
import router from '@/router';

// 前台 Axios Instance
export const appAxios = axios.create({
  baseURL: _config.apiEndpoint,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 攔截 前台 Request
appAxios.interceptors.request.use(
  (config) => {
    if (_config.environment === 'development') {
      console.log('appAxios intercepted request');
    }
    // token 過期時間
    const expirationTime = store.state.auth.expirationTime;
    // token 是否過期
    const tokenExpires =
      expirationTime !== null && new Date().getTime() > expirationTime;
    // 如果 request 是 refreshToken 不做其他動作
    if (config.url.includes('refreshToken')) {
      // 繼續 request
      return Promise.resolve(config);
    }
    // 檢查 token 是否過期
    if (tokenExpires) {
      // 更新 token
      return store
        .dispatch('auth/refreshToken', store.state.auth.refreshToken)
        .then(({ token }) => {
          // 更新 token 後，帶入 request header。
          config.headers.common['Authorization'] = 'Bearer ' + token;
          // 繼續 request
          return Promise.resolve(config);
        })
        .catch((error) => {
          // 發生問題則清空 state
          store.dispatch('auth/clearAuth');
          // 導向「後台登入頁」
          router.push({
            name: 'adminLogin',
            query: {
              returnUrl: router.currentRoute.fullPath,
            },
          });
        });
    } else {
      // token 未過期
      const token = store.state.auth.token;
      if (token) {
        // 帶入 request header
        config.headers.common['Authorization'] = 'Bearer ' + token;
      }
      // 繼續 request
      return Promise.resolve(config);
    }
  },
  (error) => {
    return Promise.reject(error.response);
  },
);

// 攔截 前台 Response
appAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 清除 auth
          store.dispatch('auth/clearAuth');
          // 導向登入頁
          router.push({
            name: 'adminLogin',
            query: {
              returnUrl: router.currentRoute.fullPath,
            },
          });
      }
    }
    return Promise.reject(error.response);
  },
);
