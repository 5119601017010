import { CopyObject } from '@/utils/common.js';
import { GeographicShorter, GeographicLonger } from '@/utils/map.js';

import * as types from '../mutations_type';
import { GroupVM } from '@/models/group';

const state = {
  mode: sessionStorage.getItem('groupEditMode'),
  group: sessionStorage.getItem('groupEditData')
    ? JSON.parse(sessionStorage.getItem('groupEditData'))
    : new GroupVM(),
};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getData: (state) => state.group,
};

const actions = {
  setEditMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_GROUP_EDIT);
    }
    commit(types.SET_GROUP_EDIT_MODE, mode);
  },
  setData({ commit }, data) {
    commit(types.SET_GROUP_EDIT, data);
  },
  importData({ commit }, data) {
    data.altitude = [data.min_altitude, data.max_altitude];
    delete data.min_altitude;
    delete data.max_altitude;

    commit(types.SET_GROUP_EDIT, data);
    commit(types.SET_GROUP_EDIT_MODE, 'edit');
  },
  exportData() {
    const apiData = CopyObject(state.group);
    // apiData.latitude = apiData.lat;
    // apiData.longitude = apiData.lng;
    // delete apiData.lat;
    // delete apiData.lng;
    // apiData.fence = apiData.fence.map(GeographicLonger);
    apiData.min_altitude = apiData.altitude[0];
    apiData.max_altitude = apiData.altitude[1];
    delete apiData.altitude;
    // apiData.elz=apiData.elz.map(GeographicLonger);
    return apiData;
  },
};

const mutations = {
  [types.SET_GROUP_EDIT](state, data) {
    state.group = data;
    sessionStorage.setItem('groupEditData', JSON.stringify(data));
  },
  [types.SET_GROUP_EDIT_MODE](state, mode) {
    if (mode == null) {
      return;
    }
    state.mode = mode;
    sessionStorage.setItem('groupEditMode', mode);
  },
  [types.CLEAR_GROUP_EDIT](state) {
    state.mode = null;
    state.group = new GroupVM();
    sessionStorage.removeItem('groupEditData');
    sessionStorage.removeItem('groupEditMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
