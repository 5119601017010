export default {
  noMission: {
    title: 'No mission yet',
    content: 'Please click the “Add New” button to add a new mission.',
  },
  dialog: {
    addTitle: 'Add a New Mission',
    updateTitle: 'Edit Mission',
    name: 'Mission Name',
    type: 'Mission Type',
    types: {
      lineString: 'Line String Mission',
      lineCounting: 'AI Counting Mission',
      aerialSingle: 'Aerial Survey-Single',
      aerialGrid: 'Aerial Survey-Grid',
    },
    startTime: 'Start Time',
    endTime: 'End Time',
    repeat: 'Repeat',
    noRepeat: 'No Repeat',
    period: 'Every',
    addWaypoint: 'Add waypoint',
    editWaypoint: 'Edit waypoint',
  },
  deleteConfirm: {
    title: 'Are you sure?',
    body: 'Once you delete the Mission, you can\'t undo it!',
  },
  title: 'Missions',
  map: 'Map',
  name: 'Name',
  tasks: 'Tasks',
  location: 'Location',
  endTime: 'End Time',
  period: 'Scheduled at',
  periodUnit: {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
  },
  periodOnce: 'Once',
  periodMessage: 'Every {number} {unit}',
  load: 'Load',
  survey: {
    createRoute: 'Create Route',
  },
  saveConfirm: 'Do you want to save this mission?',
  correct: 'Current route will pass a No Fly Zone, do you want to automatically correct the route?',
  correctAltitude: 'The waypoints cross altitude limit zone. We recommend executing optimized route.',
  correctBoth: 'Current route will trigger multiple conditional restriction. We recommend executing optimized route.',
  selectelz: 'Select ELZ',
  delete: 'Delete Mission',
  export: 'Export Mission',
  import: 'Import Mission',
  filename: 'File Name',
  dragndrop: 'Drag & drop files here or',
  filepath: 'Select Files',
  fileexisted: 'already exits, do you want to replace it?',
  or: 'or',
  noNest: 'No Nest Found',
  toAddNest: 'Please go to the devices page to add a new nest.',
  manualHint: 'Are you sure to switch the auto-heading to manual mode?',
  alert: {
    nameExist: 'The name is already exist.',
    nameEmpty: 'The name cannot be empty.',
  },
};
