<template>
  <div class="app-main">
    <section class="app-main-toolbar">
      <slot name="toolbar"/>
    </section>
    <section class="app-main-content">
      <slot/>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'AdminMainLayout',
  };
</script>

<style lang="scss">
  .app-main > .app-main-toolbar {
    .el-row {
      h1{
        margin-top: 56px;
      }
    }
  }
  .app-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 89px;
    padding-left: 89px;
    margin: 0 auto;
    max-width: 1528px;
    .app-main-toolbar {
      flex-shrink: 0;
      flex-grow: 0;
      margin-bottom: 20px;
      z-index: 3;

      .el-row {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .app-main-content {
      flex-grow: 1;
      position: relative;
    }
  }
</style>
