export default {
  altitude_limit: 'Height restriction',
  no_fly: 'No Fly Zone',
  obstacle: 'Building',
  latitude: 'Latitude',
  longitude: 'Longitude',
  altitude: 'Altitude',
  rtl_altitude: 'RTL Altitude',
  distance: 'Distance',
  time: 'Time',
  takeoff: 'Takeoff',
  title: {
    drone: {
      total: 'Total: ',
      selectall: 'Select all',
      auto_heading: 'Auto heading',
    },
  },
  nest: {
    name: 'Nest',
    open: 'Open',
    close: 'Close',
  },
  ipCam: {
    name: 'IP Cam',
  },
  aerialSurvey: {
    title: 'Survey',
    capturePoint: 'Capture Point',
    intervalShooting: 'Interval Shooting',
    footprint: 'Footprint',
    longitudinal: 'Longitudinal',
    lateral: 'Lateral',
    area: 'Area',
    pictures: 'Pictures',
    numberOfStrips: 'Number of strips',
    update: 'Update',
    altitude: 'Altitude',
  },
  unit: {
    m: 'm',
    m2: 'm<sup>2</sup>',
    pcs: 'pcs',
    mmpx: 'mm/px',
  },
  waypointMenu: {
    photo_duration: 'Interval Shooting',
    selected: 'Selected',
    altitude: 'Altitude',
    delay: 'Delay',
    speed: 'Speed to next',
    tilt: 'Camera tilt',
    heading: 'Drone heading',
    Take_Off: 'Take off',
    Waypoint: 'Waypoint',
    Counting: 'Counting',
    Landing: 'Landing',
    Nest: 'Nest',
    Return_Home: 'Return Home',
    Camera: 'Camera',
    Intervalcam: 'Interval Camera',
    selectNest: 'Select A Nest',
    notify_ai: 'AI Recognition',
  },
};
