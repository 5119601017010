import BaseVM from '@/models/';

export class TriggerVM extends BaseVM {
  init() {
    return {
      name: '',
      address: '',
      type: 'alert',
      enabled: true,

      // type = alert require, others option
      message: '',

      // type = mission require
      mid: null,

      // type = goto require
      duration: 0,
      latitude: 0,
      longitude: 0,
      altitude: 0,
      heading: 0,
    };
  }
}
