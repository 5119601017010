export default {
  title: '圖資來源',
  name: '名稱',
  warning: '圖資來源只會被存在這個瀏覽器上',
  dialog: {
    addTitle: '新增圖資',
    updateTitle: '編輯圖資',
    type: '圖資類型',
    options: '參數',
    typeSelect: '選擇圖資類型',
    preview: '預覽',
    olRefs: 'OpenLayers 參考物件: ',
    parseFail: '參數 {key} 必須是 {type}, 但無法被解析: {error}',
    noField: '缺少必要的欄位: {key}',
  },
};