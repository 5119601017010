import { CopyObject } from '@/utils/common.js';

import * as types from '../mutations_type';
import { TriggerVM } from '@/models/trigger';


const state = {
  mode: sessionStorage.getItem('triggerMode'),
  trigger: sessionStorage.getItem('trigger')
    ? JSON.parse(sessionStorage.getItem('trigger'))
    : new TriggerVM(),
};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getTrigger: (state) => state.trigger,
};

const actions = {
  setMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_TRIGGER);
    }
    commit(types.SET_TRIGGER_MODE, mode);
  },
  setTrigger({ commit }, trigger) {
    commit(types.SET_TRIGGER, trigger);
  },
  exportTrigger: () => {
    let apiData = CopyObject(state.trigger);
    // mission only for preview
    switch (apiData.type) {
      case 'alert':
        if (apiData.message == null || apiData.message === '') {
          throw new Error('require message');
        }
        delete apiData.mid;
        delete apiData.latitude;
        delete apiData.longitude;
        delete apiData.altitude;
        delete apiData.heading;
        break;
      case 'curise':
        break;
      case 'mission':
        if (apiData.mission == null || apiData.mission === '') {
          throw new Error('require mission');
        }
        if (apiData.mission.id != null) {
          apiData.mid = apiData.mission.id;
        }
        delete apiData.latitude;
        delete apiData.longitude;
        delete apiData.altitude;
        delete apiData.heading;
        break;
      case 'goto':
        if (apiData.latitude == null || apiData.latitude === '') {
          throw new Error('require latitude');
        }
        if (apiData.longitude == null || apiData.longitude === '') {
          throw new Error('require longitude');
        }
        if (apiData.altitude == null || apiData.altitude === '') {
          throw new Error('require altitude');
        }
        if (apiData.heading == null || apiData.heading === '') {
          throw new Error('require heading');
        }
        break;
      default:
        break;
    }
    delete apiData.mission;

    return apiData;
  },
};

const mutations = {
  [types.SET_TRIGGER](state, trigger) {
    state.trigger = trigger;
    sessionStorage.setItem('trigger', JSON.stringify(trigger));
  },
  [types.SET_TRIGGER_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('triggerMode', mode);
  },
  [types.CLEAR_TRIGGER](state) {
    state.mode = null;
    state.trigger = new TriggerVM();
    sessionStorage.removeItem('trigger');
    sessionStorage.removeItem('triggerMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
