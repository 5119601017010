/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
// mqtt instance
import _config from 'config';
import uuidv4 from 'uuid/v4';
import 'paho-mqtt';

export const mqtt = function () {
  // private attribute
  let clientConnect = false;
  const connectSuccessQuery = [];
  const connectUuid = uuidv4();
  const onMessageJobs = {};
  let subscribedGroup = null;
  const subscribedNest = [];
  const subscribedDrone = [];
  const subscribedDroneDetail = [];
  const subscribedWeather = [];
  const topicMap = new Map();
  let options = {};
  // public attribute
  this.client = null;
  // make private attribute public for debug
  this.subscribedNest = subscribedNest;
  this.subscribedDrone = subscribedDrone;
  this.subscribedDroneDetail = subscribedDroneDetail;
  this.subscribedWeather = subscribedWeather;

  // constrator
  const mqttInstance = () => { };

  this.mqttConnect = ({
    host, port, path, connectOptions,
  }) => {
    const vhost = window.location.hostname;

    let vport = 1884;

    if (typeof port === 'number') {
      vport = port;
    }

    if (path == null) {
      path = '/mqtt';
    }
    if (this.client == null) {
      this.client = new Paho.MQTT.Client(vhost, vport, path, `web_${connectUuid}_${Math.floor(Math.random() * 100)}`);
    }

    this.client.onConnectionLost = reconnect;
    this.client.onMessageArrived = messageArrived;
    options = connectOptions;

    this.client.connect({
      onSuccess: connectSuccess,
      onFailure: reconnect,
      ...connectOptions,
    });
  };

  this.mqttDisconnect = () => {
    this.client = null;
  };

  const mqttReconnect = () => {
    this.client.connect({
      onSuccess: () => {
        if (subscribedGroup != null) {
          this.subscribeGroupAlert();
        }
        for (const topic of subscribedNest) {
          this.subscribeNest({}, {}, topic);
        }
        for (const topic of subscribedDrone) {
          this.subscribeDrone({}, {}, topic);
        }
        for (const topic of subscribedDroneDetail) {
          this.subscribeDroneDetail({}, {}, topic);
        }
        for (const topic of subscribedWeather) {
          this.subscribeWeather({}, {}, topic);
        }
        connectSuccess();
      },
      onFailure: reconnect,
      ...options,
    });
  };

  this.subscribeGroupAlertFacade = (group, job) => {
    const jobId = this.addMessageArrived(job);
    this.subscribeGroupAlert(group, jobId);
  };

  this.unsubscribeGroupAlertFacade = () => {
    this.unsubscribeGroupAlert();
  };

  this.addGroupAlertHandler = (addJob) => {
    const jobId = this.addMessageArrived(addJob);
    const group = subscribedGroup;
    // let group_topic = `${group.domain}/${group.name}`;
    const group_topic = group.topic;
    const group_topic_alert = `${group_topic}/alert`;

    const job = () => {
      const { drones } = group;
      topicMap.get(group_topic_alert).push(jobId);
      if (drones == null) {
        return;
      }
      for (const drone of drones) {
        const droneAlertTopic = `${group_topic}/${drone.id}/alert`;
        topicMap.get(droneAlertTopic).push(jobId);
      }
    };

    runJobOnConnection(job);
    return jobId;
  };

  // public function
  this.subscribeGroupAlert = (group, jobId) => {
    if (group != null) {
      subscribedGroup = group;
    } else {
      group = subscribedGroup;
    }
    // let group_topic = `${group.domain}/${group.name}`;
    const group_topic = group.topic;
    const group_topic_alert = `${group_topic}/alert`;

    const job = () => {
      const { drones } = group;
      this.client.subscribe(group_topic_alert);
      if (jobId) {
        topicMap.set(group_topic_alert, [jobId]);
      }
      if (drones == null) {
        return;
      }
      for (const drone of drones) {
        const droneAlertTopic = `${group_topic}/${drone.id}/alert`;
        this.client.subscribe(droneAlertTopic);
        if (jobId) {
          topicMap.set(droneAlertTopic, [jobId]);
        }
      }
    };

    runJobOnConnection(job);
  };

  this.unsubscribeGroupAlert = () => {
    const group = subscribedGroup;
    if (group == null) {
      return;
    }

    // let group_topic = `${group.domain}/${group.name}`;
    const group_topic = group.topic;
    const group_topic_alert = `${group_topic}/alert`;
    subscribedGroup = null;

    const job = () => {
      const { drones } = group;
      if (this.client) {
        this.client.unsubscribe(group_topic_alert);
      }
      this.removeMessageArrived(topicMap.get(group_topic_alert).pop);
      topicMap.delete(group_topic_alert);
      if (drones == null) {
        return;
      }
      for (const drone of drones) {
        const droneAlertTopic = `${group_topic}/${drone.id}/alert`;
        if (this.client) {
          this.client.unsubscribe(droneAlertTopic);
        }
        this.removeMessageArrived(topicMap.get(droneAlertTopic).pop);
        topicMap.delete(droneAlertTopic);
      }
    };

    runJobOnConnection(job);
  };

  this.subscribeNestFacade = (group, nest, job) => {
    const jobId = this.addMessageArrived(job);
    this.subscribeNest(group, nest, '', jobId);
  };

  this.unsubscribeNestFacade = (group, nest) => {
    this.unsubscribeNest(group, nest);
  };

  this.subscribeNest = (group, nest, topic, jobId) => {
    if (topic == '') {
      // for manually subscribe nest
      // let group_topic = `${group.domain}/${group.name}`;
      // topic = `${group_topic}/${nest.id}`;
      topic = nest.topic;
      subscribedNest.push(topic);
    } else {
      nest = {
        id: topic.split('/').pop(),
      };
      // for reconnect auto re-subscribe
    }

    const topic_heartbeat = `${topic}/heartbeat`;

    const job = () => {
      this.client.subscribe(topic_heartbeat);
      if (jobId) {
        topicMap.set(topic_heartbeat, [jobId]);
      }
    };

    runJobOnConnection(job);
  };

  this.subscribeDroneFacade = (group, drone, job) => {
    const jobId = this.addMessageArrived(job);
    this.subscribeDrone(group, drone, '', jobId);
  };

  this.subscribeDrone = (group, drone, topic, jobId) => {
    if (topic == '') {
      // let group_topic = `${group.domain}/${group.name}`;
      // topic = `${group_topic}/${drone.id}`;
      topic = drone.topic;
      subscribedDrone.push(topic);
    } else {
      drone = {
        id: topic.split('/').pop(),
      };
    }

    const topic_gps = `${topic}/gps`;
    const topic_hud = `${topic}/hud`;
    const topic_mission = `${topic}/current_mission`;
    const topic_attitude = `${topic}/attitude`;
    const topic_battery = `${topic}/battery_status`;
    const topic_heartbeat = `${topic}/heartbeat`;
    const topic_system_status = `${topic}/system_status`;
    const topic_event = `${topic}/event`;

    const job = () => {
      this.client.subscribe(topic_heartbeat);
      this.client.subscribe(topic_gps);
      this.client.subscribe(topic_hud);
      this.client.subscribe(topic_mission);
      this.client.subscribe(topic_attitude);
      this.client.subscribe(topic_battery);
      this.client.subscribe(topic_system_status);
      this.client.subscribe(topic_event);

      if (jobId) {
        topicMap.set(topic_heartbeat, [jobId]);
        topicMap.set(topic_gps, [jobId]);
        topicMap.set(topic_hud, [jobId]);
        topicMap.set(topic_mission, [jobId]);
        topicMap.set(topic_attitude, [jobId]);
        topicMap.set(topic_battery, [jobId]);
        topicMap.set(topic_system_status, [jobId]);
        topicMap.set(topic_event, [jobId]);
      }
    };

    runJobOnConnection(job);
  };

  this.unsubscribeDroneFacade = (group, drone) => {
    this.unsubscribeDrone(group, drone);
  };

  this.unsubscribeDrone = (group, drone) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}`;
    const { topic } = drone;
    subscribedDrone.splice(subscribedDrone.indexOf(topic), 1);

    const topic_mission = `${topic}/current_mission`;
    const topic_heartbeat = `${topic}/heartbeat`;
    const topic_gps = `${topic}/gps`;
    const topic_hud = `${topic}/hud`;
    const topic_attitude = `${topic}/attitude`;
    const topic_battery = `${topic}/battery_status`;
    const topic_system_status = `${topic}/system_status`;
    const topic_event = `${topic}/event`;

    const job = () => {
      this.client.unsubscribe(topic_heartbeat);
      this.client.unsubscribe(topic_gps);
      this.client.unsubscribe(topic_hud);
      this.client.unsubscribe(topic_mission);
      this.client.unsubscribe(topic_attitude);
      this.client.unsubscribe(topic_battery);
      this.client.unsubscribe(topic_system_status);
      this.client.unsubscribe(topic_event);

      this.removeMessageArrived(topicMap.get(topic_heartbeat).pop);
      this.removeMessageArrived(topicMap.get(topic_gps).pop);
      this.removeMessageArrived(topicMap.get(topic_hud).pop);
      this.removeMessageArrived(topicMap.get(topic_mission).pop);
      this.removeMessageArrived(topicMap.get(topic_attitude).pop);
      this.removeMessageArrived(topicMap.get(topic_battery).pop);
      this.removeMessageArrived(topicMap.get(topic_system_status).pop);
      this.removeMessageArrived(topicMap.get(topic_event).pop);

      topicMap.delete(topic_heartbeat);
      topicMap.delete(topic_gps);
      topicMap.delete(topic_hud);
      topicMap.delete(topic_mission);
      topicMap.delete(topic_attitude);
      topicMap.delete(topic_battery);
      topicMap.delete(topic_system_status);
      topicMap.delete(topic_event);
    };

    runJobOnConnection(job);
  };

  this.subscribeDroneDetailFacade = (group, drone, job) => {
    const jobId = this.addMessageArrived(job);
    this.subscribeDroneDetail(group, drone, '', jobId);
  };

  this.subscribeDroneDetail = (group, drone, topic, jobId) => {
    if (topic == '') {
      // let group_topic = `${group.domain}/${group.name}`;
      // topic = `${group_topic}/${drone.id}`;
      topic = drone.topic;
      subscribedDroneDetail.push(topic);
    } else {
      drone = {
        id: topic.split('/').pop(),
      };
    }

    const topic_pilot = `${topic}/pilot_status`;

    const job = () => {
      this.client.subscribe(topic_pilot);
      if (jobId) {
        topicMap.set(topic_pilot, [jobId]);
      }
    };

    runJobOnConnection(job);
  };

  this.unsubscribeDroneDetailFacade = (group, drone) => {
    this.unsubscribeDroneDetail(group, drone);
  };

  this.unsubscribeDroneDetail = (group, drone) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}`;
    const { topic } = drone;
    subscribedDroneDetail.splice(subscribedDroneDetail.indexOf(topic), 1);

    const topic_pilot = `${topic}/pilot_status`;
    // let topic_alert = topic + '/alert';

    const job = () => {
      this.client.unsubscribe(topic_pilot);
      this.removeMessageArrived(topicMap.get(topic_pilot).pop);
      topicMap.delete(topic_pilot);
    };

    runJobOnConnection(job);
  };

  // topic : domain/group/weather_station_id/heartbeat
  this.subscribeWeather = (group, weather, topic, jobId) => {
    if (topic == '') {
      // let group_topic = `${group.domain}/${group.name}`;
      // topic = `${group_topic}/${weather.id}`;
      topic = weather.topic;
      subscribedWeather.push(topic);
    } else {
      weather = {
        id: topic.split('/').pop(),
      };
    }

    const topic_heartbeat = `${topic}/heartbeat`;
    const job = () => {
      this.client.subscribe(topic_heartbeat);
      if (jobId) {
        topicMap.set(topic_heartbeat, [jobId]);
      }
    };

    runJobOnConnection(job);
    return topic_heartbeat;
  };

  this.subscribeWeatherFacade = (group, weather, job) => {
    const jobId = this.addMessageArrived(job);
    this.subscribeWeather(group, weather, '', jobId);
  };

  this.unsubscribeWeatherFacade = (group, weather) => {
    this.unsubscribeWeather(group, weather);
    // this.removeMessageArrived(topicMap.get(topic));
    // topicMap.delete(topic);
  };

  this.unsubscribeWeather = (group, weather) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${weather.id}`;
    const { topic } = weather;
    subscribedWeather.splice(subscribedWeather.indexOf(topic), 1);

    const topic_heartbeat = `${topic}/heartbeat`;
    const job = () => {
      this.client.unsubscribe(topic_heartbeat);
      this.removeMessageArrived(topicMap.get(topic_heartbeat).pop);
      topicMap.delete(topic_heartbeat);
    };

    runJobOnConnection(job);
    return topic_heartbeat;
  };

  this.unsubscribeNest = (group, nest) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${nest.id}`;
    const { topic } = nest;
    subscribedNest.splice(subscribedNest.indexOf(topic), 1);

    const topic_heartbeat = `${topic}/heartbeat`;

    const job = () => {
      this.client.unsubscribe(topic_heartbeat);
      this.removeMessageArrived(topicMap.get(topic_heartbeat).pop);
      topicMap.delete(topic_heartbeat);
    };

    runJobOnConnection(job);
  };

  this.addMessageArrived = (callback) => {
    let jobId;

    while (jobId == null || onMessageJobs[jobId]) {
      jobId = Math.ceil(Math.random() * 65535);
    }

    onMessageJobs[jobId] = callback;
    return jobId;
  };

  this.removeMessageArrived = (jobId) => {
    onMessageJobs[jobId] = null;
    delete onMessageJobs[jobId];
  };

  // private function
  const runJobOnConnection = (job) => {
    if (clientConnect) {
      job();
    } else {
      connectSuccessQuery.push(job);
    }
  };

  const reconnect = (responseObject) => {
    clientConnect = false;
    if (responseObject.errorMessage) {
      console.error(`connection lost: ${responseObject.errorMessage}. Reconnecting`);
    } else {
      console.error(`Connection Attempt to host ${_config.mqttHost} Failed`);
    }

    setTimeout(mqttReconnect, _config.mqttReconnectTimeout);
  };

  const messageArrived = (message) => {
    if (message) {
      try {
        const a = JSON.parse(message.payloadString);
      } catch (e) {
        return;
      }
    }
    const msg = JSON.parse(message.payloadString);
    const destination = message.destinationName.split('/');

    if (typeof this.mqttArriveMessage === 'function') {
      this.mqttArriveMessage({
        message,
        msg,
        destination,
      });
    }

    const jobIds = topicMap.get(message.destinationName);
    if (jobIds) {
      jobIds.forEach((jobId) => {
        if (typeof onMessageJobs[jobId] === 'function') {
          onMessageJobs[jobId](message, msg, destination);
        }
      });
    }
    // for (let jobId in onMessageJobs) {
    //   onMessageJobs[jobId](message, msg, destination);
    // }
  };

  const connectSuccess = () => {
    clientConnect = true;
    while (connectSuccessQuery.length > 0) {
      const job = connectSuccessQuery.splice(0, 1)[0];
      job();
    }
  };

  this.sendHover = (group, drone) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}/execute_command`;
    const topic = `${drone.topic}/execute_command`;
    const job = () => {
      const data = {};
      data.request = 'execute_command';
      data.sender = 'FMS';
      data.target = `${drone.id}`;
      data.data = {
        command: 'hover',
        values: [],
      };
      const message = new Paho.MQTT.Message(JSON.stringify(data));
      message.destinationName = topic;
      this.client.send(message);
    };

    runJobOnConnection(job);
  };

  this.sendResume = (group, drone) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}/execute_command`;
    const topic = `${drone.topic}/execute_command`;
    const job = () => {
      const data = {};
      data.request = 'execute_command';
      data.sender = 'FMS';
      data.target = `${drone.id}`;
      data.data = {
        command: 'resume',
        values: [],
      };
      const message = new Paho.MQTT.Message(JSON.stringify(data));
      message.destinationName = topic;
      this.client.send(message);
    };

    runJobOnConnection(job);
  };

  this.sendLand = (group, drone) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}/execute_command`;
    const topic = `${drone.topic}/execute_command`;
    const job = () => {
      const data = {};
      data.request = 'execute_command';
      data.sender = 'FMS';
      data.target = `${drone.id}`;
      data.data = {
        command: 'land',
        values: [],
      };
      const message = new Paho.MQTT.Message(JSON.stringify(data));
      message.destinationName = topic;
      this.client.send(message);
    };

    runJobOnConnection(job);
  };

  this.sendTakeOff = (group, drone) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}/execute_command`;
    const topic = `${drone.topic}/execute_command`;
    const job = () => {
      const data = {};
      data.request = 'execute_command';
      data.sender = 'FMS';
      data.target = `${drone.id}`;
      data.data = {
        command: 'take_off',
        altitude: 100,
      };
      const message = new Paho.MQTT.Message(JSON.stringify(data));
      message.destinationName = topic;
      this.client.send(message);
    };

    runJobOnConnection(job);
  };

  this.sendRTL = (group, drone) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}/execute_command`;
    const topic = `${drone.topic}/execute_command`;
    const job = () => {
      const data = {};
      data.request = 'execute_command';
      data.sender = 'FMS';
      data.target = `${drone.id}`;
      data.data = {
        command: 'rtl',
        values: [],
      };
      const message = new Paho.MQTT.Message(JSON.stringify(data));
      message.destinationName = topic;
      this.client.send(message);
    };

    runJobOnConnection(job);
  };

  this.zoom = (group, drone, value) => {
    // let group_topic = `${group.domain}/${group.name}`;
    // let topic = `${group_topic}/${drone.id}/execute_command`;
    const topic = `${drone.topic}/execute_command`;
    const job = () => {
      const data = {};
      data.request = 'execute_command';
      data.sender = 'FMS';
      data.target = `${drone.id}`;
      data.data = {
        command: 'camera_zoom',
        values: [value],
      };
      const message = new Paho.MQTT.Message(JSON.stringify(data));
      message.destinationName = topic;
      this.client.send(message);
    };

    runJobOnConnection(job);
  };

  // main
  mqttInstance();
};
