import BaseVM from '@/models/';

export class NestVM extends BaseVM {
  init() {
    return {
      name: '',
      group: '',
      address: '',
      domain: '',
      created_time : new Date(),
      update_time : new Date(),
      latitude: 0,
      longitude: 0,
      relay : true,
      inactive: false,
      max_temperature: 0,
      min_temperature: 0,
      temperature: [0, 40],
      altitude: 0,
    };
  }
}
