<template>
  <img :height="height" :src="replacedUrl" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Thumbnail',
  components: {
  },
  computed: {
    ...mapGetters({
      googleMapsKey: 'user/googleMapsKey'
    }),
    replacedUrl() {
      if (this.$config.environment == 'production') {
        return this.src;
      }
      let re = /[&?]key=[^&]*/;
      let newUrl = this.src.replace(re, '');
      if (newUrl.indexOf('?') == -1) {
        return newUrl + '?key=' + this.googleMapsKey;
      } else {
        return newUrl + '&key=' + this.googleMapsKey;
      }
    }
  },
  props: {
    height: {
      default: null,
    },
    src: {
      type: String,
      required: true,
    },
  },
};
</script>
