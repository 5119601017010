import fileDownload from 'js-file-download';
import { appAxios as axios } from '@/services/api/';

const apiUrl = '/v1/groups';

export default {
  getAll(filter) {
    return axios
      .get(`${apiUrl}`, { params: filter })
      .then((response) => response.data);
  },
  get(groupId) {
    return axios.get(`${apiUrl}/${groupId}`).then((response) => response.data);
  },
  create(group) {
    return axios.post(`${apiUrl}`, group).then((response) => response.data);
  },
  update(groupId, group) {
    return axios.put(`${apiUrl}/${groupId}`, group).then((response) => response.data);
  },
  delete(groupId) {
    return axios.delete(`${apiUrl}/${groupId}`).then((response) => response.data);
  },

  history(groupId, filter) {
    return axios.get(`${apiUrl}/${groupId}/histories`, { params: filter }).then((response) => response.data);
  },

  historyExportExl(groupId, filter) {
    return axios.get(`${apiUrl}/${groupId}/histories/excel`, { params: filter, responseType: 'blob' }).then((response) => {
      fileDownload(response.data, 'report.xlsx');
    });
  },

  historyDroneExportExl(groupId, droneId, filter) {
    return axios.get(`${apiUrl}/${groupId}/histories/drones/${droneId}/excel`, { params: filter, responseType: 'blob' }).then((response) => {
      fileDownload(response.data, 'report.xlsx');
    });
  },

};
