import _ from 'lodash';
import * as types from '../mutations_type';
import { NoFlyZoneVM } from '@/models/noFlyZone';

import {
  GeographicShorter, FormatPolygonsToLatitudeLongitude, PolygonsApiToOl, PolygonsOlToApi,
} from '@/utils/map.js';
import { CopyObject } from '@/utils/common';

const state = {
  noFlyZone: sessionStorage.getItem('noFlyZone')
    ? JSON.parse(sessionStorage.getItem('noFlyZone'))
    : null,
};

const getters = {
  getNoFlyZone: (state) => state.noFlyZone,
  getNoFlyZoneGoogle: (state) => {
    const data = { ...state.noFlyZone };
    if (data == null || data.polygons == null) {
      return;
    }
    data.polygons = data.polygons.map((polygon) => polygon.map(GeographicShorter));
    return data;
  },
  getNoFlyZoneOl: (state) => {
    const data = { ...state.noFlyZone };
    if (data == null || data.polygons == null) {
      return;
    }
    data.coordinates = PolygonsApiToOl(data.polygons);
    delete data.polygons;
    return data;
  },
};

const actions = {
  new({ commit }) {
    commit(types.SET_NO_FLY_ZONE_DATA, new NoFlyZoneVM());
  },
  load({ commit }, zone) {
    commit(types.SET_NO_FLY_ZONE_DATA, zone);
  },
  loadFromGoogle({ commit }, zone) {
    const data = { ...zone };
    data.polygons = FormatPolygonsToLatitudeLongitude(data.polygons);
    commit(types.SET_NO_FLY_ZONE_DATA, data);
  },
  loadFromOl({ commit }, zone) {
    const data = { ...zone };
    data.polygons = PolygonsOlToApi(data.coordinates);
    delete data.coordinates;
    commit(types.SET_NO_FLY_ZONE_DATA, data);
  },
  close({ commit }, zone) {
    commit(types.SET_NO_FLY_ZONE_DATA, null);
  },
  // operation with map
  setType({ commit }, type) {
    const data = CopyObject(state.noFlyZone);
    if (data == null) {
      return;
    }
    data.type = type;
    commit(types.SET_NO_FLY_ZONE_DATA, data);
  },
  insert({ commit }, event) {
    const { position } = event;
    const index = event.sequence;
    const data = CopyObject(state.noFlyZone);
    if (index == null) {
      data.polygons[0].push(position);
    } else {
      data.polygons[0].splice(index + 1, 0, position);
    }
    commit(types.SET_NO_FLY_ZONE_DATA, data);
  },
  delete({ commit }, event) {
    const data = CopyObject(state.noFlyZone);
    data.polygons[0].splice(event, 1);
    commit(types.SET_NO_FLY_ZONE_DATA, data);
  },
};

const mutations = {
  [types.SET_NO_FLY_ZONE_DATA](state, zone) {
    // important for prevent data loop
    if (_.isEqual(state.noFlyZone, zone)) {
      return;
    }
    state.noFlyZone = zone;
    if (zone == null) {
      sessionStorage.removeItem('noFlyZone');
    } else {
      sessionStorage.setItem('noFlyZone', JSON.stringify(zone));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
