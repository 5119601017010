import BaseVM from '@/models/';

export class DroneVM extends BaseVM {
  init() {
    return {
      name: '',
      group: '',
      address: '',
      serial: '',
      type : 'HEXAROTOR',
      charge: true,
      custom_rgb_url :{
        push_url: '',
        pop_url:'',
      },
      custom_thermal_url:{
        push_url: '',
        pop_url:'',
      },
      inactive : false,
      model : '',
      gimbal1: '',
      gimbal2: '',
      // active: true,
    };
  }
}
