<template>
  <vl-feature :id="`Zones-SurveyZone-${zone.id}`" v-if="visible" :z-index="zIndex">
    <vl-geom-polygon :coordinates="coordinates" />
    <vl-style-box>
      <vl-style-stroke :color="strokeColor" :width="strokeWidth"></vl-style-stroke>
      <vl-style-fill :color="fillColor"></vl-style-fill>
    </vl-style-box>
  </vl-feature>
</template>

<script>

export default {
  name: 'SurveyZone',
  data() {
    return {
      strokeColor: [53, 187, 209, 0.8],
      strokeWidth: 3,
      fillColor: [53, 187, 209, 0],
    };
  },
  components: {
  },
  computed: {
  },
  props: {
    coordinates: {
      required: true,
      type: Array,
    },
    visible: {
      required: false,
      type: Boolean,
      default: true,
    },
    zIndex: {
      default: 100,
    },
  },
};
</script>
