export default {
  noHistory: {
    title: '尚未有任何飛行紀錄',
    content: 'Please click the “Add New” button to add a new history.',
  },
  dialog: {
    addTitle: 'Add a New Mission',
    updateTitle: 'Edit Mission',
    name: 'Mission Name',
    type: 'Mission Type',
    types: {
      lineString: 'Line String Mission',
      aerialSingle: 'Aerial Survey-Single',
      aerialGrid: 'Aerial Survey-Grid',
    },
    startTime: 'Start Time',
    endTime: 'End Time',
    repeat: 'Repeat',
    noRepeat: 'No Repeat',
    period: 'Every',
    addWaypoint: 'Add waypoint',
    save: 'Save the photos and the videos to your device?',
    delete: 'Once you delete the photos and the videos, you can\'t undo it!',
  },
  deleteConfirm: {
    title: 'Are you sure?',
    body: 'Once you delete the Mission, you can\'t undo it!',
  },
  title: '飛行紀錄',
  map: '地圖',
  name: '名稱',
  tasks: '任務',
  location: 'Location',
  endTime: 'End Time',
  period: 'Scheduled at',
  allDrones: 'All Drones',
  periodUnit: {
    minutes: '分',
    hours: '時',
    days: '日',
    weeks: '週',
    months: '月',
  },
  periodOnce: 'Once',
  periodMessage: 'Every {number} {unit}',
  load: 'Load',
  survey: {
    createRoute: 'Create Route',
  },
  saveConfirm: '你確定要建立這任務嗎?',
  table: {
    drone_name: '無人機',
    create_time: '日期',
    take_off: '起飛時間',
    landing: '降落時間',
    distance: '距離',
    time: '時間',
    result: '結果',
    upload_progress: '照片上傳進度',
    report: '報告',
    video: '影片',
  },
  imageNone: '毋需上傳',
  recordStatus: {
    200: 'Success',
    404: 'Can not find target drone',
    405: 'Assign mission failed',
    406: 'Execute mission failed',
    407: 'Assign device not ready',
    408: 'Incomplete Mission',
    409: 'Mission is not valid',
    410: 'Wind speed is not valid',
    411: 'Precipitation is not valid',
  },
};
