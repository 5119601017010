import { CopyObject } from '@/utils/common.js';

import * as types from '../mutations_type';
import { OlSourceVM } from '@/models/olSource';

const state = {
  mode: sessionStorage.getItem('olSourceEditMode'),
  olSource: sessionStorage.getItem('olSourceEditData')
    ? JSON.parse(sessionStorage.getItem('olSourceEditData'))
    : new OlSourceVM(),
};

const getters = {
  shouldShow: (state) => (state.mode != null),
  getMode: (state) => state.mode,
  getData: (state) => state.olSource,
};

const actions = {
  setEditMode({ commit }, mode) {
    if (mode == null) {
      commit(types.CLEAR_OL_SOURCE_EDIT);
    }
    commit(types.SET_OL_SOURCE_EDIT_MODE, mode);
  },
  setEditData({ commit }, data) {
    commit(types.SET_OL_SOURCE_EDIT, data);
  },
};

const mutations = {
  [types.SET_OL_SOURCE_EDIT](state, data) {
    state.olSource = data;
    sessionStorage.setItem('olSourceEditData', JSON.stringify(data));
  },
  [types.SET_OL_SOURCE_EDIT_MODE](state, mode) {
    if (mode == null) {
      return ;
    }
    state.mode = mode;
    sessionStorage.setItem('olSourceEditMode', mode);
  },
  [types.CLEAR_OL_SOURCE_EDIT](state) {
    state.mode = null;
    state.olSource = new OlSourceVM();
    sessionStorage.removeItem('olSourceEditData');
    sessionStorage.removeItem('olSourceEditMode');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
