<template>
  <div @click="onClick">
    <fa-icon :icon="icon"/>
  </div>
</template>

<script>
  export default {
    name: 'Hamburger',
    data() {
      return {
        isActive: false,
      };
    },
    computed: {
      icon() {
        return this.isActive ? 'angle-double-right' : 'bars';
      },
    },
    methods: {
      onClick() {
        this.isActive = !this.isActive;
        this.$emit('click');
      },
    },
  };
</script>

<style>
</style>
