export default {
  tab: {
    eventList: 'Event Lists',
    triggerForward: 'Trigger/Forward',
  },
  title: {
    eventList: 'Event Lists',
    triggers: 'Triggers',
    forwards: 'Forwards',
  },
  triggerEditDialog: {
    addTitle: 'Add a New Trigger',
    updateTitle: 'Edit Trigger',
    name: 'Name',
    type: 'Type',
    enabled: 'Enabled',
    auto: 'Auto',
    message: 'Message',
    ip: 'IP Address',
    mission: 'Mission',
    types: {
      alert: 'Alert',
      curise: 'Curise',
      mission: 'Mission',
    },
    load: 'Load Mission',
  },
  forwardEditDialog: {
    addTitle: 'Add a New Forward',
    updateTitle: 'Edit Forward',
    name: 'Name',
    type: 'Type',
    enabled: 'Enabled',
    auto: 'Auto',
    message: 'Message',
    ip: 'IP Address',
    mission: 'Mission',
    types: {
      alert: 'Alert',
      curise: 'Curise',
      mission: 'Mission',
      http: 'HTTP Post',
      websocket : 'WebSocket',
      event : 'Drone Event',
      status : 'Drone Status',
    },
    mode : 'Mode',
    forwardType: 'Forward Type',
    url : 'URL',
    account : 'Account',
    password : 'Password',
  },
  triggerDeleteDialog: {
    title: 'Are you sure?',
    body: 'Once you delete the Trigger, you can\'t undo it!',
    confirm: 'Delete Trigger',
  },
  forwardDeleteDialog: {
    title: 'Are you sure?',
    body: 'Once you delete the Forward, you can\'t undo it!',
    confirm: 'Delete Forward',
  },
  name: 'Name',
  type: 'Type',
  typeType: {
    alert: 'Alert',
    cruise: 'Cruise',
    mission: 'Mission',
  },
  time: 'Event Time',
  description: 'Description',
  droneStatus: 'Drone Status',
  droneEvent: 'Drone Event',
};
