export default {
  homePage: 'Home',
  dashboard: 'Dashboard',
  adminLogin: 'Login',
  monitor: 'Monitors',
  monitorLive: 'Monitor Live View',
  monitorMap: 'Monitor Map View',
  assignMap: 'Assign Mission Confirm',
  mission: 'Missions',
  missionList: 'Mission List',
  missionCreateMap: 'Set Mission Waypoint',
  missionEditMap: 'Set Mission Waypoint',
  missionAerialSurveySetArea: 'Aerial Survey',
  device: 'Devices',
  event: 'Event',
  history: 'History',
  setting: 'Settings',
  profile: 'Profile',
  editLocationMap: 'Edit Location Map',
  createLocationMap: 'Create Location Map',
  deviceMap: 'Edit Device Location Map',
  selectGroup: 'Select A Location',
  deviceWeatherProfile: 'Weather Station',
  deviceCameraProfile: 'Camera',
  deviceProfile: 'Drone',
  deviceNestProfile: 'Nest',
  accountSetting: 'Account',
  deviceNVRProfile: 'NVR',
  message : 'Message',
  olSourcePreview: 'OpenLayers Source Preview',
};
